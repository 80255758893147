import React, { useState } from "react";
import "./LoginForm.css";
import { FaUser, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { userLogginAsync } from "../../redux/user/userSlice";
import { pathOrigin } from "../../utils/helper";
import SquareButton from "../Ui Components/SquareButton";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [userLogginPannel, setUserLogginPannel] = useState("doctor");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const Navigate = useNavigate();

  // Login API Integration
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Please enter email and password");
      return;
    }

    try {
      toast.loading("Loading Please Wait...")
      setIsLoading(true)
      const result = await axiosClient().post(webURLs.LOGIN_USER, {
        email,
        password,
      });
      if (result.data.status) {
        setIsLoading(false)
        toast.dismiss()
        Cookies.set("user", JSON.stringify(result.data.data));
        Cookies.set("userId", result.data.data._id);
        Cookies.set("token", result.data.data.token);
        // console.log("token", result.data.data.token);
        toast.success("Login successfully");
        dispatch(userLogginAsync({ email: email, password: password }));
        // window.location.replace("/");
        Navigate("/");
      } else {
        toast.dismiss()
        toast.error("Enter valid details!");
        setIsLoading(false)
      }
    } catch (error) {
      toast.dismiss()
      setIsLoading(false)
      toast.error("Network Error Please try again After SomeTime...");
    }
  };

  return (
    <>
      <div>
        <div id="header-top" className="flex justify-around h-full mt-6">
          <img src="/asset/images/LOGO.png" alt="logo" />
          <img src={`/asset/images/balaji.png`} style={{ height: "200px" }} alt="logo-side" />
        </div>
      </div>
      <div className="bg-[#301762] h-4 w-full"></div>
      <div id="form-container">
        <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
          <div id="form-sidebox" className="sm:p-0 p-6 sm:rounded-none rounded-se-3xl">
            <div className="flex flex-col flex-wrap-reverse">
              <div
                className={
                  userLogginPannel === "doctor"
                    ? "user-login-options active"
                    : "user-login-options"
                }
                onClick={() => {
                  setUserLogginPannel("doctor");
                }}
              >
                <h1>Doctor Login</h1>
              </div>
              <div
                className={
                  userLogginPannel === "reception"
                    ? "user-login-options active"
                    : "user-login-options"
                }
                onClick={() => {
                  setUserLogginPannel("reception");
                }}
              >
                <h1>Reception Login</h1>
              </div>
            </div>
          </div>
          {userLogginPannel === "doctor" ? (
            <div
              id="form-box"
              className="doctor-login-form h-auto bg-white m-auto py-3 px-6 rounded-ee-3xl sm:rounded-e-3xl"
            >
              <img
                src={`/asset/icons/Blue Matte Simple Dentist Logo.png`}
                alt="reception"
                className="w-14 mx-auto h-auto"
              />
              <h1 className="text-center font-bold text-[#301762] font-serif text-3xl ">
                Greetings! Doc
              </h1>
              <h6 className="text-center">please login, have a nice day.</h6>
              <div>
                <form
                  id="login-form"
                  className="my-4 flex justify-center px-12 flex-col"
                >
                  <div className="flex flex-col gap-3">
                    <div className="input-container  flex items-center gap-2 py-2 px-2">
                      <FaUser />
                      <input
                        className=" focus:outline-none outline-none w-full "
                        type="email"
                        id="email"
                        autoComplete="false"
                        placeholder="Email/Username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input-container flex items-center gap-2 py-2 px-2">
                      <FaLock />
                      <input
                        className=" focus:outline-none outline-none w-full "
                        type="password"
                        id="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <h6 className="text-end mt-2 hover:text-[#3b2d57] duration-200 cursor-pointer font-medium">
                    Forgotten Password?
                  </h6>
                  {/* {isLoading ? <div className='loader ml-6'></div> : <button
                    onClick={handleLogin}
                    className="bg-[#301762] text-white hover:bg-[#3b2d57] text-xl py-1 px-3 rounded-lg my-6"
                  >
                    Login
                  </button>} */}
                  <SquareButton
                    handleClick={handleLogin}
                    btnType={"submit"}
                    sqicon={<span className="text-sm">Login</span>}
                    isLoading={isLoading}
                    className={"mt-2 bg-[#301762]"}
                  />
                </form>
              </div>
            </div>
          ) : (
            <div
              id="form-box"
              className="reception-login-form h-auto bg-white m-auto py-3 px-6"
            >
              <img
                src={`/asset/icons/reception.png`}
                alt="reception"
                className="w-14 mx-auto h-auto"
              />
              <h1 className="text-center font-bold text-[#301762] font-serif text-3xl ">
                Reception Login
              </h1>
              <h6 className="text-center">
                please login to manage appointments.
              </h6>
              <div>
                <form
                  id="login-form"
                  className="my-4 flex justify-center px-12 flex-col"
                >
                  <div className="flex flex-col gap-3">
                    <div className="input-container  flex items-center gap-2 py-2 px-2">
                      <FaUser />
                      <input
                        className=" focus:outline-none outline-none w-full "
                        type="email"
                        id="email"
                        autoComplete="false"
                        auto
                        placeholder="Email/Username"
                      />
                    </div>
                    <div className="input-container flex items-center gap-2 py-2 px-2">
                      <FaLock />
                      <input
                        className=" focus:outline-none outline-none w-full "
                        type="password"
                        id="password"
                        placeholder="Enter Password"
                      />
                    </div>
                  </div>
                  <h6 className="text-end mt-2 hover:text-[#3b2d57] duration-200 cursor-pointer font-medium">
                    Forgotten Password?
                  </h6>
                  {/* <button className="bg-[#301762] text-white hover:bg-[#3b2d57] duration-300 font-semibold text-xl py-1 px-3 rounded-lg my-6">
                    Login
                  </button> */}
                  <SquareButton
                    handleClick={handleLogin}
                    btnType={"submit"}
                    sqicon={<span className="text-sm">Login</span>}
                    isLoading={isLoading}
                    className={"mt-2 bg-[#301762]"}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <footer className="bg-[#301762] py-4 flex justify-center">
        <div className="flex flex-col justify-center items-center">
          <h6 className="font-center text-white font-medium">
            ©All copy rights resserved by Shree Balaji Dental Clinic
          </h6>
          <h6 className="font-center text-white font-semibold">2023-2024</h6>
        </div>
      </footer>
    </>
  );
};

export default LoginForm;
