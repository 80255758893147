import { useEffect, useState } from "react";
import SquareButton from "../Ui Components/SquareButton";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormat, Prifix, TimeFormat, pathOrigin } from "../../utils/helper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { path5, imgPath, imgPath2, imgPath3, imgPath4 } from './imagePath';
import { useDispatch, useSelector } from "react-redux";
import { addExaminationAsync, deleteExaminationAsync, editExaminationAsync, selectExamination } from "../../redux/examination/examinationSlice";
import { RiDeleteBin6Line } from "react-icons/ri";
import toast from "react-hot-toast";
import { selectedPatient } from "../../redux/patientAppointments/patientSlice";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";

const ClinicalExamination = () => {

  const { id } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Examinations = useSelector(selectExamination)
  const patientDetails = useSelector(selectedPatient)
  const [tooth_no, setTooth] = useState([])
  const [edited, setEdited] = useState({ is: false, examinationId: "" })
  const [otherOption, setOtherOption] = useState(false)
  const [otherDisease, setOtherDisease] = useState()


  const addDiseaseToSelectedTooth = async (d_name) => {
    toast.loading("Loading...")
    if (d_name) {
      let Obj = { tooth_no, disease_name: d_name }
      if (edited.is) {
        dispatch(editExaminationAsync({ id, examinationId: edited.examinationId, Obj }))
      } else {
        dispatch(addExaminationAsync({ id, Obj }))
      }
    } else {
      toast.dismiss()
      toast.error("Please Select Tooth")
    }
  }

  // Toggle the state for the clicked image
  //{=========tooth selection start =================}
  const changeBackground = (t_no) => {
    if (tooth_no.find((tooth) => tooth === t_no)) {
      setTooth(tooth_no.filter(item => item !== t_no))
    } else {
      setTooth([...tooth_no, t_no])
    }
  };

  // exaimation function work on every Disease click
  const handleClinicalExamination = (d_name) => {
    if (otherOption) {
      if (otherDisease.trim()) {
        addDiseaseToSelectedTooth(otherDisease)
      } else {
        if (tooth_no.length > 0) {
          addDiseaseToSelectedTooth(otherDisease)
        } else {
          toast.error("Please Select Tooth")
        }
      }
      return;
    }

    if (tooth_no.length > 0) {
      addDiseaseToSelectedTooth(d_name)
    } else {
      toast.error("Please Select Tooth")
    }

  };

  // delete examination  
  function handleDeleteExamination(examinationId) {
    dispatch(deleteExaminationAsync({ id, examinationId }))
  }

  // edit examination 
  function editExamination(data) {
    setTooth(data.tooth_no)
    setEdited({ is: true, examinationId: data._id })
    if (data.disease_name) {
      if (!path5.includes((item) => item.title.toLowerCase() === data.disease_name.toLowerCase())) {
        setOtherDisease(data.disease_name)
        setOtherOption(true)
      }

    }
    navigate(`#${data.tooth_no[0]}`)
  }

  // refresh state when examination done!
  useEffect(() => {
    setEdited({ is: false, examinationId: "" })
    setTooth([])
    setOtherDisease("")
    setOtherOption(false)
  }, [Examinations])

  return (
    <>
      <SkeletonTheme baseColor="#e1dee8" highlightColor="#d1bffc">
        <div className="">
          <div className="flex justify-between w-full p-3">
            <div className="flex">
              <span>
                <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
              </span>
              <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                Patient Name -
              </p>
              <p className="text-gray-600 text-sm pt-[5px] pl-2">
                {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
              </p>
            </div>
            <div className="flex mr-2">
              <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                File Number -
              </p>
              <p className="text-gray-600 text-sm pt-[5px] pl-2">
                {patientDetails?.file_number}
              </p>
            </div>
          </div>
          <div className="bg-white rounded border-b-2 border-t-2">
            <div className="flex items-center space-x-2 bg-gray-300 rounded-t-sm justify-center text-sm text-[#301762] px-4 py-2">
              <span className="tracking-widec font-medium">Clinical Examinations</span>
            </div>
            <hr />
            <section
              id="medicine-section"
              className="bg-white rounded border-b-2 overflow-y-auto"
            >
              {/* //======================file display table section start============================= */}
              <table className="w-[100%] text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr className="text-center">
                    <th scope="col" className="px-2 py-2 font-medium">
                      Sr.No.
                    </th>
                    <th scope="col" className="px-2 py-2 font-medium">
                      Tooth Numbers
                    </th>
                    <th scope="col" className="px-2 py-2 font-medium">
                      Disease
                    </th>
                    <th scope="col" className="px-2 py-2 font-medium">
                      Date & Time
                    </th>
                    <th scope="col" className="px-2 py-2 font-medium">
                      Edit Examination
                    </th>
                    <th scope="col" className="px-2 py-2 font-medium">
                      delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Examinations && Examinations?.map((item, index) => (
                    <tr key={index} className="text-center text-xs">
                      <td className="px-2 py-2 font-medium">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2">
                        {item?.tooth_no?.join(",")}
                      </td>
                      <td className="px-2 py-2">
                        {item?.disease_name}
                      </td>
                      <td className="px-2 py-2">
                        {DateFormat(item?.createdAt)}<br />
                        {TimeFormat(item?.createdAt)}
                      </td>
                      <td className="px-2 py-2">
                        <div className="flex justify-center">
                          <SquareButton
                            sqicon={
                              <span className="text-lg">
                                <FaEdit />
                              </span>
                            }
                            handleClick={() => {
                              editExamination(item)
                            }}
                          />
                        </div>
                      </td>
                      <td className="px-2 py-2">
                        <div className="flex justify-center">
                          <button
                            onClick={() => {
                              handleDeleteExamination(item?._id);
                            }}
                            className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                          >
                            <RiDeleteBin6Line className="p-0 m-0 text-lg" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* //======================file display table section start============================= */}
            </section>
            <div className="flex justify-between mt-4 mb-0 px-10 w-full">
              <SquareButton
                sqicon={<span className="text-xs">Clear Selection</span>}
                handleClick={() => { setTooth([]) }}
              />
              {edited.is ? <SquareButton
                sqicon={<span className="text-xs">Cancel Edit</span>}
                handleClick={() => { setEdited({ is: false, examinationId: "" }); setTooth([]) }}
              /> : null}
            </div>
            <div className="text-gray-700">
              {imgPath ? (
                <div className="flex justify-between p-8">
                  {imgPath.map((path, index) => (
                    <div
                      key={index}
                      onClick={() => changeBackground(path.num)} // Pass the index to changeBackground
                      className={`pb-2 rounded-sm shadow-slate-300 shadow-md hover:bg-[#301762] hover:no-underline cursor-pointer ${tooth_no.filter(item => item === path.num).length ? "bg-[#301762] text-white" : "bg-white"
                        }`}
                      id={path.num}
                    >
                      <img
                        src={`${pathOrigin()}/${path.image}`}
                        alt={`svg${index}`}
                        id={path.num}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Skeleton
                  className="mt-4 mx-8 rounded-sm shadow-md"
                  height={200}
                  width={"94%"}
                />
              )}
            </div>
            <div className="text-gray-700">
              {imgPath2 ? (
                <div className="flex justify-between p-10">
                  {imgPath2.map((path, index) => (
                    <div
                      key={index}
                      onClick={() => changeBackground(path.num)} // Pass the index to changeBackground
                      className={`pb-2 rounded-sm shadow-slate-300 shadow-md hover:bg-[#301762] hover:no-underline cursor-pointer ${tooth_no.filter(item => item === path.num).length
                        ? "bg-[#301762] text-white"
                        : "bg-white"
                        }`}
                      id={path.num}
                    >
                      <img
                        src={`${pathOrigin()}/${path.image}`}
                        alt={`svg${index}`}
                        id={path.num}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Skeleton
                  className="mt-4 mx-8 rounded-sm shadow-md"
                  height={200}
                  width={"94%"}
                />
              )}
            </div>
            <div className="text-gray-700">
              {imgPath3 ? (
                <div className="flex justify-between p-10">
                  {imgPath3.map((path, index) => (
                    <div
                      key={index}
                      onClick={() => changeBackground(path.num)} // Pass the index to changeBackground
                      className={`pb-2 rounded-sm shadow-slate-300 shadow-md hover:bg-[#301762] hover:no-underline cursor-pointer ${tooth_no.filter(item => item === path.num).length
                        ? "bg-[#301762] text-white"
                        : "bg-white"
                        }`}
                      id={path.num}
                    >
                      <img
                        src={`${pathOrigin()}/${path.image}`}
                        alt={`svg${index}`}
                        id={path.num}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Skeleton
                  className="mt-4 mx-8 rounded-sm shadow-md"
                  height={200}
                  width={"94%"}
                />
              )}
            </div>
            <div className="text-gray-700">
              {imgPath4 ? (
                <div className="flex justify-between p-10">
                  {imgPath4.map((path, index) => (
                    <div
                      key={index}
                      onClick={() => changeBackground(path.num)} // Pass the index to changeBackground
                      className={`pb-2 rounded-sm shadow-slate-300 shadow-md hover:bg-[#301762] hover:no-underline cursor-pointer ${tooth_no.filter(item => item === path.num).length ?
                        "bg-[#301762] text-white"
                        : "bg-white"
                        }`}
                      id={path.num}
                    >
                      <img
                        src={`${pathOrigin()}/${path.image}`}
                        alt={`svg${index}`}
                        id={path.num}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Skeleton
                  className="mt-4 mx-8 rounded-sm shadow-md"
                  height={200}
                  width={"94%"}
                />
              )}
            </div>
          </div>
          <div>
            {path5 ? (
              <div className="flex justify-start flex-wrap p-10 w-full gap-10">
                {path5.map((element, index) => (
                  <div id="other_tooth" className="flex flex-col w-[100px]" key={index}>
                    <div
                      id={element.title}
                      title={element.title}
                      onClick={() => { otherOption ? toast.error("Please Remove Other Option") : handleClinicalExamination(element.title) }} // Pass the index to changeBackground
                      className={` w-[100px] h-[90px] py-2 rounded-sm shadow-slate-300 shadow-md hover:bg-[#301762]  hover:text-white hover:no-underline cursor-pointer`}
                    >
                      <img
                        id={element.title}
                        className="m-auto  hover:bg-[#301762] hover:no-underline cursor-pointer"
                        style={{ width: "40px", height: "40px" }}
                        src={`${pathOrigin()}/${element.image}`}
                        alt={`svg${index}`}
                        title={element.title}
                      />
                      <div className="title p-2 text-center text-xs text-wrap">
                        {element.title}
                      </div>
                    </div>
                  </div>
                ))}
                <div id="other_tooth" className="flex flex-col w-[100px]">
                  <div
                    id="Other"
                    title="Other"
                    onClick={() => { setOtherOption(!otherOption) }} // Pass the index to changeBackground
                    className={` w-[100px] h-[90px] py-2 rounded-sm shadow-slate-300 ${otherOption ? 'bg-[#301762] text-white' : ""} shadow-lg hover:bg-[#301762]  hover:text-white hover:no-underline cursor-pointer`}
                  >
                    <img
                      id="Other"
                      className="m-auto  hover:bg-[#301762] hover:no-underline cursor-pointer"
                      style={{ width: "50px", height: "50px" }}
                      src={`${pathOrigin()}/asset/Conditionicons/other-disease.svg`}
                      alt="svg-other"
                      title="Other"
                    />
                    <div className="title p-2 text-center text-xs text-wrap">
                      Other
                    </div>
                  </div>
                </div>
                {otherOption ? <div id="other_tooth" className="flex items-center gap-4">
                  <input
                    type="text"
                    placeholder="Enter value"
                    list="sugess"
                    defaultValue={otherDisease}
                    onChange={(e) => { setOtherDisease(e.target.value) }}
                    className="bottom-7 text-xs z-10 rounded-md border-0 border-white py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <datalist id="sugess">
                    <option>Irreversible Pulpitis</option>
                    <option>Gingivitis</option>
                    <option>Acute Pulpitis</option>
                    <option>Decayed Tooth</option>
                    <option>Tooth Abscess</option>
                    <option>Gum Disease</option>
                    <option>Genralized Gingivitis</option>
                    <option>Chronic Gingivitis</option>
                    <option>Periapical Abscess</option>
                    <option>Apical Periodontitis</option>
                    <option>Pericoronitis</option>
                    <option>Chronic Pulpitis</option>
                    <option>Edentulous Arch</option>
                    <option>Periodontitis</option>
                    <option>Retained Deciduous </option>
                    <option>Sensitivity</option>
                    <option>Attrition</option>
                    <option>Cervical Abrasions </option>
                    <option>Dry Socket</option>
                    <option>Gingival Recession</option>
                    <option>Halitosis</option>
                  </datalist>
                  <SquareButton
                    sqicon={<span className="text-xs">Save Examination</span>}
                    handleClick={handleClinicalExamination}
                  />
                </div> : ""}
              </div>
            ) : (
              <Skeleton
                className="mt-4 mx-8 rounded-lg shadow-md"
                height={200}
                width={"94%"}
              />
            )}
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default ClinicalExamination;
