import React, { useEffect, useState } from "react";
import { LuCalendarClock } from "react-icons/lu";
import { IoPersonAdd } from "react-icons/io5";
import RoundedButton from "../Ui Components/RoundedButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../redux/user/userSlice";
import moment from "moment";

const GreetingSection = () => {
  const [day, setDay] = useState()
  const userName = useSelector(selectLoggedInUser)


  useEffect(() => {
    let date = new Date()
    const dayOnly = moment(date).format("dddd");
    setDay(dayOnly)
  }, [])

  return (
    <div className="h-[185px] bg-[#4070b7ad] rounded-md overflow-hidden greeting-Container" >
      <div className="flex justify-end">
        <div
          id="greeting_text"
          className="flex py-5 m-3 justify-between flex-col gap-3 w-5/6 text-white"
        >
          <div>
            <h3 className="text-xl font-serif font-semibold text-[#301762]" style={{textShadow:'1px 3px 4px #d2c2f1'}}>
              {`${userName?.name}`}
            </h3>
            <p className="text-sm capitalize">
              Have a great {day}
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <a href="#dashboard-form-section" className="text-white"><RoundedButton icon={<span className="text-md"><IoPersonAdd /></span>} /></a>
            <Link to="/upcomming"> <RoundedButton icon={<span className="text-md"><LuCalendarClock /></span>} /> </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreetingSection;
