import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import SquareButton from "../Ui Components/SquareButton";
import { FiRefreshCw } from "react-icons/fi";

function WebCam({ className, height, width, getImage }) {
    const webcamRef = useRef(null);
    const [facingMode, setFacingMode] = useState("user"); // Default is front camera
    const [imageSrc, setImageSrc] = useState(null)

    const capture = useCallback(
        () => {
            const image = webcamRef.current.getScreenshot();
            setImageSrc(image)
        },
        [webcamRef]
    );

    // Function to switch the camera
    const switchCamera = useCallback(() => {
        setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
    }, []);

    const videoConstraints = {
        width: width,
        height: height,
        facingMode: facingMode
    };

    const switchCss = {
        fontSize: "x-large"
    }

    useEffect(() => {
        if (typeof (getImage) === "function") {
            getImage(imageSrc)
        }
    })

    useEffect(() => {
        return () => {
            setImageSrc(null)
        }
    }, [])

    return (
        <>
            <div className={className}>
                {imageSrc ? <img src={imageSrc} alt="user" height={height} width={width} loading="lazy" /> :
                    <Webcam
                        audio={false}
                        mirrored={facingMode === "user" ? true : false}
                        height={height}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={width}
                        className={`h-[${height}px]`}
                        videoConstraints={videoConstraints}
                    />
                }
            </div>
            <div className="w-full flex justify-center gap-2">
                {!imageSrc ? <button type="button" style={switchCss} onClick={switchCamera}><FiRefreshCw /></button> : null}
                {!imageSrc ? <SquareButton btnType={"button"} handleClick={capture} sqicon={<span className="text-xs">Capture photo</span>} />
                    : <SquareButton btnType={"button"} handleClick={() => { setImageSrc(null) }} sqicon={<span className="text-xs"><FiRefreshCw /></span>} />}
            </div>
        </>
    )
}

export default WebCam;