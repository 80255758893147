import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelPatientAppointmentsAsync, selectPatientAppointment } from "../../redux/patientAppointments/patientSlice";
import { Link } from "react-router-dom";
import { DateFormat, GetTime, Prifix } from "../../utils/helper";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

const UpCommingAppointments = () => {
  const dispatch = useDispatch();
  const [filteredPatient, setFilter] = useState([])
  const [itemPerPage, setItemPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const appointment = useSelector(selectPatientAppointment);
  const filteredAppointment = appointment && appointment.filter((item) =>
    (item.booked === true && item.isAppointmentDone === false && item.missed === false))?.sort((a, b) => GetTime(a.date) - GetTime(b.date))

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const handleFilter = (data) => {
    let from = DateFormat(data.from)
    let filteredPatient = filteredAppointment && filteredAppointment.filter((patient) => {
      if (DateFormat(patient.date) === from) {
        return patient
      }
    });
    if (filteredPatient.length === 0) {
      toast.error("No Appointments")
    }
    setFilter(filteredPatient)
  };

  function clearFilter() {
    setFilter([])
    reset()
  }

  // for delete appointment

  async function handleDelete(item) {
    const is = window.confirm("Are You Sure ?");
    try {
      if (is) {
        dispatch(cancelPatientAppointmentsAsync(item._id));
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className=" mt-2 items-baseline w-full p-2 flex justify-between bg-gray-200 border">
        <h1 className="text-sm font-medium text-[#301762] mb-2">
          Upcomming Appointments({filteredPatient?.length > 0 ? filteredPatient.length : filteredAppointment.length})
        </h1>
        <div className="flex gap-2">
          <form action="" className="flex gap-2" onSubmit={handleSubmit(handleFilter)}>
            <input
              className="p-2 rounded-sm text-xs"
              type="date"
              {...register("from")}
            />
            <SquareButton
              btnType={"submit"}
              sqicon={<span className="text-sm">Search</span>}
            />
          </form>
          <SquareButton
            handleClick={clearFilter}
            sqicon={<span className="text-sm">Reset</span>}
          />
        </div>
      </div>
      <div className="relative rounded-b-lg border-2 order-solid overflow-y-scroll">
        {appointment?.length ? (
          <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50  sticky top-0">
                <tr className="text-center">
                  <th scope="col" className="px-2 py-2 font-medium">
                    S.No.
                  </th>
                  <th scope="col" className="px-2 py-2 font-medium">
                    Name
                  </th>
                  <th scope="col" className="px-2 py-2 font-medium">
                    Mobile No.
                  </th>
                  <th scope="col" className="px-2 py-2 font-medium">
                    File No.
                  </th>
                  <th scope="col" className="px-2 py-2 font-medium">
                    Appointment Date
                  </th>
                  <th scope="col" className="px-2 py-2 font-medium">
                    Appointment Time
                  </th>
                  <th scope="col" className="px-2 py-2 font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(filteredPatient?.length > 0 ? filteredPatient : filteredAppointment)?.slice((page * itemPerPage) - itemPerPage, page * itemPerPage).map((item, index) => (
                  <tr
                    className="odd:bg-white text-center even:bg-blue-50 text-gray-700 text-[13px] border-t-2"
                    key={index}
                  >
                    <td
                      scope="row"
                      className="px-2 py-2 font-medium"
                    >
                      {index + 1}
                    </td>

                    <td className="px-2 py-2 border-none">
                      <Link
                        className="text-gray-700 hover:text-blue-600"
                        to={`/patient/patientdetails/${item?.patient_name?._id}`}
                      >
                        <h3 className={`px-4 py-2 ${item?.patient_name?.importance === "low" && "border-l-8 border-red-500"} ${item?.patient_name?.importance === "medium" && "border-l-8 border-green-500"} ${item?.patient_name?.importance === "high" && "border-l-8 border-yellow-500"}`}>{Prifix(item?.patient_name)} {item?.patient_name?.patient_name}&nbsp;{item?.patient_name?.surname} </h3>
                      </Link>
                    </td>
                    <td className="px-2 py-2">{item?.patient_name?.mobile}</td>
                    <td className="px-2 py-2">{item?.patient_name?.file_number}</td>
                    <td className="px-2 py-2">{DateFormat(item?.date)}</td>
                    <td className="px-2 py-2">{item?.time}</td>
                    <td className="px-2 py-2">
                      <button
                        onClick={() => {
                          handleDelete(item);
                        }}
                        className="bg-red-600 hover:bg-red-400 text-[13px] hover:scale-90 duration-300 cursor-pointer text-white p-1 px-2 rounded-sm"
                        title="Cancel Appointment"
                      >
                        Cancel Appointment
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* PAGINATION LOGIC USED HERE */}
            <div className="flex flex-row items-center justify-center my-2 gap-5 bg-[#eff6ff]">
              <IoMdArrowBack className="text-2xl cursor-pointer font-extrabold hover:scale-150 duration-300" onClick={() => { page > 1 ? setPage(page - 1) : setPage(1) }} />
              {(filteredPatient?.length > 0 ? filteredPatient : filteredAppointment)?.map((item, index) => (<>
                <span className="p-2 cursor-pointer hover:scale-75 duration-75" onClick={() => (setPage(index + 1))} >{index + 1}</span>
              </>))}
              <IoMdArrowForward className="text-2xl cursor-pointer font-extrabold hover:scale-150 duration-300 " onClick={() => { setPage(page + 1) }} />
            </div>
          </>) : (
          <div className="w-full flex flex-col items-center justify-center">
            <img src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527139.jpg?w=740&t=st=1715321245~exp=1715321845~hmac=ac03b291ef85c469e2935df5bdc8e42a6af065a8259681c1f1033ecd4776deba" alt="noData" />

            <div className="flex flex-col items-center justify-center">
              <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 mt-1">
                Data Not Found!
              </p>
              <p className="md:text-lg lg:text-xl text-gray-600 mt-8">
                Sorry, No Such Data Found , Please clear filter and try Again!.
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Return Home</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpCommingAppointments;
