import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  missedAppointmentNotification: [],
  upCommingAppointmentNotification: [],
};

export const setUpcommingNotification = createAsyncThunk(
  "notification/prenotification",
  async (prevnotification) => {
    const response = await new Promise((resolve, reject) => {
      resolve(prevnotification);
    });
    return response;
  }
);
export const setMissedNotification = createAsyncThunk(
  "notification/premissednotification",
  async (prevnotification) => {
    const response = await new Promise((resolve, reject) => {
      resolve(prevnotification);
    });
    return response;
  }
);

export const addMissednotification = createAsyncThunk(
  "notification/addMisssedAppointment",
  async (notification) => {
    const response = await new Promise((resolve, reject) => {
      resolve(notification);
    });
    return response;
  }
);
export const addUpcommingnotification = createAsyncThunk(
  "notification/addupcommingAppointment",
  async (notification) => {
    const response = await new Promise((resolve, reject) => {
      resolve(notification);
    });
    return response;
  }
);

export const removeMissedNotification = createAsyncThunk(
  "notification/removemissedNotification",
  async (appointmentId) => {
    const response = await new Promise((resolve, reject) => {
      resolve(appointmentId);
    });
    return response;
  }
);
export const removeUpcommingNotification = createAsyncThunk(
  "notification/removeupcommingNotification",
  async (appointmentId) => {
    const response = await new Promise((resolve, reject) => {
      resolve(appointmentId);
    });
    return response;
  }
);

export const notificationSlice = createSlice({
  name: "appointment notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addMissednotification.fulfilled, (state, action) => {
        let isExisit = state.missedAppointmentNotification.findIndex(
          (notify) =>
            notify?.appointmentDetails?._id ==
            action.payload.appointmentDetails._id
        );
        if (isExisit == -1) {
          state.missedAppointmentNotification.push(action.payload);
        }
      })
      .addCase(addUpcommingnotification.fulfilled, (state, action) => {
        let isExisit = state.upCommingAppointmentNotification.findIndex(
          (notify) =>
            notify?.appointmentDetails?._id ==
            action.payload.appointmentDetails._id
        );
        if (isExisit == -1) {
          state.upCommingAppointmentNotification.push(action.payload);
        }
      })
      .addCase(removeMissedNotification.fulfilled, (state, action) => {
        let index = state.missedAppointmentNotification.findIndex(
          (notify) => notify?.appointmentDetails?._id == action.payload
        );
        console.log(index);
        if (index != -1) {
          state.missedAppointmentNotification.splice(index, 1);
        }
      })
      .addCase(removeUpcommingNotification.fulfilled, (state, action) => {
        let index = state.upCommingAppointmentNotification.findIndex(
          (notify) => notify?.appointmentDetails?._id == action.payload
        );
        console.log(index);
        if (index != -1) {
          state.upCommingAppointmentNotification.splice(index, 1);
        }
      })
      .addCase(setUpcommingNotification.fulfilled, (state, action) => {
        state.upCommingAppointmentNotification = action.payload;
      })
      .addCase(setMissedNotification.fulfilled, (state, action) => {
        state.missedAppointmentNotification = action.payload;
      });
  },
});

export const selectMissedAppointmentNotification = (state) =>
  state.notification.missedAppointmentNotification;
export const selectUpcommingAppointmentNotification = (state) =>
  state.notification.upCommingAppointmentNotification;

export default notificationSlice.reducer;
