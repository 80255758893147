import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FaEye, FaRegCircleUser, FaTrash, FaWhatsapp } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import SquareButton from "../Ui Components/SquareButton";
import toast from "react-hot-toast";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientReportAsync,
  fetchPatientByIdAsync,
  selectedPatient,
  selectPatientReport,
} from "../../redux/patientAppointments/patientSlice";
import { base64ToBlob, DateFormat, Prifix } from "../../utils/helper";
import { ApiRemoveReportDelete } from "../../redux/patientAppointments/patientAppointmentAPI";
import WebCam from "../webcam/webcam";

function FileReports() {
  const dispatch = useDispatch();
  const reports = useSelector(selectPatientReport);
  const patientDetails = useSelector(selectedPatient);
  const [isLoading, setIsLoading] = useState(false)
  const [openWebCam, setOpenWebCame] = useState(false)
  const [capturedImage, setCapturedImage] = useState(null)
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const addLabReport = async (data) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("fileType", data.fileType);
    formData.append("note", data.note);

    if (openWebCam) {

      if (!capturedImage) {
        toast.error("Please Capture photo First")
        setIsLoading(false);
        return;
      }
      // Extract the base64 data (remove the data URL prefix)
      const base64Data = capturedImage.split(',')[1]; // Only the Base64 data
      const mimeType = capturedImage.match(/^data:(.*);base64,/)[1]; // Extract MIME type (e.g., image/jpeg)

      // Convert base64 to Blob
      const imageBlob = base64ToBlob(base64Data, mimeType);

      // Convert Blob to File (optional)
      const file = new File([imageBlob], `${patientDetails?.patient_name}-${patientDetails?.file_number}.jpg`, { type: mimeType });
      formData.append("paths", file);
    } else {
      formData.append("paths", data.paths[0]);
    }

    try {
      await toast.promise(
        dispatch(addPatientReportAsync({ id: id, reportDetails: formData })),
        {
          loading: "Saving...",
          success: "Report Added Successfully",
          error: "Please try again",
        }
      );
      reset();
      setIsLoading(false);
      setOpenWebCame(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleReportFileDelete = async (id, reportId) => {
    let ask = window.confirm("Do you want to delete this patient report?");
    if (ask) {
      let response = await ApiRemoveReportDelete(id, reportId);
      if (response.status) {
        toast.success("Report deleted successfully");
        dispatch(fetchPatientByIdAsync(id))
      } else {
        toast.error("Failed to delete report");
      }
    } else {
      toast.error("Report deletion cancelled");
    }
  };

  return (
    <>
      <div className="mb-2 overflow-y-scroll h-screen">
        {/* patient name and file number */}
        <div className="flex justify-between w-full p-3">
          <div className="flex">
            <span>
              <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
            </span>
            <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
              Patient Name -
            </p>
            <p className="text-gray-600 text-sm pt-[5px] pl-2">
              {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
            </p>
          </div>
          <div className="flex mr-2">
            <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
              File Number -
            </p>
            <p className="text-gray-600 text-sm pt-[5px] pl-2">
              {patientDetails?.file_number}
            </p>
          </div>
        </div>
        {/* add report  */}
        <div className="bg-white">
          <div className="flex items-center bg-gray-300 rounded-t-sm justify-center space-x-2 text-[#301762] text-sm px-4 py-2">
            <span className="tracking-wide font-medium">Upload Files</span>
          </div>
          <form onSubmit={handleSubmit(addLabReport)}>
            <div className="w-full m-4 px-4 flex flex-raw flex-wrap gap-6">
              <label
                htmlFor="file_type"
                className="block text-sm font-medium text-gray-600 mt-3"
              >
                File Type
              </label>
              <div className="mt-2">
                <select
                  id="fileType"
                  name="fileType"
                  {...register("fileType", {
                    required: "*File type is required",
                  })}
                  onChange={(e) => { e.target.value === "Consent Form" ? setOpenWebCame(true) : setOpenWebCame(false) }}
                  autoComplete="Select"
                  className="rounded-md border-0 py-1.5 px-1 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                >
                  <option value="">-Select-</option>
                  {/* <option value="User File">User File</option> */}
                  <option value="Report File">Report File</option>
                  <option value="Consent Form">Consent Form</option>
                  <option value="X-ray File">X-rays File</option>
                  <option value="Other">other</option>
                </select>
                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                  <ErrorMessage errors={errors} name="fileType" />
                </div>
              </div>
              {!openWebCam ? <div>
                <label className="block ">
                  <span className="sr-only">Choose profile photo</span>
                </label>
                <div className="mt-2">
                  <input
                    type="file"
                    name="paths"
                    {...register("paths", {
                      required:
                        "*Report file is required & only .pdf are allowed",
                    })}
                    className="block border rounded-md w-full text-sm text-slate-500 h-9
                            file:mr-4 file:py-3 file:px-5
                            file:rounded-md file:border-0
                            file:text-sm file:font-medium
                            file:bg-violet-50 file:text-[#301477]
                            hover:file:bg-[#301477] hover:file:text-white 
                          "
                  />
                </div>
                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                  <ErrorMessage errors={errors} name="paths" />
                </div>
              </div> : null}
              <div className="flex gap-5 justify-center">
                <div className="flex gap-5">
                  <label className="block text-sm font-medium leading-6 text-gray-600 mt-1">
                    Note
                  </label>
                  <textarea
                    className="rounded-md border-1 border-gray-600 mt-2 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                    name="note"
                    id="note"
                    cols="50"
                    rows="1"
                    {...register("note")}
                  ></textarea>
                </div>
                <div className="w-full md:w-1/1 p-1 md:mb-0">
                  {openWebCam ? <WebCam
                    className={"w-full p-1"}
                    height={330}
                    width={300}
                    getImage={(data) => { setCapturedImage(data) }}
                  /> : <div className="flex justify-center"><img src="/asset/images/unknown-image.jpg" className="m-2 h-[280px]" alt="user" loading="lazy" /></div>
                  }
                  {openWebCam ?
                    <SquareButton
                      btnType={"button"}
                      handleClick={() => { setOpenWebCame(false); setCapturedImage(""); }}
                      sqicon={<span className="text-xs">Close Camera</span>}
                      className={"bg-red-600 relative sm:top-[25px] sm:left-[0px] text-white rounded focus:ring-[#301762]"}
                    />
                    : null
                  }
                </div>
              </div>
            </div>
            <div className="ml-9 mb-4">
              <SquareButton
                btnType={"submit"}
                isLoading={isLoading}
                className={"bg-[#301762] text-white p-2 px-4 rounded-sm focus:ring-[#301762]"}
                sqicon={<span className="text-xs">Add File</span>}
              />
            </div>
          </form>
        </div>
        <hr />
        {/*table for patient reports  form*/}
        <table className="w-[94%] text-sm text-left text-gray-500 dark:text-gray-400 my-4 mx-8">
          <caption className="m-auto uppercase min-h-15 text-sm font-medium pl-5 py-1 text-white bg-[#9E9E9E]">
            Patient Reports
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-center">
              <th scope="col" className="px-2 py-2 font-medium">
                Sr.No.
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                File Type
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Note
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Uploaded Date
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                View
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Send
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {reports &&
              reports
                ?.filter((item) => item.fileType === "Report File")
                .map((report, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0 ? "odd:bg-white text-center text-xs" : "even:bg-gray-50 text-center text-xs"
                    }
                  >
                    <td className="px-2 py-2 font-medium">
                      {index + 1}
                    </td>
                    <td className="px-2 py-2">{report?.fileType}</td>
                    <td className="px-2 py-2">{report?.note}</td>
                    <td className="px-2 py-2">
                      {moment(report?.createdAt).format("DD-MMM-YY")}
                    </td>
                    <td className="px-2 py-2 flex justify-center pt-2">
                      <a href={report?.paths} target="_blank">
                        <FaEye className="hover:scale-150 transition text-xl" />
                      </a>
                    </td>
                    <td className="px-2 py-2 text-gray-700">
                      <div className="flex justify-center">
                        <SquareButton
                          sqicon={
                            <span className="text-xl" title="Send work To lab">
                              <Link
                                to={`https://web.whatsapp.com/send?phone=+91${patientDetails?.mobile}&text=Hi%20${patientDetails?.patient_name}%20Your%20Report%20Link%20Click%20Here%20${report?.paths}%20Date%20${DateFormat(report?.createdAt)}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                                target="_blank"
                                className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                              >
                                <FaWhatsapp />
                              </Link>
                            </span>
                          }
                          color={"#25D366"}
                        />
                      </div>
                    </td>
                    <td
                      className="px-2 py-2 text-center"
                      onClick={(e) => {
                        handleReportFileDelete(id, report._id);
                      }}
                    >
                      <FaTrash className="text-red-600 hover:scale-150 transition text-lg cursor-pointer" />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        {/*table for xrays  form*/}
        <table className="w-[94%] text-sm text-left text-gray-500 dark:text-gray-400 my-4 mx-8">
          <caption className="m-auto uppercase min-h-15 text-sm font-medium pl-5 py-1 text-white bg-[#9E9E9E]">
            X-ray Reports
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-center">
              <th scope="col" className="px-2 py-2">
                Sr.No.
              </th>
              <th scope="col" className="px-2 py-2">
                File Type
              </th>
              <th scope="col" className="px-2 py-2">
                Note
              </th>
              <th scope="col" className="px-2 py-2">
                Uploaded Date
              </th>
              <th scope="col" className="px-2 py-2">
                View
              </th>
              <th scope="col" className="px-2 py-2">
                Send
              </th>
              <th scope="col" className="px-2 py-2">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {reports &&
              reports
                ?.filter((item) => item.fileType === "X-ray File")
                .map((report, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 === 0 ? "odd:bg-white text-center text-xs" : "even:bg-gray-50 text-center text-xs"
                    }
                  >
                    <td className="px-2 py-2 font-medium">
                      {index + 1}
                    </td>
                    <td className="px-2 py-2">{report?.fileType}</td>
                    <td className="px-2 py-2">{report?.note}</td>
                    <td className="px-2 py-2">
                      {moment(report?.createdAt).format("DD-MMM-YY")}
                    </td>
                    <td className="px-2 py-2 flex justify-center pt-2">
                      <a href={report?.paths} target="_blank">
                        <FaEye className="hover:scale-150 transition text-xl" />
                      </a>
                    </td>
                    <td className="px-2 py-2 text-gray-700">
                      <div className="flex justify-center">
                        <SquareButton
                          sqicon={
                            <span className="text-xl" title="Send work To lab">
                              <Link
                                to={`https://web.whatsapp.com/send?phone=+91${patientDetails?.mobile}&text=Hi%20${patientDetails?.patient_name}%20Your%20X-ray%20Report%20Link%20Click%20Here%20${report?.paths}%20Date%20${DateFormat(report?.createdAt)}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                                target="_blank"
                                className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                              >
                                <FaWhatsapp />
                              </Link>
                            </span>
                          }
                          color={"#25D366"}
                        />
                      </div>
                    </td>
                    <td
                      className="px-2 py-2 text-center"
                      onClick={(e) => {
                        handleReportFileDelete(id, report._id);
                      }}
                    >
                      <FaTrash className="text-red-600 hover:scale-150 transition text-lg cursor-pointer" />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        {/*table for consent file */}
        {
          <table className="w-[94%] text-sm text-left text-gray-500 dark:text-gray-400 my-4 mx-8">
            <caption className="m-auto uppercase min-h-15 text-sm font-medium pl-5 py-1 text-white bg-[#9E9E9E]">
              Consent & other Formality Forms
            </caption>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr className="text-center">
                <th scope="col" className="px-2 py-2">
                  Sr.No.
                </th>
                <th scope="col" className="px-2 py-2">
                  File Type
                </th>
                <th scope="col" className="px-2 py-2">
                  Note
                </th>
                <th scope="col" className="px-2 py-2">
                  Uploaded Date
                </th>
                <th scope="col" className="px-2 py-2">
                  View
                </th>
                <th scope="col" className="px-2 py-2">
                  Send
                </th>
                <th scope="col" className="px-2 py-2">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {reports &&
                reports
                  ?.filter(
                    (item) =>
                      item.fileType === "Consent Form" ||
                      item.fileType === "Other"
                  )
                  .map((report, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0 ? "odd:bg-white text-center text-xs" : "even:bg-gray-50 text-center text-xs"
                      }
                    >
                      <td className="px-2 py-2 font-medium">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2">{report?.fileType}</td>
                      <td className="px-2 py-2">{report?.note}</td>
                      <td className="px-2 py-2">
                        {moment(report?.createdAt).format("DD-MMM-YY")}
                      </td>
                      <td className="px-2 py-2 flex justify-center pt-2">
                        <a href={report?.paths} target="_blank">
                          <FaEye className="hover:scale-150 transition text-xl" />
                        </a>
                      </td>
                      <td className="px-2 py-2 text-gray-700">
                        <div className="flex justify-center">
                          <SquareButton
                            sqicon={
                              <span className="text-xl" title="Send work To lab">
                                <Link
                                  to={`https://web.whatsapp.com/send?phone=+91${patientDetails?.mobile}&text=Hi%20${patientDetails?.patient_name}%20Your%20Consent%20Form%20Link%20Click%20Here%20${report?.paths}%20Date%20${DateFormat(report?.createdAt)}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                                  target="_blank"
                                  className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                                >
                                  <FaWhatsapp />
                                </Link>
                              </span>
                            }
                            color={"#25D366"}
                          />
                        </div>
                      </td>
                      <td
                        className="px-2 py-2 text-center"
                        onClick={(e) => {
                          handleReportFileDelete(id, report._id);
                        }}
                      >
                        <FaTrash className="text-red-600 hover:scale-150 transition text-lg cursor-pointer" />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        }
      </div>
    </>
  );
}

export default FileReports;
