// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sqbtn {
  display: grid;
  place-items: center;
  background: #ffffff;
  border-radius: 3px;
  color: white;
  padding: 5px 10px;
  /* border: 1px solid rgba(0, 0, 0, 0); */
  /* font-size: 0.08em; */
  cursor: pointer;
  transition: 0.1s;
}

.sqbtn:hover {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border: 1px solid #301762;
  transform: translateY(0.2em);
  background: #fff;
  color: #301762;

}

.sqbtn svg {
  transition: 0.1s;
}

.sqbtn:hover svg {
  transform: scale(0.9);
  fill: #333333;
}

.loader {
  height: 40px;
  width: 40px;
  border: 6px solid white;
  border-top: 6px solid black;
  border-radius: 50%;
  animation: loading linear 1s infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Ui Components/SquareButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,wCAAwC;EACxC,uBAAuB;EACvB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;;;;2CAIyC;EACzC,yBAAyB;EACzB,4BAA4B;EAC5B,gBAAgB;EAChB,cAAc;;AAEhB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,2BAA2B;EAC3B,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".sqbtn {\n  display: grid;\n  place-items: center;\n  background: #ffffff;\n  border-radius: 3px;\n  color: white;\n  padding: 5px 10px;\n  /* border: 1px solid rgba(0, 0, 0, 0); */\n  /* font-size: 0.08em; */\n  cursor: pointer;\n  transition: 0.1s;\n}\n\n.sqbtn:hover {\n  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),\n    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),\n    -0.5px -0.5px 0px rgba(255, 255, 255, 1),\n    0.5px 0.5px 0px rgba(0, 0, 0, 0.15),\n    0px 12px 10px -10px rgba(0, 0, 0, 0.05);\n  border: 1px solid #301762;\n  transform: translateY(0.2em);\n  background: #fff;\n  color: #301762;\n\n}\n\n.sqbtn svg {\n  transition: 0.1s;\n}\n\n.sqbtn:hover svg {\n  transform: scale(0.9);\n  fill: #333333;\n}\n\n.loader {\n  height: 40px;\n  width: 40px;\n  border: 6px solid white;\n  border-top: 6px solid black;\n  border-radius: 50%;\n  animation: loading linear 1s infinite;\n}\n\n@keyframes loading {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
