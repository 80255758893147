import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW MATERIAL API =======================
export async function addMaterial(material) {
    const response = await axiosClient().post(
        `${webURLs.ADD_MATERIAL}`,
        material
    );
    return response.data;
}
//=================== GET ALL MATERIAL API =======================
export async function getAllMaterial() {
    const response = await axiosClient().get(`${webURLs.GET_MATERIAL}`);
    return response.data;
}

//========================= DELETE MATERIAL API ==================

export async function deleteMaterial(materialId) {
    const response = await axiosClient().delete(
        `${webURLs.DELETE_MATERIAL}${materialId}`
    );
    return response.data;
}
