import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  PATIENT_DETAILS_NAVBAR_LINKS,
  PATIENT_DETAILS_SIDEBAR_LINKS,
} from "../../routes/SideBarTabs";
import Details from "./Details";
import AllAppointments from "./AllAppointments";
import Procedure from "./Procedure";
import Bill from "./Bill";
import FileReports from "./FileReports";
import ClinicalExamination from "./ClinicalExamination";
import AddPrescription from "./AddPrescription";
import PatientCommunication from "./PatientCommunication";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillsByPatientIdAsync, fetchPatientByIdAsync, selectedPatient } from "../../redux/patientAppointments/patientSlice";
import { getAllProcedureAsync } from "../../redux/procedure/procedureSlice";
import { FaUser } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import SquareButton from "../Ui Components/SquareButton";
import WebCam from "../webcam/webcam";
import { base64ToBlob } from "../../utils/helper";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import LabDetails from "./labDetails";
import OrthoDetails from "./orthoDetails";
const linkClass = "flex items-center gap-2 font-medium  px-3 py-3 mr-0 hover:bg-[#d2c2f1] hover:text-[#301762] hover:no-underline rounded-sm";

const Tabs = () => {
  const dispatch = useDispatch()
  const patientDetails = useSelector(selectedPatient)
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState("patient-details");
  const [openWebCam, setOpenWebCame] = useState(false)
  const [capturedImage, setCapturedImage] = useState(null)
  const [isLoading, setLoading] = useState(false)

  async function uploadImage() {
    setLoading(true)
    // Extract the base64 data (remove the data URL prefix)
    const base64Data = capturedImage.split(',')[1]; // Only the Base64 data
    const mimeType = capturedImage.match(/^data:(.*);base64,/)[1]; // Extract MIME type (e.g., image/jpeg)

    // Convert base64 to Blob
    const imageBlob = base64ToBlob(base64Data, mimeType);

    // Convert Blob to File (optional)
    const file = new File([imageBlob], `${patientDetails?.patient_name}.jpg`, { type: mimeType });

    const formData = new FormData()
    formData.append("patient", file)

    try {
      let res = await axiosClient().patch(`${webURLs.UPLOAD_PATIENT_PHOTO}${id}`, formData)
      if (res.data.status) {
        dispatch(fetchPatientByIdAsync(id))
        setCapturedImage(null)
        setOpenWebCame(false)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }

  }

  useEffect(() => {
    dispatch(fetchPatientByIdAsync(id))
    dispatch(fetchBillsByPatientIdAsync(id))
    dispatch(getAllProcedureAsync(id))
    return () => {
      dispatch(fetchPatientByIdAsync(false))
    }
  }, [id])

  return (
    <>
      <div className="w-full flex flex-cols gap-2 mt-1">
        <div className="w-[80%] border-2">
          <nav className="bg-white">
            <div className="flex flex-cols justify-center gap-0.5 bg-gray-100">
              {PATIENT_DETAILS_NAVBAR_LINKS.map((link, index) => (
                <div key={index} onClick={() => setActiveTab(link.key)}>
                  <NavbarLink
                    key={link.key}
                    link={link}
                    activeTab={activeTab}
                  />
                </div>
              ))}
            </div>
            <hr />
          </nav>
          <div>
            {activeTab === "patient-details" && <Details />}
            {activeTab === "appointments" && <AllAppointments />}
            {activeTab === "procedure" && <Procedure />}
            {activeTab === "bill" && <Bill />}
            {activeTab === "lab-reports" && <FileReports />}
            {activeTab === "clinical-examination" && <ClinicalExamination />}
            {activeTab === "add-prescription" && <AddPrescription />}
            {activeTab === "patient-communication" && <PatientCommunication />}
            {activeTab === "lab-details" && <LabDetails />}
            {activeTab === "ortho-details" && <OrthoDetails />}
          </div>
        </div>
        {/* Right Sidebar */}
        <div className="w-[20%] h-[700px] border-2 overflow-hidden">
          <div>
            {PATIENT_DETAILS_SIDEBAR_LINKS.map((link, index) => (
              <div key={index} onClick={() => setActiveTab(link.key)}>
                <NavbarLink key={link.key} link={link} activeTab={activeTab} />
              </div>
            ))}
          </div>
          <div className="flex justify-center flex-wrap gap-2">
            {openWebCam ? <WebCam
              className={"h-[260px] w-full border m-2 bg-slate-400 flex justify-center items-center"}
              height={280}
              width={220}
              getImage={(data) => { setCapturedImage(data) }}
            /> : <>{patientDetails?.image ? <img src={patientDetails?.image} className="m-2" alt="user" loading="lazy" height={280} width={220} /> :
              <div className="h-[260px] w-full p-2 border m-2 bg-slate-400 flex justify-center items-center">
                <FaUser fontSize={120} color="white" />
              </div>
            }</>}
            {capturedImage ?
              <div className="flex justify-center "><SquareButton handleClick={uploadImage} isLoading={isLoading} sqicon={<span className="text-xs">Save Photo</span>} /></div> : null}
          </div>
          {patientDetails?.image && !openWebCam ? <div className="flex justify-center mt-2">
            <SquareButton handleClick={() => { setOpenWebCame(true) }} sqicon={<span className="text-xs">Change Photo</span>} />
          </div> :
            <div className="flex justify-center gap-2 mt-2">
              {openWebCam ? <SquareButton handleClick={() => { setOpenWebCame(false); setCapturedImage(null) }} className={"bg-red-500 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-3 rounded-sm"} sqicon={<span className="text-sm"><RxCross2 /></span>} /> :
                <SquareButton handleClick={() => { setOpenWebCame(true) }} sqicon={<span className="text-xs">Open Camera</span>} />}
            </div>}
        </div>
      </div>
    </>
  );
};

function NavbarLink({ link, activeTab }) {
  return (
    <Link
      to={link.path}
      className={classNames(
        activeTab === link.key ? "bg-[#d2c2f1] text-[#301762] text-sm" : "text-gray-800 text-sm",
        linkClass
      )} //rounded-2xl
    >
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
}
export default Tabs;
