import React, { useEffect, useState } from 'react'
import { Prifix, DateFormat } from '../../utils/helper'
import { useSelector } from 'react-redux'
import { selectedPatient } from '../../redux/patientAppointments/patientSlice'
import { FaRegCircleUser } from 'react-icons/fa6'
import SquareButton from '../Ui Components/SquareButton'
import AudioRecorder from '../audioRecorder/recorder'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { addVoiceRecording, deleteVoiceRecording, getVoiceRecordingByPatientId } from '../../redux/voiceRecordSection/voiceApis'
import { useParams } from 'react-router-dom'
import { RiDeleteBin6Line } from 'react-icons/ri'

const PatientCommunication = () => {
  const { id } = useParams();
  const patientDetails = useSelector(selectedPatient)
  const [audioFile, setAudioFile] = useState(false)
  const [Recording, setRecordings] = useState([])
  const [reload, setReload] = useState(true)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  // save voice recording function
  async function handleAddVoiceRecorder(data) {

    if (!audioFile) {
      toast.error("Audio Recording Is  Not found")
    }
    // convert blob to file 
    const file = new File([audioFile], `${Date.now()}-Rec.wav`, { type: 'audio/wav' });

    const formData = new FormData()
    formData.append("note", data.note)
    formData.append("recording", file)

    try {

      let response = await addVoiceRecording(id, formData)

      if (!response.status) {
        toast.error(response.message)
        setReload(!reload)
        return;
      }
      toast.success(response.message)
      setRecordings([...Recording, response.data])
      setReload(!reload)
      reset()
    } catch (error) {
      console.log(error);
    }

  }

  // delete Recording function

  async function DeleteRecording(id) {

    let isDel = window.confirm("Are You Sure Want To Delete ?")

    if (isDel) {
      try {
        let response = await deleteVoiceRecording(id)
        if (!response.status) {
          toast.error(response.message)
          return;
        }
        toast.success(response.message)
        setRecordings(Recording.filter((item) => item._id !== response.data._id))
      } catch (error) {
        console.log(error);
      }
    }

  }

  useEffect(() => {
    (async function () {
      try {
        let response = await getVoiceRecordingByPatientId(id)
        if (response.status) {
          setRecordings(response.data)
          return;
        }
      } catch (error) {
        console.log(error);
      }
    })()
  }, [id])

  return (
    <div className='mb-2'>
      <div className="flex justify-between w-full p-3">
        <div className="flex">
          <span>
            <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
          </span>
          <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
            Patient Name -
          </p>
          <p className="text-gray-600 text-sm pt-[5px] pl-2">
            {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
          </p>
        </div>
        <div className="flex mr-2">
          <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
            File Number -
          </p>
          <p className="text-gray-600 text-sm pt-[5px] pl-2">
            {patientDetails?.file_number}
          </p>
        </div>
      </div>
      <section
        id="medicine-section"
        className="bg-white mb-2"
      >
        <div className="w-full pl-2 bg-gray-300 rounded-t-sm text-white items-center">
          <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
            Patient Communication
          </h1>
        </div>
        <hr />
        {/* //======================file display table section start============================= */}
      </section>
      <form className="py-4" onSubmit={handleSubmit(handleAddVoiceRecorder)}>
        <div className="w-full m-1 px-4 flex justify-around flex-raw flex-wrap gap-3">
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-600">
              Note
            </label>
            <textarea
              className="rounded-md border-1 border-gray-600 mt-1 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
              name="note"
              id="note"
              cols="30"
              rows="4"
              {...register("note", { required: "Note is Required" })}
            ></textarea>
            {errors.note && <span className='text-red-600'>*Note is required</span>}
          </div>
          <div className="">
            <AudioRecorder setAudioFile={setAudioFile} reload={reload} />
          </div>
        </div>
        <div className="p-8">
          <SquareButton
            btnType={"submit"}
            // isLoading={isLoading}
            sqicon={<span className="text-xs">Save Recording</span>}
          />
        </div>
      </form>
      <section
        id="medicine-section"
        className="bg-white rounded overflow-y-auto mb-2"
      >
        <div className="w-[100%] pl-2 bg-gray-300 rounded-t-sm text-white items-center">
          <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
            Total voice Notes
          </h1>
        </div>
        <hr />
        {/* //======================file display table section start============================= */}
        <table className="w-[100%] text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-center">
              <th className="px-2 py-2 font-medium">
                Sr.No.
              </th>
              <th className="px-2 py-2 font-medium">
                Note
              </th>
              <th className="px-2 py-2 font-medium">
                Recordings
              </th>
              <th className="px-2 py-2 font-medium">
                Date
              </th>
              <th className="px-2 py-2 font-medium">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {Recording && Recording.map((item, idx) => (
              <tr key={idx} className='text-center'>
                <td>{idx + 1}</td>
                <td>{item.note}</td>
                <td>
                  <audio controls className="w-full mt-1">
                    <source src={item.voice_url} type="audio/wav" />
                  </audio>
                </td>
                <td>
                  {DateFormat(item.createdAt)}
                </td>
                <td>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        DeleteRecording(item?._id);
                      }}
                      className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                    >
                      <RiDeleteBin6Line className="p-0 m-0 text-lg" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* //======================file display table section start============================= */}
      </section>
    </div>
  )
}

export default PatientCommunication
