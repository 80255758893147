import React, { useState, useRef, useEffect } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';

const AudioRecorder = ({ setAudioFile,reload }) => {

    const [isRecording, setIsRecording] = useState(false);
    const [recordings, setRecordings] = useState([]);  // Store multiple recordings
    const [timer, setTimer] = useState(0);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const timerRef = useRef(null);

    const handleStartRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    noiseSuppression: true,  // Reduce background noise
                    echoCancellation: true,  // Remove echo
                },
            });

            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunks.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setRecordings([{ url: audioUrl, duration: formatTime(timer) }]);
                audioChunks.current = [];
                clearInterval(timerRef.current);
                setTimer(0);
                setAudioFile(audioBlob)
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);

            timerRef.current = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);

        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const handleStopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    // for reset recording
    function deleteRec() {
        setRecordings([])
        setAudioFile(false)
    }

    useEffect(() => {
        setRecordings([])
        setAudioFile(false)
        return () => {
            setRecordings([])
            setAudioFile(false)
        }
    }, [reload,setAudioFile])

    return (
        <div className="">
            <h1 className="text-lg font-bold mb-4">Audio Recorder with Noise Suppression</h1>

            {!isRecording ? (
                <button
                    type='button'
                    onClick={handleStartRecording}
                    className="bg-green-500 text-sm text-white py-1 px-2 rounded hover:bg-green-600"
                >
                    Start Recording
                </button>
            ) : (
                <button
                    type='button'
                    onClick={handleStopRecording}
                    className="bg-red-500 text-sm text-white py-1 px-2 rounded hover:bg-red-600"
                >
                    Stop Recording
                </button>
            )}

            {isRecording && (
                <div className="mt-1 text-sm font-semibold">
                    Recording Time: {formatTime(timer)}
                </div>
            )}

            {recordings.length > 0 && (
                <div className="mt-2">
                    <h2 className="text-sm font-semibold mb-2">Saved Recording</h2>
                    <ul className="space-y-4">
                        {recordings.map((recording, index) => (
                            <li key={index} className="border p-1 rounded bg-gray-100">
                                <div className='flex justify-between p-2'>
                                    <p className="ml-2 font-medium">Recording {index + 1} (Duration: {recording.duration})</p>
                                    <button type='button' className='bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm' onClick={deleteRec}>
                                        <RiDeleteBin6Line className="p-0 m-0 text-lg" />
                                    </button>
                                </div>
                                <audio controls className="w-full mt-1">
                                    <source src={recording.url} type="audio/wav" />
                                </audio>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AudioRecorder;
