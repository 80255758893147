import React from "react";
import { FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../redux/user/userSlice";
import { DateFormat } from "../../utils/helper";
function Userprofile() {
  const userDetails = useSelector(selectLoggedInUser);
  return (
    <div>
      <div className="container mx-auto my-5">
        <div className="md:flex no-wrap md:-mx-2 ">
          {/* Left Side */}
          <div className="w-full md:w-3/12 md:mx-2 shadow border-2">
            {/* Profile Card */}
            <div className="bg-white p-3 ">
              <div
                className=" h-40 w-40 image mx-auto overflow-hidden rounded-[50%] border-4 border-[#301762] shadow-mg shadow-black"
                id="edit_profile_pic" title="Edit Profile"
              >
                <img
                  className="h-auto w-full mx-auto tool"
                  src={userDetails && userDetails.avatar}
                  alt="profile_img"
                  id="tooltip-default"
                  loading="lazy"
                />
              </div>
              <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                {userDetails?.name}
              </h1>
              <h3 className="text-gray-600 font-lg text-semibold leading-6">
                {userDetails?.qualification}
              </h3>
              <h3 className="text-gray-600 font-lg text-semibold leading-6">
                {userDetails?.specialization}
              </h3>
              <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                <li className="flex items-center py-3">
                  <span>Status</span>
                  <span className="ml-auto">
                    <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                      {userDetails?.isActive?"Active":"Deactive"}
                    </span>
                  </span>
                </li>
                <li className="flex items-center py-3">
                  <span>Member since</span>
                  <span className="ml-auto">{DateFormat(userDetails.createdAt)}</span>
                </li>
              </ul>
            </div>
            {/* End of profile card */}
          </div>
          {/* Right Side */}
          <div className="w-full md:w-9/12 mx-2">
            {/* Profile tab */}
            {/* About Section */}
            <div className="bg-white p-3 shadow border-2 rounded-sm h-100">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span className="text-green-500">
                  <svg
                    className="h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
                <span className="tracking-wide">About</span>
              </div>
              <div className="text-gray-700">
                <div className="grid md:grid-cols-2 text-sm">
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">Full Name : </div>
                    <div className="px-4 py-4">{userDetails.name}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">Role</div>
                    <div className="px-4 py-4">{userDetails.role===0?"Receptionist":"Doctor"}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">Contact No.</div>
                    <div className="px-4 py-4">+91 {userDetails.mobile}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">Email.</div>
                    <div className="px-4 py-4">
                      <a
                        className="text-blue-800"
                        href={`mailto:${userDetails.email}`}
                      >
                        {userDetails.email}
                      </a>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">Gender</div>
                    <div className="px-4 py-4">{userDetails.gender}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">Age</div>
                    <div className="px-4 py-4">{userDetails.age} years</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-4 font-semibold">
                      Permanant Address
                    </div>
                    <div className="px-4 py-4">
                      {userDetails?.address}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end	">
                <button className="w-10 text-[#301762] text-xl font-bold hover:bg-[#301762] rounded hover:text-white focus:outline-none focus:shadow-outline focus:bg-[#301762] hover:shadow-xs p-3 my-5">
                  <FaEdit />
                </button>
              </div>
            </div>
            {/* End of about section */}

            {/* End of profile tab */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userprofile;
