import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useRef, useState } from "react";
import TimeSlotComponent from "../slotCalendar/TimeSlotComponent";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { bookPatientAppointmentAsync, selectPatientAppointment } from "../../redux/patientAppointments/patientSlice";
import { selectEveningSlots, selectMorningSlots } from "../../redux/bookingSlot/bookingSlotSlice";

const AddNewPatientAppointment = ({setOpen, patientId }) => {
  const dispatch = useDispatch()
  const nameRef = useRef();
  const dateRef = useRef();
  const doctorRef = useRef();
  const fileRef = useRef();
  const timeRef = useRef();
  const treatmentRef = useRef();
  const mobileRef = useRef();

  const todayDate = new Date();
  // console.log(todayDate);
  // console.log(todayDay);
  let bookingDate = moment(todayDate).format("yyyy-MM-DD");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();



  const morningSlots = useSelector(selectMorningSlots)
  const eveningSlots = useSelector(selectEveningSlots);
  const appointment = useSelector(selectPatientAppointment);
  const [patient, setPatient] = useState([]);
  const [scheduledTime, setScheduledTime] = useState("");
  const [isBooked, setIsBooked] = useState(false);
  // console.log(morningSlots);

  useEffect(() => {
    axiosClient()
      .get(`${webURLs.PATIENTS}/${patientId}`)
      .then((res) => setPatient(res.data.data))
      .catch((error) => console.log(error.message));

    axiosClient()
      .get(
        `${webURLs.GET_APPOINTMENT}?date=${bookingDate}&time=${scheduledTime}`
      )
      .then((response) => {
        response.data.data?.length === 1
          ? setIsBooked(true)
          : setIsBooked(false);
      });
  }, [scheduledTime]);
  //==========Appointment Booking Function=============
  const handleAppointmentBooking = () => {
    const bookingDetails = {
      patient_name: nameRef.current.value,
      doctor: doctorRef.current.value,
      date: dateRef.current.value,
      time: timeRef.current.value,
      file_number: fileRef.current.value,
      treatment: treatmentRef.current.value,
      priority: patient.importance,
      mobile: mobileRef.current.value,
      gender:patient.gender
    };
    dispatch(bookPatientAppointmentAsync(bookingDetails))
    setOpen(false)
   
  };
  console.log(appointment)
  return (
    <>
      <h1 className="text-2xl mb-7 mt-3 font-semibold text-center">
        Schedule Appointment
      </h1>

      <form
        className="space-y-6"
        onSubmit={handleSubmit(handleAppointmentBooking)}
      >
        {/* {patient.map((patient) => ( */}
        <>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Patient's Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  ref={nameRef}
                  type="text"
                  value={patient.patient_name}
                  autoComplete="name"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="mobile"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Mobile no.
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  ref={mobileRef}
                  required
                  value={parseInt(patient.mobile)}
                  className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="file"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                File No.
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  required
                  ref={fileRef}
                  value={patient.file_number}
                  id="FileNo."
                  className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="scheduleddate"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Scheduled Date
              </label>
              <div className="mt-2">
                <input
                  id="scheduleddate"
                  name="scheduleddate"
                  type="date"
                  defaultValue={moment(Date.now()).format("yyyy-MM-DD")}
                  autoComplete="date"
                  ref={dateRef}
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="scheduledtime"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Scheduled Time
                </label>
                <div className="text-sm"></div>
              </div>
              <div className="mt-2">
                <input
                  id="scheduledtime"
                  name="scheduledtime"
                  type="text"
                  value={scheduledTime}
                  ref={timeRef}
                  autoComplete="time"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="doctor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Doctor Name
              </label>
              <div className="mt-2">
                <select
                  id="doctor"
                  name="doctor"
                  value={patient.doctor}
                  type="text"
                  ref={doctorRef}
                  autoComplete="doctor"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="660ff939a86950eb475caf85">Sumit Rathi</option>
                  <option value="660e684ecc1f62dadcb1818e">
                    Dr.Ankita Rathi
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="treatment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Treatment
            </label>
            <div className="mt-2">
              <select
                id="treatment"
                name="treatment"
                type="text"
                value={patient?.treatment}
                autoComplete="treatment"
                ref={treatmentRef}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">-Select-</option>
                <option value="RTC 1">RTC 1</option>
                <option value="RTC 2">RTC 2</option>
                <option value="RTC 3">RTC 3</option>
                <option value="scaling and curettage with pollish">Scaling And Curettage With Pollish</option>
                <option value="extraction">Extraction</option>
                <option value="disimpaction">Disimpaction</option>
                <option value="orthodontic treatment">Orthodontic treatment</option>
                <option value="fixed partial denture">Fixed Partial Denture</option>
                <option value="removable partial denture">Removable Partial Denture</option>
                <option value="complete dentures">Complete Denture</option>
                <option value="smile designing">Smile Designing</option>
              </select>
              <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                <ErrorMessage errors={errors} name="treatment" />
              </div>
            </div>
          </div>

          <hr />
          <div
            id="slot-time-container"
            className="flex w-full py-2 text-sm cursor-pointer text-white"
          >
            <div id="morning-slot-timming-tab" className="w-1/2 px-1">
              <h1 className="bg-gray-300 text-center  text-xl py-1 font-medium text-blue-800">
                Morning
              </h1>
              <div className="grid grid-cols-3 py-2">
                {morningSlots.map((slot, index) => (
                  <>
                    <div
                      onClick={() => {
                        setScheduledTime(slot.startTime);
                      }}
                    >
                      <TimeSlotComponent
                        time={slot.startTime}
                        date={bookingDate}
                        appointment={appointment}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div id="morning-slot-timming-tab" className="w-1/2 px-1">
              <h1 className="bg-gray-300 text-center text-xl py-1 font-medium text-blue-800">
                Evening
              </h1>
              <div className="grid grid-cols-3  py-2">
                <>
                  {eveningSlots.map((slot, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setScheduledTime(slot.startTime);
                        // console.log(slot.startTime);
                      }}
                    >
                      <TimeSlotComponent
                        time={slot.startTime}
                        date={bookingDate}
                        appointment={appointment}
                      />
                    </div>
                  ))}
                </>
              </div>
            </div>
          </div>
        </>
        <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          {!isBooked ? (
            <button
              type="submit"
              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
            >
              Save
            </button>
          ) : (
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-gray-500 px-3 py-1  text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
            >
              Appointment is already booked , Change Appointment Time
            </button>
          )}
        </div>
        {/* ))} */}
      </form>
    </>
  );
};

export default AddNewPatientAppointment;
