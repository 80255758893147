// Import component
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as COMPONENT_ROUTE from "../../routes/ComponentRoute";
import { selectAllPatient } from "../../redux/patientAppointments/patientSlice";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import { GetTime } from "../../utils/helper";
import { useLocation } from "react-router-dom";
import { SlUserFemale, SlUser } from "react-icons/sl";

const Patients = () => {
  const allPatient = useSelector(selectAllPatient);
  const [filteredPatient, setFilter] = useState([]);
  const toDate = DateFormat()
  const [AllGender, setAllGender] = useState({ male: 0, female: 0 });
  const [formData, setFormData] = useState(null)
  const state = useLocation().state


  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const handleFilter = (e) => {
    // e.preventDefault()
    const nameValue = e
    let filteredPatient = allPatient && allPatient.filter((patient) => {
      return (
        (nameValue &&
          patient.patient_name.toLowerCase().includes(nameValue)) ||
        (nameValue &&
          patient?.surname?.toLowerCase()?.includes(nameValue)) ||
        (nameValue &&
          patient.file_number.includes(nameValue)) ||
        (nameValue &&
          patient.mobile.includes(nameValue))
      );
    });

    setFilter(filteredPatient)
  };

  const handleFilter1 = useCallback(() => {
    let malePatient = (filteredPatient.length > 0 ? filteredPatient : allPatient)?.filter((patient) => (patient.gender.toLowerCase() === "male"));
    let femalePatient = (filteredPatient.length > 0 ? filteredPatient : allPatient)?.filter((patient) => (patient.gender.toLowerCase() === "female"));
    setAllGender({ male: malePatient?.length, female: femalePatient?.length })
  }, [allPatient, filteredPatient]);

  const handleFilter2 = useCallback((data) => {

    setFormData(data)
    let from = GetTime(data.from)
    let to = GetTime(data.toDate) + 1000 * 60 * 60 * 12

    let filteredPatient = allPatient && allPatient.filter((patient) => GetTime(patient.createdAt) >= from && GetTime(patient.createdAt) <= to && patient);

    filteredPatient = (filteredPatient.length ? filteredPatient : allPatient).filter(
      (patient) => patient.importance.toLowerCase().includes(data.importance)
    )
    setFilter(filteredPatient)
  }, [allPatient]);

  function clearFilter() {
    setFilter([])
    reset()
  }

  function DateFormat() {
    let date = new Date()
    let day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    return `${date.getFullYear()}-${month}-${day}`
  }


  const handleBackForm = useCallback(() => {
    if (state != null) {
      handleFilter2(state.formDate)
    }
  }, [state, handleFilter2])


  useEffect(() => {
    handleFilter1()
  }, [filteredPatient, handleFilter1])


  useEffect(() => {
    handleBackForm()
  }, [handleBackForm])


  return (
    <>
      {/* <div className="container"> */}
      <div className="flex flex-col md:flex-row w-full p-0">
        <div className="w-fit md:w-1/3 p-1 rounded md:mb-0">
          <div className="flex rounded border-2 flex-col items-center overflow-hidden pb-3">
            <div className="items-baseline w-full bg-slate-300 p-2">
              <h1 className="text-sm text-center text-blue font-medium">Patient Filter</h1>
            </div>
            <div className="w-fit border-b-[1px] py-4 px-4">
              <form className="space-y-6">
                <div className="">
                  <div>
                    <label
                      htmlFor="searchValue"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Patient Name | mobile | File Number
                    </label>
                    <div className="mt-2">
                      <input
                        id="searchValue"
                        type="text"
                        name="searchValue"
                        onChange={(e) => { handleFilter(e.target.value) }}
                        className="block w-full rounded-sm border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-3"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <hr className="mt-4" />
              <form className="space-y-6 mb-3 mt-2" onSubmit={handleSubmit(handleFilter2)}>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="gender"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      From Date
                    </label>
                    <div className="mt-2">
                      <input
                        id="from"
                        name="from"
                        type="date"
                        {...register("from")}
                        defaultValue={state?.formDate?.from}
                        className="block h-8 lowercase w-full rounded-sm border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="toDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      To Date
                    </label>
                    <div className="mt-2">
                      <input
                        id="toDate"
                        name="toDate"
                        type="date"
                        {...register("toDate")}
                        defaultValue={toDate || state?.formDate?.toDate}
                        className="block h-8 lowercase w-full rounded-sm border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="searchValue"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Patient Priority
                    </label>
                    <div className="mt-2">
                      <select
                        id="priority"
                        name="importance"
                        {...register("importance")}
                        defaultValue={state?.formDate?.importance}
                        autoComplete="Select"
                        className="block w-full rounded-sm border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-8"
                      >
                        <option value="">-Select-</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div className="mt-2">
                  <SquareButton
                    btnType={"submit"}
                    sqicon={<span className="text-xs">Search</span>}
                  />
                </div>
              </form>
              <hr className="mt-4" />
              <div className="flex items-center gap-4 mt-4 mb-2">
                <SquareButton
                  handleClick={clearFilter}
                  sqicon={<span className="text-xs">Clear Filter</span>}
                />
              </div>
            </div>
            <h1 className="mt-3 text-[15px] text-left" >Total Patients : {allPatient.length}</h1>
            <h2 className="mt-3 text-[15px]">Total Search Result : {filteredPatient.length}</h2>
            <div className="mt-3 flex justify-center gap-2">
              {/* <FaMale className="text-3xl" /> */}
              <SlUser className="text-blue-900 text-2xl" />
              <h2 className="mt-1 text-[15px]">Total Male Patients : {AllGender?.male}</h2>
            </div>
            <div className="mt-3 flex justify-center gap-2">
              {/* <FaFemale className="text-3xl" /> */}
              <SlUserFemale className="text-2xl text-blue-900" />
              <h2 className="mt-1 text-[15px]">Total Female Patients : {AllGender?.female}</h2>
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3 p-1 rounded md:mb-0">
          <COMPONENT_ROUTE.TABLE data={filteredPatient.length > 0 ? filteredPatient : allPatient} formDate={formData} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Patients;
