import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const PatientFilter = () => {
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    // console.log("newValue:", newValue);
    setValue(newValue);
  };
  return (
    <div className="flex flex-col h-[420px] items-center w-fit">
      <div className=" mt-5 items-baseline w-[100%] p-3  shadow-2xl border-b-[1px]">
        <h1 className="  text-2xl font-bold  mb-2">Patient Filter</h1>
      </div>
      <div className="sm:mx-auto w-fit bg-slate-100   border-b-[1px]  py-4 px-14">
        <form className="space-y-6  w- " action="#" method="POST">
          <div className=" grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <Datepicker
                primaryColor={"blue"}
                value={value}
                onChange={handleValueChange}
                showShortcuts={true}
              />
            </div>
            <div>
              <label
                htmlFor="gender"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Gender
              </label>
              <div className="mt-2">
                <select
                  id="gender"
                  name="gender"
                  // type="text"
                  autoComplete="Select"
                  required
                  className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {" "}
                  <option value="select">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            <div>
              <label
                htmlFor="filenumber"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                File Number
              </label>
              <div className="mt-2">
                <input
                  id="filenumber"
                  name="filenumber"
                  type="text"
                  autoComplete="filenumber"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="doctor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Doctor Name
              </label>
              <div className="mt-2">
                <input
                  id="doctor"
                  name="doctor"
                  type="text"
                  autoComplete="doctor"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-fit justify-center rounded-md bg-[#301762] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientFilter;
