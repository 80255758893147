import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loggedInUserDetails, userLogin } from "./userAPI";
import toast from "react-hot-toast";

const initialState = {
  user: {},
  loggedIn: false,
  status: "idle",
};
//==================user loggin ===================
export const userLogginAsync = createAsyncThunk(
  "user/loggin",
  async (loginInfo) => {
    const response = await userLogin(loginInfo);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message)
      return false
    }
  }
);
export const loggedInUserDetailsAsync = createAsyncThunk(
  "user/loggedInUser",
  async (id) => {
    const response = await loggedInUserDetails(id);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message)
      return false
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(userLogginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userLogginAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.user = action.payload;
          state.user != null ? (state.loggedIn = true) : (state.loggedIn = false);
          state.user != null && (state.token = action.payload.token);
        }
      })
      .addCase(loggedInUserDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loggedInUserDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        if (action.payload) {
          state.user = action.payload
        }
      })
  },
});

export const selectLoggedInUser = (state) => state.user.user

export default userSlice.reducer;
