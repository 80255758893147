import React from "react";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DateFormat, Prifix } from "../../utils/helper";


const Table = ({ data }) => {
  const appointment = data;
  // const [patientDetails, setPatientDetails] = useState("");
  // const [prevFileNumber, setPrevFileNumber] = useState(data?.file_number);
  // const [open,setOpen] = useState(false)

  // Delete Function
  // const handleDelete = async () => {
  //   try {
  //     const res = await axiosClient().delete(
  //       `${webURLs.DELETE_APPOINTMENT}?id=${patientDetails[0]._id}`
  //     );
  //     if (res.status == 200) {
  //       toast.success("Appointment is canceled");
  //       dispatch(DeleteAppointments(res.data.data));
  //       setOpen(false);
  //     }
  //   } catch (error) {
  //     toast.error(`something went wrong`);
  //   }
  // };

  //datasave in database appointments
  // const handleSubmit = async (e) => {
  //   // const isAppointmentDone =true
  //   e.preventDefault();

  //   try {
  //     //for creation of patient if it does not exist in patientsdetails

  //     if (!prevFileNumber) {
  //       dispatch(
  //         addNewPatientAsync({
  //           patient_name: patientDetails.patient_name,
  //           file_number: patientDetails.file_number,
  //           mobile: patientDetails.mobile,
  //           email: "",
  //           address: "",
  //           age: patientDetails.age,
  //           gender: patientDetails.gender,
  //           doctor: patientDetails.doctor,
  //           disease: disease,
  //           treatment: patientDetails.treatment,
  //           Appointments: patientDetails._id,
  //           user: "660e684ecc1f62dadcb1818e",
  //         })
  //       );

  //       dispatch(
  //         updateAppointmentAsync({
  //           _id: patientDetails._id,
  //           update: {
  //             isAppointmentDone: true,
  //             file_number: patientDetails.file_number,
  //           },
  //         })
  //       );
  //     }

  //   } catch (error) {
  //     toast.error(`something went wrong ${error.message}`);
  //   }
  // };

  // Function to open modal and set patient details
  const openModal = (patient) => {
    // setPatientDetails(patient);
    // setOpen(true);
  };

  // Function to open modal for status done and set patient file number
  // const openModalStatus = (patient) => {
  //   setPatientDetails(patient);
  //   setPrevFileNumber(patient.file_number);
  //   setPatientDetails((prevDetails) => ({
  //     ...prevDetails,
  //     isAppointmentDone: true, // Update isAppointmentDone to true
  //   }));
  //   setOpenStatus(true);
  // };


  return (
    <>
      <div className="relative rounded-lg border-2 order-solid overflow-y-scroll h-[80vh]">
        {data.length ? (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-gray-800 sticky top-0">
              <tr className="text-center border-b-2">
                <th  className="px-2 py-2 font-medium">
                  S.No.
                </th>
                <th  className="px-2 py-2 font-medium">
                  Name
                </th>
                <th  className="px-2 py-2 font-medium">
                  Mobile No.
                </th>
                <th  className="px-2 py-2 font-medium">
                  File No.
                </th>
                <th  className="px-2 py-2 font-medium">
                  Reg Date
                </th>
                <th  className="px-2 py-2 font-medium">
                  Action
                </th>
                <th  className="px-2 py-2 font-medium">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                appointment.map((patient, index) => (
                  <tr
                    className="odd:bg-white even:bg-blue-50 text-gray-700 text-xs border-b-2"
                    key={index}
                  >
                    <td
                      scope="row"
                      className="px-2 py-2 font-medium"
                    >
                      {index + 1}
                    </td>

                    <td className={`px-2 py-2`}>
                      <Link
                        className="text-gray-700 hover:text-blue-700"
                        to={`/patient/patientdetails/${patient?._id}`}
                      >
                        <h3 className={`pl-4 py-2 text-center ${patient?.importance === "low" && "border-l-8 border-red-500"} ${patient?.importance === "medium" && "border-l-8 border-green-500"} ${patient?.importance === "high" && "border-l-8 border-yellow-500"}`}>{Prifix(patient)} {patient?.patient_name}&nbsp;{patient?.surname} </h3>
                      </Link>
                    </td>
                    <td className="px-2 py-2">{patient?.mobile}</td>
                    <td className="px-2 py-2 text-center">{patient?.file_number}</td>
                    <td className="px-2 py-2">{DateFormat(patient?.createdAt)}</td>
                    <td className="px-2 py-2 text-red-600 border-0 text-lg cursor-pointer flex justify-center">
                      <span
                        className="hover:text-blue-600 text-center duration-150 hover:scale-125"
                        onClick={() => openModal(patient)}
                      >
                        <FaTrash />
                      </span>
                    </td>
                    <td className="px-2 py-2 text-sm text-green-600 text-center border-0 cursor-pointer hover:text-blue-600">
                      Active
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <img src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527139.jpg?w=740&t=st=1715321245~exp=1715321845~hmac=ac03b291ef85c469e2935df5bdc8e42a6af065a8259681c1f1033ecd4776deba" alt="noData" />

            <div className="flex flex-col items-center justify-center">
              <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 mt-1">
                Data Not Found!
              </p>
              <p className="md:text-lg lg:text-xl text-gray-600 mt-8">
                Sorry, No Such Data Found , Please clear filter and try Again!.
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Return Home</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
