import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DateFormat, paginationHelper, Prifix } from "../../utils/helper";
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io'
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedInUser } from "../../redux/user/userSlice";
import { deletePatientDetailsAsync } from "../../redux/patientAppointments/patientSlice";

const Table = ({ data, formDate }) => {
  const patients = data;
  const location = useLocation()
  const dispatch = useDispatch()
  const User = useSelector(selectLoggedInUser)

  // PAGINATION STATES AND HELPERS
  const itemPerPage = 10
  const [page, setPage] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)
  // const [selectedPage, setSelectedPage] = useState(location?.state?.formDate?.selectedPage || 1)
  const arrLength = paginationHelper(patients?.length, itemPerPage)
  // PAGINATION STATES AND HELPERS

  useEffect(() => {
    if (formDate) {
      formDate.selectedPage = selectedPage;
    }
  }, [selectedPage, formDate]);


  // for delete patient function
  const deletePatient = useCallback(async (id) => {
    const isDel = window.confirm("Are You Sure Want To Delete ?")
    if (isDel) {
      dispatch(deletePatientDetailsAsync(id))
    }
  }, [dispatch])

  return (
    <>
      <div className="rounded border-2">
        {data.length ? (<>
          <table className="text-sm w-full text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-gray-800">
              <tr className="text-center border-b-2 bg-slate-300">
                <th className="px-2 py-2 font-medium">
                  S.No.
                </th>
                <th className="px-2 py-2 font-medium">
                  Name
                </th>
                <th className="px-2 py-2 font-medium">
                  Mobile No
                </th>
                <th className="px-2 py-2 font-medium">
                  File No
                </th>
                <th className="px-2 py-2 font-medium">
                  Reg Date
                </th>
                <th className="px-2 py-2 font-medium">
                  Status
                </th>
                {User.role && <th className="px-2 py-2 font-medium">
                  others
                </th>}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                patients.slice((page * itemPerPage) - itemPerPage, page * itemPerPage).map((patient, index) => (
                  <tr
                    className="odd:bg-white even:bg-blue-50 text-gray-700 text-sm border-b-2 text-center"
                    key={index}
                  >
                    <td
                      className="px-2 py-2 font-medium"
                    >
                      {(page * itemPerPage + index + 1 - 10)}
                    </td>

                    <td className={`px-2 py-2`}>
                      <div className={`pl-4 py-2 text-center flex justify-around ${patient?.importance === "low" && "border-l-8 border-red-500"} ${patient?.importance === "medium" && "border-l-8 border-green-500"} ${patient?.importance === "high" && "border-l-8 border-yellow-500"}`}>
                        <Link
                          className="text-gray-700 hover:text-blue-700"
                          to={`/patient/patientdetails/${patient?._id}`}
                          state={{ formDate }}
                        >
                          <h3 className="text-center">{Prifix(patient)} {patient?.patient_name}&nbsp;{patient?.surname} </h3>
                        </Link>
                      </div>
                    </td>
                    <td className="px-2 py-2">{patient?.mobile}</td>
                    <td className="px-2 py-2 text-center">{patient?.file_number}</td>
                    <td className="px-2 py-2">{DateFormat(patient?.createdAt)}</td>
                    <td className="px-2 py-2 text-green-600 text-center border-0 cursor-pointer hover:text-blue-600">
                      {patient?.isActive ? <span className="text-[#28ac28] px-4 py-1 rounded-full bg-[#caffca]">Active</span> : <span className="text-[#f91e1e] px-4 py-1 rounded-full bg-[#ffb9b9]">Deactive</span>}
                    </td>
                    {User.role && <td className="py-2 px-2">
                      <button onClick={() => { deletePatient(patient?._id) }} className="text-red-500 hover:text-red-400 text-xl" title="Delete Patient"><FaTrash /></button>
                    </td>}
                  </tr>
                ))}
            </tbody>
          </table>
        </>) : (
          <div className="w-full flex flex-col items-center justify-center">
            <img src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527139.jpg?w=740&t=st=1715321245~exp=1715321845~hmac=ac03b291ef85c469e2935df5bdc8e42a6af065a8259681c1f1033ecd4776deba" height="310px" width="310px" alt="noData" />

            <div className="flex flex-col items-center justify-center p-1 mb-12">
              <p className="text-3xl md:text-3xl lg:text-3xl text-gray-800 mt-1">
                Data Not Found!
              </p>
              <p className="md:text-lg lg:text-xl text-gray-600 pb-4 pt-2">
                Sorry, No Such Data Found
              </p>
              <Link className="p-2 border border-blue-900 rounded text-blue-900" to="/">Return To Home</Link>
            </div>
          </div>
        )}
        <div className="|| PAGINATION || flex flex-row items-center justify-center my-2 gap-5 bg-[#eff6ff]">

          <button className="flex flex-row items-center justify-center" onClick={() => {
            if (page > 1) {
              setPage(page - 1)
              setSelectedPage(page - 1)
            } else {
              setPage(1)
            }
          }
          }>
            <IoMdArrowBack className=" cursor-pointer font-extrabold hover:scale-150 duration-300" />
            <span>Previous</span>
          </button>


          {/* <span>{selectedPage}</span> */}
          {console.log('SELECTED PAGE ', location?.state?.formDate?.selectedPage)}
          <input type="number" onChange={(e) => {
            setSelectedPage(e.target.value)
            setPage(e.target.value)
          }}
            // defaultValue={location?.state?.formDate?.selectedPage}
            value={selectedPage}
            min={1}
            max={arrLength.length}
            className="w-10 rounded px-2 my-2 font-semibold focus:outline-none focus:bg-gray-200"
          />


          <button className="flex flex-row items-center justify-center" onClick={() => {
            if (page < arrLength.length) {
              setPage(page + 1)
              setSelectedPage(page + 1)
            } else {
              setPage(1)
              setSelectedPage(1)
            }
          }
          }>
            <span>Next</span>
            <IoMdArrowForward className=" cursor-pointer font-extrabold hover:scale-150 duration-300 " />
          </button>

        </div>
      </div>
    </>
  );
};

export default Table;
