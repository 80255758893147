// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-date {
    font-size: 0.8rem;
    font-weight: 500;
}

.slot-date-active {
    color: black;
    background: white;
    padding: 3px 8px;
    margin: auto 0px;
    border-radius: 7px;
    outline: none;
    /* border: 1px black; */
    border-style: inset;
}

.slot-date-container {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.281);
    border-bottom: 1px solid steelblue;
    background-color: white;
}

#slot-container {
    border-radius: 16px;
    overflow: hidden;
    /* box-shadow: 0px 0px 15px #00000063; */
    border: 0.5px solid gray;
    height: 19.3em;
}

.booked {
    font-size: 0.9rem;
    --tw-text-opacity: 1;
    color: gray;
    margin: 0.5rem 0rem;
    font-weight: 500;
    cursor: pointer;
}

.notBooked {
    color: #301762;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0.5rem 0rem;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/slotCalendar/DateSlotTab.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,4CAA4C;IAC5C,kCAAkC;IAClC,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,wCAAwC;IACxC,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".slot-date {\n    font-size: 0.8rem;\n    font-weight: 500;\n}\n\n.slot-date-active {\n    color: black;\n    background: white;\n    padding: 3px 8px;\n    margin: auto 0px;\n    border-radius: 7px;\n    outline: none;\n    /* border: 1px black; */\n    border-style: inset;\n}\n\n.slot-date-container {\n    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.281);\n    border-bottom: 1px solid steelblue;\n    background-color: white;\n}\n\n#slot-container {\n    border-radius: 16px;\n    overflow: hidden;\n    /* box-shadow: 0px 0px 15px #00000063; */\n    border: 0.5px solid gray;\n    height: 19.3em;\n}\n\n.booked {\n    font-size: 0.9rem;\n    --tw-text-opacity: 1;\n    color: gray;\n    margin: 0.5rem 0rem;\n    font-weight: 500;\n    cursor: pointer;\n}\n\n.notBooked {\n    color: #301762;\n    font-size: 0.875rem;\n    font-weight: 500;\n    margin: 0.5rem 0rem;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
