import React, { Fragment, useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import {
  HiOutlineBell,
  HiOutlineSearch,
  HiOutlineChatAlt,
} from "react-icons/hi";
import { ImCross } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import socket from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientAppointment,
  updateAppointmentFieldsAsync,
} from "../../redux/patientAppointments/patientSlice";
import moment from "moment";
import { HiBellAlert, HiOutlineBellAlert } from "react-icons/hi2";
import { GoAlertFill } from "react-icons/go";
import {
  addMissednotification,
  setUpcommingNotification,
  addUpcommingnotification,
  removeMissedNotification,
  selectMissedAppointmentNotification,
  selectUpcommingAppointmentNotification,
  removeUpcommingNotification,
  setMissedNotification,
} from "../../redux/notifcations/notificationSlice";
import { selectLoggedInUser } from "../../redux/user/userSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allAppointments = useSelector(selectPatientAppointment);
  const upCommingAppointments = useSelector(selectUpcommingAppointmentNotification);
  const missedAppointments = useSelector(selectMissedAppointmentNotification);
  const user = useSelector(selectLoggedInUser);

  useEffect(() => {
    socket.on("missedNotification", (notification) => {
      toast.custom((t) => (
        <div
          className={`${t.visible ? "animate-enter" : "animate-leave"} 
             "max-w-md w-[40vw] bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-opacity-5"`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <GoAlertFill className="h-10 w-10 " color="red" />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">
                  {notification.message.includes("missed") ? (
                    <span>Appointment Missed!</span>
                  ) : (
                    <span>New Notification</span>
                  )}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {notification.message}
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ));
      dispatch(addMissednotification(notification));
      dispatch(updateAppointmentFieldsAsync({ status: true, data: notification.appointmentDetails }));
    });

    socket.on("appointmentNotification", (notification) => {
      toast.custom((t) => (
        <div
          className={`${t.visible ? "animate-enter" : "animate-leave"} ${notification.message.includes("missed")
            ? "max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
            : "max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
            }`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                {notification.message.includes("missed") ? (
                  <GoAlertFill className="h-10 w-10 " color="red" />
                ) : (
                  <HiOutlineBellAlert className="h-10 w-10 " />
                )}
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">
                  {notification.message.includes("missed") ? (
                    <span>Appointment Missed!</span>
                  ) : (
                    <span>New Notification</span>
                  )}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {notification.message}
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ));
      dispatch(addUpcommingnotification(notification));
    });

    return () => {
      socket.off("appointmentNotification");
    };
  }, [dispatch]);

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    navigate("/login");
    toast.success("Logout Successfully!");
  };

  useEffect(() => {
    const prevNotification = allAppointments.filter(
      (appointment) =>
        appointment.notified === true &&
        appointment.seen === false &&
        appointment.missed === false &&
        appointment.isAppointmentDone === false
    );
    const prevMissedNotification = allAppointments.filter(
      (appointment) =>
        appointment.notified === true &&
        appointment.seen === false &&
        appointment.missed === true &&
        appointment.isAppointmentDone === false
    );
    const notificationFormat = prevNotification.map((item) => {
      let messageFormat = {
        message: `Appointment with ${item.patient_name.patient_name
          } ${item.patient_name.surname} at ${moment(item.date).format(
            "DD MMMM"
          )} ${item.time}`,
        appointmentId: `${item._id}`,
        patientId: `${item.patient_name._id}`,
      };
      return messageFormat;
    });
    const missedNotificationFormat = prevMissedNotification.map((item) => {
      let messageFormat = {
        message: `Appointment Missed with ${item.patient_name.patient_name
          } ${item.patient_name.surname} was scheduled at ${moment(
            item.date
          ).format("DD MMMM")} ${item.time}`,
        appointmentId: `${item._id}`,
        patientId: `${item.patient_name._id}`,
      };
      return messageFormat;
    });
    dispatch(setUpcommingNotification(notificationFormat));
    dispatch(setMissedNotification(missedNotificationFormat));
  }, [allAppointments, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      let div = document.getElementById("notification");
      let btn = div.parentElement
      btn.click()
    }, 4000)
  }, [])

  return (
    <>
      <header className="h-20 flex items-center justify-between px-4 md:px-8 bg-blue border-gray-200">
        <div className="relative">
          <HiOutlineSearch
            fontSize={20}
            className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2"
          />
          <input
            type="text"
            placeholder="Search..."
            className="text-sm focus:outline-none active:outline-none border border-gray-300 max-w-sm-[10rem] max-w-lg-[24rem] h-10 pl-11 pr-4 rounded-2xl"
          />
        </div>
        <div className="flex items-center gap-2 mr-2">
          <Popover className="relative" >
            {({ open }) => (
              <>
                <PopoverButton
                  className={classNames(
                    open && "bg-gray-100 focus:text-[#9F78FF] duration-0",
                    "group inline-flex items-center text-white rounded-sm p-1.5 hover:text-opacity-100 focus:outline-none"
                  )}
                >
                  <HiOutlineChatAlt fontSize={24} className="text-[inherit]" />
                </PopoverButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <PopoverPanel className="absolute right-0 z-10 mt-2.5 transform w-80">
                    <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                      <strong className="text-[#301762] font-medium">
                        Messages
                      </strong>
                      {/* //=============message panel=============== */}
                      <div className="mt-2 py-1 text-sm">
                        This is message panel
                      </div>
                    </div>
                  </PopoverPanel>
                </Transition>
              </>
            )}
          </Popover>
          <Popover className="relative">
            {({ open }) => (
              <>
                <PopoverButton
                  className={classNames(
                    open && "bg-gray-100 focus:text-[#9F78FF] duration-0",
                    "group inline-flex items-center text-white rounded-sm p-1.5 hover:text-opacity-100 focus:outline-none"
                  )}
                >
                  <div id="notification" className="h-4 flex items-center text-sm justify-center w-4 bg-black text-white p-3 absolute bottom-[19px] left-[20px] rounded-full">
                    {upCommingAppointments?.length + missedAppointments?.length}
                  </div>
                  <HiOutlineBell fontSize={24} className="text-[inherit]" />
                </PopoverButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <PopoverPanel className="absolute right-0 mt-4 rounded-bl-xl rounded-br-xl transform w-[45vw] overflow-y-scroll z-50 h-[70vh]">
                    <div className="bg-white border-b-4 border-[#301762] h-full backdrop-blur-lg rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-6 py-2.5">
                      <>
                        <div className="text-sm flex rounded-md px-3 py-0 items-center gap-2 font-medium">
                          <HiBellAlert color="dodgerblue" />
                          <p>Notifications</p>
                          <div className="w-2 h-2 flex items-center bg-[#301762] text-white p-3 text-sm rounded-full justify-center">
                            {upCommingAppointments.length}
                          </div>
                        </div>
                        <div className="px-2 my-2 rounded-lg h-[25vh] overflow-y-scroll text-sm">
                          <ul>
                            {upCommingAppointments.map((note, index) => (
                              <li
                                className="p-3 my-3 flex hover:bg-slate-300 items-center justify-between gap-x-4 rounded-sm px-3 py-2"
                                key={index}
                              >
                                <Link
                                  to={`patient/patientdetails/${note.patientId}`}
                                >
                                  <p className="text-justify tracking-tighter px-2">
                                    {note.message}
                                  </p>
                                </Link>
                                <div
                                  onClick={() => { dispatch(removeUpcommingNotification(note?.appointmentDetails?._id)) }}
                                >
                                  <ImCross
                                    size={15}
                                    className="cursor-pointer hover:scale-90 duration-150 hover:shadow-xl"
                                    color="red"
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                      <>
                        <div className="text-sm flex rounded-lg px-3 py-1 items-center gap-2 font-normal">
                          <HiBellAlert color="dodgerblue" />
                          <p className="text-red-400">Missed Appointments</p>
                          <div className="w-4 h-4 flex items-center bg-[#301762] text-white p-3 text-sm rounded-full justify-center">
                            {missedAppointments.length}
                          </div>
                        </div>
                        <div className="mt-1  h-[25vh] py-1 text-sm">
                          <ul className="h-full overflow-y-scroll">
                            {missedAppointments.map((note, index) => (
                              <li
                                className="p-3 my-3 flex items-center hover:bg-slate-300 justify-between gap-x-4 rounded-lg px-3 py-2"
                                key={index}
                              >
                                <Link
                                  to={`patient/patientdetails/${note?.patientId}`}
                                >
                                  <p className="text-justify tracking-tighter px-2">
                                    {note.message}
                                  </p>
                                </Link>
                                <div
                                  onClick={() => {
                                    dispatch(
                                      removeMissedNotification(
                                        note?.appointmentDetails?._id
                                      )
                                    );
                                  }}
                                >
                                  <ImCross
                                    size={15}
                                    className="cursor-pointer hover:scale-90 duration-150 hover:shadow-xl"
                                    color="red"
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    </div>
                  </PopoverPanel>
                </Transition>
              </>
            )}
          </Popover>
          <Menu as="div" className="relative">
            <div>
              <MenuButton className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                <span className="sr-only">Open user menu</span>
                <div
                  className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                  style={{
                    backgroundImage:
                      `url("${user && user.avatar}")`,
                  }}
                >
                  <span className="sr-only">Marc Backes</span>
                </div>
              </MenuButton>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  {() => (
                    <div className="text-center p-1">
                      {user && user.name}
                    </div>
                  )}
                </MenuItem>
                <hr />
                <MenuItem>
                  {({ active }) => (
                    <div
                      onClick={() => navigate(`/profile/${user._id}`)}
                      className={classNames(
                        active && "bg-[#301762] text-white",
                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      )}
                    >
                      Your Profile
                    </div>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <div
                      onClick={() => navigate("/setting")}
                      className={classNames(
                        active && "bg-[#301762] text-white",
                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      )}
                    >
                      Settings
                    </div>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <div
                      onClick={handleLogout}
                      className={classNames(
                        active && "bg-[#301762] text-white",
                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      )}
                    >
                      Sign out
                    </div>
                  )}
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </header>
    </>
  );
};

export default Header;
