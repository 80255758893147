import { useState, useEffect } from "react";
import { IoMdPhonePortrait } from "react-icons/io";
import { IoCalendar } from "react-icons/io5";
import AddNewPatientAppointment from "../forms/AddNewPatientAppointment";
import ModalComponent from "../modal/ModalComponent";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAllPatient } from "../../redux/patientAppointments/patientSlice";
import { Prifix } from "../../utils/helper";

const RecentPatient = () => {
  const patient = useSelector(selectAllPatient);
  const [recentPatients, setRecenetPatients] = useState([]);
  const [searchPatients, setSearchPatients] = useState([])
  const [open, setOpen] = useState(false);
  const [bookingFor, setBookingFor] = useState();

  function search(e) {
    const searchValue = e.target.value.toLowerCase();
    let filteredPatient = recentPatients.filter(
      (patient) =>
        (patient.patient_name &&
          patient.patient_name.toLowerCase().includes(searchValue)) ||
        (patient.patient_name &&
          patient.surname.toLowerCase().includes(searchValue)) ||
        (patient.mobile && patient.mobile.includes(searchValue)) ||
        (patient.file_number &&
          patient.file_number.toLowerCase().includes(searchValue))
    );
    setSearchPatients(filteredPatient);
  }

  useEffect(() => {
    setRecenetPatients(patient?.slice().reverse())
  }, [patient])

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="flex flex-row items-baseline justify-between p-3 border border-b-0 rounded">
          <h1 className="text-sm font-mono text-white px-2 bg-blue mb-2">
            Recent Added Patients
          </h1>
          <div className="bg-white flex items-center px-2 ">
            <input
              type="text"
              onChange={search}
              placeholder="Search patient name"
              className="outline-none p-2 text-sm focus:bg-slate-200 bg-slate-100 rounded-sm"
            />
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-scroll h-[800px]">
          <table className="w-full table-auto border-collapse text-sm text-gray-500 ">
            <tbody className="divide-y  h-[20%] overflow-y-scroll  divide-gray-100 border-t border-gray-100">
              {(searchPatients && searchPatients.length ? searchPatients : recentPatients)?.map((patient, index) => (
                <tr key={index} className="hover:bg-gray-50 ">
                  <th className="flex gap-3 font-normal text-gray-900 text-justify">
                    <div className={`px-6 py-2 text-sm m-1  ${patient?.importance === "low" && "border-l-8 border-red-500"} ${patient?.importance === "medium" && "border-l-8 border-green-500"} ${patient?.importance === "high" && "border-l-8 border-yellow-500"}`}>
                      <Link to={`/patient/patientdetails/${patient?._id}`}>
                        <div className="font-mono text-gray-600 hover:text-blue-700">
                          {`${Prifix(patient)} ${patient?.patient_name} ${patient?.surname}`}
                        </div>
                      </Link>
                      <div className="text-gray-400 flex flex-row items-center">
                        <IoMdPhonePortrait />
                        {patient?.mobile}
                      </div>
                    </div>
                  </th>

                  <td className="px-6 py-4">
                    <div className="flex justify-end gap-4">
                      <i
                        style={{ color: "black" }}
                        className="text-lg cursor-pointer hover:scale-150 duration-300"
                        onClick={() => {
                          setOpen(true);
                          setBookingFor(patient._id);
                        }}
                      >
                        <IoCalendar />
                      </i>
                    </div>
                  </td>
                </tr>
              ))}
              <ModalComponent
                open={open}
                content={
                  <AddNewPatientAppointment
                    setOpen={setOpen}
                    patientId={bookingFor}
                  />
                }
                setOpen={setOpen}
              />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RecentPatient;
