import { configureStore } from "@reduxjs/toolkit";
import patientAppointmentReducer from "./patientAppointments/patientSlice";
import bookingSlotReducer from "../redux/bookingSlot/bookingSlotSlice";
import userReducer from "../redux/user/userSlice";
import medicineReducer from "../redux/medicines/medicineSlice";
import prescriptionReducer from "./prescription/prescriptionSlice";
import precautionReducer from "../redux/precautions/precautionSlice";
import medicineGroupReducer from "./medicineGroup/medicineGroupSlice";
import examinationReducer from "./examination/examinationSlice";
import treatmentReducer from "./treatment/treatmentSlice";
import procedureReducer from "./procedure/procedureSlice";
import feeReducer from "./fee/feeSlice";
import labReducer from "./lab/labSlice";
import notifications from "./notifcations/notificationSlice";

export const store = configureStore({
  reducer: {
    patient: patientAppointmentReducer,
    slot: bookingSlotReducer,
    user: userReducer,
    prescription: prescriptionReducer,
    medicine: medicineReducer,
    medicineGroup: medicineGroupReducer,
    diseasePrecations: precautionReducer,
    examination: examinationReducer,
    treatment: treatmentReducer,
    procedure: procedureReducer,
    fee: feeReducer,
    notification: notifications,
    lab: labReducer
  },
});

export default store;
