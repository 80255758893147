import { IoPartlySunnySharp } from "react-icons/io5";
import { FaCloudMoon } from "react-icons/fa";
import { FaPrescription } from "react-icons/fa";
import { Prifix, pathOrigin } from '../../utils/helper'
import { useSelector } from "react-redux";
import { selectExamination } from "../../redux/examination/examinationSlice";
import { selectLoggedInUser } from "../../redux/user/userSlice";

const GenerateMedicineInvoice = ({ patientDetails, otherAdvice, medicines, extraMedicine, cheifComplaint, note, priscriptionData, advice }) => {
  let date = new Date()
  const Examinations = useSelector(selectExamination);
  const userDetails = useSelector(selectLoggedInUser);
  return (
    <div id='pdf' className="container mx-auto bg-[#F8E6CE] p-6 shadow-lg max-w-4xl">
      <div>
        <img src={`${pathOrigin()}/asset/images/generatemedicine.jpg`} alt="gen" />
      </div>
      <hr />
      <div className="ml-3 mt-1">
        <FaPrescription size={"20px"} />
      </div>
      <div className="patient-info mt-2 pb-2 flex justify-between">
        <div className="text-sm text-slate-800">
          <p>
            <strong>Patient Contact No:</strong> +91-{patientDetails?.mobile}
          </p>
          <p>
            <strong>Patient Name :</strong>&nbsp;{Prifix(patientDetails)}&nbsp;{patientDetails?.patient_name}&nbsp;{patientDetails?.surname}
          </p>
          <p>
            <strong>Patient File Number:</strong> {patientDetails?.file_number}
          </p>
          <p>
            <strong>Any Medical History:</strong> {patientDetails?.disease} {patientDetails?.other_disease}
          </p>
        </div>
        <div className='text-slate-800 text-sm'>
          <p> <strong>Dr Name:</strong>&nbsp;{userDetails?.name}</p>
          <p><strong>Date :</strong>&nbsp;{date.toDateString()}</p>
          <p><strong>Phone No :</strong>&nbsp;+91-{userDetails?.mobile}</p>
        </div>
      </div>
      <hr className="border-[#291d1d]" />
      <div className="mt-3 text-slate-800">
        <p className="text-sm"><strong>Cheif Complaint :</strong>{cheifComplaint ? cheifComplaint : priscriptionData?.cheif_complaint}</p>
      </div>
      <div className="mt-2 text-slate-800">
        <p className="text-sm"><strong>Clinical Examination: :</strong>{Examinations && Examinations.map((item, idx) => (
          <span key={idx}><span>Tooth Number : {item?.tooth_no?.join(",")}</span>&nbsp;<span>Disease : {item?.disease_name}{","}</span></span>
        ))}</p>
      </div>
      <div className="mt-2 text-slate-800">
        <p className="text-sm"><strong>Clinical Advice: </strong>{note ? note : priscriptionData?.note == "undefined" ? "" : priscriptionData?.note}</p>
      </div>
      <table className="w-full text-md text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4 ">
        <thead className='bg-[#b03232] text-sm'>
          <tr className='text-center text-white'>
            <th className="px-4 py-2">
              Medicine Name
            </th>
            <th className="px-4 py-2">
              Contents
            </th>
            <th className="px-4 py-2">
              Quantity
            </th>
            <th className="px-4 py-2">
              Days
            </th>
            <th className="px-4 py-2">
              Regime
            </th>
          </tr>
        </thead>
        <tbody>
          {medicines.length > 0 ? medicines.map((item, idx) => (
            <tr className={`${item._id === "664c3c1c37d09bc5124d4a39" ? "text-center text-red-500 text-sm border-black font-semibold" : "text-slate-800 text-center text-sm border-black"}`} key={idx}>
              <td className="px-4 py-2">
                {item.medicine_name}
              </td>
              <td className="px-4 py-2">
                {item.contains}
              </td>
              <td className="px-4 py-2">
                {item.quantity}
              </td>
              <td className="px-4 py-2">
                {item.days}&nbsp;days
              </td>
              <td className="text-center px-4 py-2">
                <div className="flex justify-center">
                  <IoPartlySunnySharp className="text-center" color="orange" size={"30px"} />
                  {item.times}
                  <FaCloudMoon color="darkblue" size={"30px"} />
                </div>
              </td>
            </tr>
          )) : null}
        </tbody>
      </table>
      <hr className="border-[#291d1d]" />
      <div className="pl-5 mt-5 text-slate-800">
        <ol className="capitalize list-decimal">
          {extraMedicine && extraMedicine?.map((item, idx) => (
            <li className={`${item._id === "664f5014eabbbfe9a68bb4ba" ? "text-blue-800 text-sm p-1 font-semibold" : "text-slate-800 text-sm p-1"} `} key={idx}><p>{item?.medicine_name} ({item?.contains}) <br />({item?.times}) <br />Number of Tab - ({item?.quantity})</p></li>
          ))}
        </ol>
      </div>

      <div className="pl-2 mt-5 text-slate-800">
        <p className="text-sm"><strong>Advice: </strong></p>
        <ul className="capitalize list-none">
          {advice?.length > 0 && advice.split("_")?.map((item, idx) => (
            <li className="text-slate-800 text-sm p-1" key={idx}>{item}</li>
          ))}
          <li className="text-slate-800 text-sm p-1">{otherAdvice}</li>
        </ul>
      </div>
    </div>
  )
}

export default GenerateMedicineInvoice
