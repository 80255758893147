import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW MEDICINES API =======================
export function addMedicineGroup(medicineDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      webURLs.ADD_MEDICINE_GROUP,
      medicineDetails
    );
    resolve(response.data);
  });
}
//=================== GET ALL MEDICINES API =======================
export function getMedicinesGroup() {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(webURLs.GET_MEDICINE_GROUP);
    resolve(response.data)
  });
}

//========================= DELETE MEDICINES API ==================

export function deleteMedicineGroup(medicineId) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().delete(
      `${webURLs.DELETE_MEDICINE_GROUP}${medicineId}`
    );
    resolve(response.data);
  });
}
