import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== addLab API =======================
export function addLab(data) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().post(webURLs.ADD_LAB,data);
        resolve(response.data);
    });
}

//===================  getLab API =======================
export function getLab() {
    return new Promise(async (resolve) => {
        const response = await axiosClient().get(webURLs.GET_LABS);
        resolve(response.data)
    });
}

//=========================  deleteLab API ==================
export function deleteLab(labId) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().put(`${webURLs.DELETE_LAB}${labId}`);
        resolve(response.data);
    });
}

//=================== add Lab Data API =======================
export async function addLabData(patientId, data) {
    try {
        const response = await axiosClient().post(`${webURLs.ADD_LAB_DATA}${patientId}`, data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

//===================  get Lab Data API =======================
export function getLabData() {
    return new Promise(async (resolve) => {
        const response = await axiosClient().get(webURLs.GET_LAB_DATA);
        resolve(response.data)
    });
}

//===================  get Lab data by Patient Id API =======================
export function getLabDataByPatientId(patientId) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().get(`${webURLs.GET_LAB_DATA_BY_PATIENT_ID}${patientId}`);
        resolve(response.data)
    });
}


//=================== delete Lab Data API =======================
export async function deleteLabData(labDataId) {
    try {
        const response = await axiosClient().delete(`${webURLs.DELETE_LAB_DATA}${labDataId}`);
        return response.data
    } catch (error) {
        console.log(error);
    }

}


//===================  Lab work done API =======================
export async function labWorkDone(labDataId) {
    try {
        const response = await axiosClient().put(`${webURLs.LAB_WORK_DONE}${labDataId}`);
        return response.data
    } catch (error) {
        console.log(error);
    }

}

