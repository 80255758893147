// Import component
import * as COMPONENT_ROUTE from "../../routes/ComponentRoute";

const Patientform = () => {
  

  return (
    <div className="flex flex-col items-center overflow-hidden border-2 rounded-lg">
      <div className="items-baseline bg-gray-300 w-full p-3 border-b-[1px]">
        <h1 className="text-sm text-center font-medium text-[#301762]">Patient Form</h1>
      </div>
      <COMPONENT_ROUTE.ADD_PATIENT />
    </div>
  );
};

export default Patientform;
