import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchEveningSlots, fetchMorningSlots } from "./bookingSlotAPI";

const initialState = {
  morningSlots: [],
  eveningSlots: [],
  status: "idle",
};

export const fetchMorningSlotsAsync = createAsyncThunk(
  "slot/fetchMorningSlot",
  async (bookingDay) => {
    const response = await fetchMorningSlots(bookingDay);
    // console.log(response);
    return response;
  }
);
export const fetchEveningSlotsAsync = createAsyncThunk(
  "slot/fetchEveningSlot",
  async (bookingDay) => {
    const response = await fetchEveningSlots(bookingDay);
    // console.log(response);
    return response;
  }
);

export const bookingSlotSlice = createSlice({
  name: "bookingSlot",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMorningSlotsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMorningSlotsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.morningSlots = action.payload;
      })
      .addCase(fetchEveningSlotsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEveningSlotsAsync.fulfilled, (state, action) => {
        state.status = "idle";
       state.eveningSlots = action.payload
      })
  }
});

export const selectMorningSlots = (state) => state.slot.morningSlots;
export const selectEveningSlots = (state) => state.slot.eveningSlots;
export default bookingSlotSlice.reducer;
