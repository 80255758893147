import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllExamination, deleteExamination, addExamination, editExamination } from "./examinationApi";
import toast from "react-hot-toast";

const initialState = {
    examinations: [],
    status: "idle",
};
//=============GET EXAMINATION THUNK================
export const getAllExaminationAsync = createAsyncThunk(
    "examination/get_All_examination)",
    async (patientId) => {
        const response = await getAllExamination(patientId);
        if (response.status) {
            toast.dismiss()
            return response.data;
        } else {
            toast.dismiss()
            // toast.error(response.message);
            return false;
        }
    }
);

//============= ADD EXAMINATION THUNK =============
export const addExaminationAsync = createAsyncThunk(
    "examination/add_examination",
    async ({ id, Obj }) => {
        const response = await addExamination(id, Obj);
        if (response.status) {
            toast.dismiss()
            return response.data;
        } else {
            toast.dismiss()
            toast.error(response.message);
            return false;
        }
    }
);

//============= ADD EXAMINATION THUNK =============
export const editExaminationAsync = createAsyncThunk(
    "examination/edit_examination",
    async ({ id, examinationId, Obj }) => {
        const response = await editExamination(id, examinationId, Obj);
        if (response.status) {
            toast.dismiss()
            return response.data;
        } else {
            toast.dismiss()
            toast.error(response.message);
            return false;
        }
    }
);

//===============DELETE MEDICINE API ====================
export const deleteExaminationAsync = createAsyncThunk(
    "examination/delete_examination",
    async ({ id, examinationId }) => {
        let isDel = window.confirm("Are You Sure!");
        try {
            if (isDel) {
                const response = await deleteExamination(id, examinationId);
                if (response.status) {
                    toast.dismiss()
                    return response.data;
                } else {
                    toast.dismiss()
                    toast.error(response.message);
                    return false;
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const examinationSlice = createSlice({
    name: "examination",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllExaminationAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAllExaminationAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.examinations = action.payload;
                } else {
                    state.examinations = []
                }
            })
            .addCase(addExaminationAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addExaminationAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.examinations.push(action.payload);
                    toast.success("examination added successfully");
                } else {
                    toast.error("Something went wrong .failed to add examination");
                }
            }).addCase(editExaminationAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(editExaminationAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    let idx = state.examinations.findIndex(item => item._id === action.payload._id)
                    state.examinations[idx] = action.payload
                    console.log(action.payload);
                    toast.success("examination edit successfully");
                } else {
                    toast.error("Something went wrong .failed to add examination");
                }
            })
            .addCase(deleteExaminationAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteExaminationAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const index = state.examinations.findIndex(
                        (exami) => exami._id === action.payload._id
                    );
                    state.examinations.splice(index, 1);
                    toast.success("examination deleted successfully");
                }
            });
    },
});

export const selectExamination = (state) => state.examination.examinations;

export default examinationSlice.reducer;
