import {
	HiOutlineViewGrid,
	HiOutlineUsers,
	HiOutlineDocumentText,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog,
	HiOutlineLogout,
	HiOutlineUser,
	HiOutlineDocumentReport,
	HiOutlineAnnotation
} from 'react-icons/hi'
import { FaClipboardList } from "react-icons/fa";
import { ImLab } from "react-icons/im";
import { AiOutlineSchedule } from "react-icons/ai";
import { TbDental, TbReportMoney } from 'react-icons/tb';
import { PiTooth } from "react-icons/pi";
import { MdOutlineRecordVoiceOver } from "react-icons/md";


export const DASHBOARD_SIDEBAR_LINKS = [
	{
		// key: 'dashboard',
		label: 'Dashboard',
		path: '/',
		icon: <HiOutlineViewGrid />
	},
	{
		key: 'upcoming',
		label: 'Upcoming Appointment',
		path: '/upcomming-appointment',
		icon: < AiOutlineSchedule />
	},
	{
		key: 'follow-up',
		label: 'Missed Appointments',
		path: '/missed-appointments',
		icon: <HiOutlineDocumentText />
	},
	{
		key: 'voice-message',
		label: 'Voice Messages',
		path: '/voice-message',
		icon: <MdOutlineRecordVoiceOver />
	},
	{
		key: 'patient',
		label: 'Patient',
		path: '/patient',
		icon: <HiOutlineUsers />
	},
	{
		key: 'Lab Details',
		label: 'Lab Details',
		path: '/lab-details',
		icon: <HiOutlineAnnotation />
	},
	{
		key: 'Ortho Section',
		label: 'Ortho Section',
		path: '/ortho-section',
		icon: <PiTooth />
	},
	{
		key: 'To Do List',
		label: 'To Do List',
		path: '/to-do-list',
		icon: <FaClipboardList />
	}
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	{
		key: 'settings',
		label: 'Settings',
		path: '/setting',
		icon: <HiOutlineCog />
	},
	{
		key: 'support',
		label: 'Help & Support',
		path: '/support',
		icon: <HiOutlineQuestionMarkCircle />
	},
	{
		key: 'logout',
		label: 'Logout',
		path: '/login',
		icon: <HiOutlineLogout />
	}
]

export const PATIENT_DETAILS_NAVBAR_LINKS = [
	{
		key: 'patient-details',
		label: 'Patient Details',
		// path: '../components/tabs/Details.jsx',
		icon: <HiOutlineUser />
	},
	{
		key: 'procedure',
		label: 'Procedure',
		// path: '../components/tabs/Procedure.jsx',
		icon: <TbDental />
	},
	{
		key: 'appointments',
		label: 'Appointments',
		// path: '../components/tabs/AllAppointments.jsx',
		icon: <AiOutlineSchedule />
	},
	{
		key: 'bill',
		label: 'Bill',
		// path: '../components/tabs/Bill.jsx',
		icon: <TbReportMoney />
	},
	{
		key: 'lab-reports',
		label: 'Attachments',
		// path: '../components/tabs/FileReports.jsx',
		icon: <HiOutlineDocumentReport />
	},
	{
		key: 'lab-details',
		label: 'Lab',
		// path: '../components/tabs/FileReports.jsx',
		icon: <ImLab />
	},
	{
		key: 'ortho-details',
		label: 'Ortho',
		// path: '../components/tabs/FileReports.jsx',
		icon: <PiTooth />
	},
]

export const PATIENT_DETAILS_SIDEBAR_LINKS = [
	{
		key: 'clinical-examination',
		label: 'Clinical Examination',
		// path: '../components/tabs/Details.jsx',
		icon: <HiOutlineUser />
	},
	{
		key: 'add-prescription',
		label: 'Add Prescription',
		// path: '../components/tabs/AllAppointments.jsx',
		icon: <AiOutlineSchedule />
	},
	{
		key: 'patient-communication',
		label: 'Patient Communication',
		// path: '../components/tabs/Procedure.jsx',
		icon: <TbDental />
	}
]


