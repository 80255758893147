import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW TREATMENT API =======================
export function addTreatment(treatmentDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      webURLs.ADD_TREATMENT,
      treatmentDetails
    );
    resolve(response.data);
  });
}
//=================== GET ALL TREATMENT API =======================
export function getAllTreatment() {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(webURLs.GET_TREATMENT);
    resolve(response.data)
  });
}

//========================= DELETE TREATMENT API ==================

export function deleteTreatment(treatmentId) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().delete(
      `${webURLs.DELETE_TREATMENT}/${treatmentId}`
    );
    resolve(response.data);
  });
}
