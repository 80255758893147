import React, { useEffect, useState } from 'react'
import SquareButton from '../../components/Ui Components/SquareButton'
import { ImLab } from "react-icons/im";
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useDispatch, useSelector } from 'react-redux'
import { addLabDataAsync, deleteLabDataAsync, labWorkDoneAsync, selectLabData, selectLabs } from '../../redux/lab/labSlice'
import { base64ToBlob, DateFormat, pathOrigin, TimeFormat } from '../../utils/helper'
import { TiTick } from 'react-icons/ti'
import { addLabData, deleteLabData, labWorkDone } from '../../redux/lab/labApi'
import { FaTrash, FaWhatsapp } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WebCam from '../../components/webcam/webcam';
import Skeleton from 'react-loading-skeleton';
import { imgPath, imgPath2 } from '../../components/patientDetails/imagePath';
import { selectAllPatient } from '../../redux/patientAppointments/patientSlice';
import toast from 'react-hot-toast';

export default function EachLabData() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const allPatient = useSelector(selectAllPatient)
    const Labs = useSelector(selectLabs)
    const navigate = useNavigate()
    const LabDetails = useSelector(selectLabData)
    // for Lab Data filter by lab id
    const FilteredLabData = LabDetails && LabDetails.filter(item => item.lab._id === id)
    const [LabName, setLabName] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [openWebCam, setOpenWebCame] = useState(false)
    const [capturedImage, setCapturedImage] = useState(null)
    const [tooth_no, setTooth] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // Toggle the state for the clicked image
    //{=========tooth selection start =================}
    const changeBackground = (t_no) => {
        if (tooth_no.find((tooth) => tooth === t_no)) {
            setTooth(tooth_no.filter(item => item !== t_no))
        } else {
            setTooth([...tooth_no, t_no])
        }
    };

    // for delete lab Data
    async function handleDelete(labId) {
        const is = window.confirm("Are You Sure ?");
        try {
            if (is) {
                let response = await deleteLabData(labId)
                dispatch(deleteLabDataAsync(response))
            }
        } catch (error) {
            console.log(error);
        }
    }

    //   for form submit
    const onSubmit = async (data) => {

        if (!tooth_no.length) {
            toast.error("please Select Tooth")
            return;
        }

        setIsLoading(true);
        if (!capturedImage) {
            toast.error("Please Capture photo First")
            setIsLoading(false);
            return;
        }

        let labName = LabName.lab_Owner
        // Extract the base64 data (remove the data URL prefix)
        const base64Data = capturedImage.split(',')[1]; // Only the Base64 data
        const mimeType = capturedImage.match(/^data:(.*);base64,/)[1]; // Extract MIME type (e.g., image/jpeg)

        // Convert base64 to Blob
        const imageBlob = base64ToBlob(base64Data, mimeType);

        // Convert Blob to File (optional)
        const file = new File([imageBlob], `${labName}.jpg`, { type: mimeType });


        const formData = new FormData()
        formData.append("cost", data.cost)
        formData.append("works", data.works)
        formData.append("note", data.note)
        formData.append("lab", LabName._id)
        formData.append("units", tooth_no.join("_"))
        formData.append("photo", file)

        try {
            let response = await addLabData(data.patient, formData)
            if (response.status) {
                setIsLoading(false);
                setOpenWebCame(false);
                setCapturedImage("")
                setTooth([])
                dispatch(addLabDataAsync(response))
                reset()
            } else {
                toast.error(response.message);
                setIsLoading(false);
                reset()
                setCapturedImage("")
                setOpenWebCame(false);
                setTooth([])
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    // for lab item received
    async function handleReceived(id) {
        const is = window.confirm("Are You Sure ?");
        try {
            if (is) {
                let response = await labWorkDone(id)
                if (response.status) {
                    dispatch(labWorkDoneAsync(response))
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setLabName(Labs && Labs.find(item => item._id === id))
    }, [id, Labs])

    return (
        <>
            <div className="mt-2 items-center w-full p-4 flex shadow border-b-[1px]">
                <ImLab className="text-lg p-0 m-0 text-black" />
                <h1 className="text-sm text-[#301762]">
                    Lab Owner - {LabName && LabName.lab_name}
                </h1>
            </div>
            <div className="flex flex-col md:flex-row w-full p-1">
                <div className="w-full md:w-3/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 h-[100%] overflow-y-auto w-full">
                        <div className="overflow-x-auto">
                            <div className="bg-white rounded ">
                                <div className="flex items-center bg-gray-300 rounded-t-sm justify-center space-x-2 text-[#301762] leading-6 text-xl px-4 py-2">
                                    <span className="tracking-wide text-sm">Lab Form</span>
                                </div>
                                <hr />
                                <div className="flex flex-col md:flex-row w-full p-5 gap-2 justify-center">
                                    <div className="w-full p-1 md:mb-0">
                                        <div className="text-gray-700 mb-3">
                                            {imgPath ? (
                                                <div className="w-full flex flex-wrap justify-between">
                                                    {imgPath.map((path, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => changeBackground(path.num)} // Pass the index to changeBackground
                                                            className={`rounded-sm shadow-slate-300 shadow-lg hover:bg-[#301762] hover:no-underline cursor-pointer ${tooth_no.filter(item => item === path.num).length ? "bg-[#301762] text-white" : "bg-white"
                                                                }`}
                                                            id={path.num}
                                                        >
                                                            <img
                                                                src={`${pathOrigin()}/${path.image}`}
                                                                alt={`svg${index}`}
                                                                id={path.num}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <Skeleton
                                                    className="mt-4 mx-8 rounded-sm shadow-lg"
                                                    height={200}
                                                    width={"94%"}
                                                />
                                            )}
                                        </div>
                                        <div className="text-gray-700">
                                            {imgPath2 ? (
                                                <div className="w-full flex flex-wrap justify-between">
                                                    {imgPath2.map((path, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => changeBackground(path.num)} // Pass the index to changeBackground
                                                            className={`rounded-sm shadow-slate-300 shadow-lg hover:bg-[#301762] hover:no-underline cursor-pointer ${tooth_no.filter(item => item === path.num).length ? "bg-[#301762] text-white" : "bg-white"
                                                                }`}
                                                            id={path.num}
                                                        >
                                                            <img
                                                                src={`${pathOrigin()}/${path.image}`}
                                                                alt={`svg${index}`}
                                                                id={path.num}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <Skeleton
                                                    className="mt-4 mx-8 rounded-sm shadow-lg"
                                                    height={200}
                                                    width={"94%"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row w-full gap-2 justify-center">
                                    <div className="w-full md:w-2/8 p-1 md:mb-0">
                                        <div className="pl-6 flex flex-wrap justify-center gap-8">
                                            <form className="py-2" onSubmit={handleSubmit(onSubmit)}>
                                                <label htmlFor="patient" className="block text-sm font-medium leading-6 text-gray-600">
                                                    Patient File Number
                                                </label>
                                                <div className="mb-2">
                                                    <input
                                                        id="patient"
                                                        name="patient"
                                                        type='text'
                                                        autoComplete="patient"
                                                        {...register("patient", {
                                                            required: "*patient is required",
                                                        })}
                                                        placeholder='Name | File Number'
                                                        list='plist'
                                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    />
                                                    <datalist id='plist'>
                                                        {allPatient && allPatient.map((ele, ind) => (
                                                            <option value={ele.file_number} key={ind}>{`${ele.patient_name} ${ele.surname} ${ele.file_number}`}</option>
                                                        ))}
                                                    </datalist>
                                                    <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                        <ErrorMessage errors={errors} name="patient" />
                                                    </div>
                                                </div>
                                                <label htmlFor="works" className="block text-sm font-medium leading-6 text-gray-600">
                                                    Work
                                                </label>
                                                <div className="mb-2">
                                                    <input
                                                        id="works"
                                                        {...register("works", {
                                                            required: "*works is required",
                                                        })}
                                                        name="works"
                                                        type="text"
                                                        autoComplete="works"
                                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    />
                                                    <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                        <ErrorMessage errors={errors} name="works" />
                                                    </div>
                                                </div>
                                                <label htmlFor="cost" className="block text-sm font-medium leading-6 text-gray-600">
                                                    Cost
                                                </label>
                                                <div className="mb-2">
                                                    <input
                                                        id="cost"
                                                        {...register("cost", {
                                                            required: "*cost is required",
                                                        })}
                                                        name="cost"
                                                        type="text"
                                                        autoComplete="cost"
                                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    />
                                                    <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                        <ErrorMessage errors={errors} name="cost" />
                                                    </div>
                                                </div>
                                                <label htmlFor="note" className="block text-sm font-medium leading-6 text-gray-600">
                                                    Note
                                                </label>
                                                <div className="mb-2">
                                                    <input
                                                        id="note"
                                                        {...register("note")}
                                                        name="note"
                                                        type="text"
                                                        autoComplete="note"
                                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    />
                                                    <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                        <ErrorMessage errors={errors} name="note" />
                                                    </div>
                                                </div>
                                                <div className="mt-3 mb-2">
                                                    <SquareButton
                                                        btnType={"submit"}
                                                        isLoading={isLoading}
                                                        sqicon={<span className="text-xs">Add Lab Data</span>}
                                                        className={"w-full bg-[#301762] text-white p-2 rounded"}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/1 p-1 md:mb-0">
                                        {openWebCam ? <WebCam
                                            className={"w-full p-1"}
                                            height={230}
                                            width={400}
                                            getImage={(data) => { setCapturedImage(data) }}
                                        /> : <div className="flex justify-center"><img src="/asset/images/unknown-image.jpg" className="m-2 h-[280px]" alt="user" loading="lazy" /></div>
                                        }
                                        {openWebCam ?
                                            <SquareButton
                                                handleClick={() => { setOpenWebCame(false); setCapturedImage(""); }}
                                                sqicon={<span className="text-xs">Close Camera</span>}
                                                className={"bg-red-600 relative sm:top-[25px] sm:left-[0px] text-white rounded"}
                                            />
                                            :
                                            <SquareButton
                                                sqicon={<span className="text-xs">Open Camera</span>}
                                                handleClick={() => { setCapturedImage(""); setOpenWebCame(true) }}
                                                className={"bg-[#301762] text-white p-2 rounded  focus:ring-[#301762]"}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="flex flex-col md:flex-row w-full h-96 p-1">
                {/* Second Box - Two Thirds Width */}
                <div className="w-full md:w-3/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 h-96 overflow-y-auto w-full">
                        <div className="flex bg-gray-300 pl-2 rounded-t-sm text-white items-center">
                            <ImLab className="text-lg p-0 m-0" />
                            <h1 className="pl-3 text-sm py-2 text-[#301762]">
                                ({LabName && LabName.lab_name}) Lab Reports ({FilteredLabData && FilteredLabData.length})
                            </h1>
                        </div>
                        {/* //======================file display table section start============================= */}
                        <div className="overflow-x-auto">
                            <table className="min-w-full text-xs bg-white rounded-lg">
                                <thead>
                                    <tr className="bg-gray-100 text-center text-gray-600">
                                        <th className="py-2 px-4 font-medium">
                                            Sr.No.
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Patient Name
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Works
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Cost
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Units
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Note
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Date
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Received
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Send To
                                        </th>
                                        <th className="py-2 px-2 font-medium">
                                            Options
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {FilteredLabData && FilteredLabData.map((item, indx) => (
                                        <tr className="text-center border-t border-gray-200 hover:bg-gray-50" key={indx} >
                                            <td className="py-2 px-2 text-gray-700">
                                                {indx + 1}
                                            </td>
                                            <td className="py-2 px-2 hover:text-blue-700 text-gray-700 cursor-pointer" onClick={() => { navigate(`/patient/patientdetails/${item.patient?._id}`) }}>
                                                {<>{item.patient?.patient_name}<br />
                                                    <>F-{item.patient?.file_number}</>
                                                </>}
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                {item.works}
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                {item.cost}
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                {item.units?.replaceAll("_", ",")}
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                {item.note}
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                <span>{DateFormat(item.createdAt)}</span>
                                                <br />
                                                <span>{TimeFormat(item.createdAt)}</span>
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">

                                                {item.isReceived ?
                                                    <button className="text-2xl text-green-500 text-center"><TiTick /></button>
                                                    : <button
                                                        onClick={() => {
                                                            handleReceived(item._id);
                                                        }}

                                                        className="bg-yellow-500 hover:bg-yellow-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                                                        title="Received button"
                                                    >
                                                        Received
                                                    </button>
                                                }

                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                <div className="flex justify-center">
                                                    <SquareButton
                                                        sqicon={
                                                            <span className="text-xl" title="Send work To lab">
                                                                <Link
                                                                    to={`https://web.whatsapp.com/send?phone=+91${item?.lab?.mobile_number}&text=Hi%20${item?.lab?.lab_Owner}%20Your%20Work-%20${item?.works}%20Units-%20${item?.units}%20Patient-Name-%20${item?.patient?.patient_name}%20${item?.patient?.surname}%20at%20${DateFormat(item?.createdAt)}and%20also%20get%20Image%20${item.photo}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                                                                    target="_blank"
                                                                    className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                                                                >
                                                                    <FaWhatsapp />
                                                                </Link>
                                                            </span>
                                                        }
                                                        color={"#25D366"}
                                                    />
                                                </div>
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                <button onClick={() => { handleDelete(item._id) }} className="text-red-500 hover:text-red-400 text-lg" title="Delete Lab Data "><FaTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
