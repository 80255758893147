import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW ORTHO API =======================
export async function addOrtho(data) {
    const response = await axiosClient().post(
        `${webURLs.ADD_ORTHO}${data?.patient}`,
        data
    );
    return response.data;
}


//=================== GET ALL ORTHO API =======================
export async function getAllOrtho() {
    const response = await axiosClient().get(webURLs.GET_ALL_ORTHO);
    return response.data;
}


//=================== GET ALL ORTHO API =======================
export async function getAllOrthoByPatientId(patientId) {
    const response = await axiosClient().get(webURLs.GET_ALL_ORTHO_BY_PATIENT_ID + patientId);
    return response.data;
}


//========================= DELETE ORTHO API ==================
export async function deleteOrtho(id) {
    const response = await axiosClient().put(
        `${webURLs.DELETE_ORTHO}${id}`
    );
    return response.data;
}


//========================= UPDATE ORTHO API ==================

export async function updateOrtho(patientId,orthoId,data) {
    const response = await axiosClient().put(
        `${webURLs.UPDATE_ORTHO}/${patientId}/${orthoId}`,data
    );
    return response.data;
}
