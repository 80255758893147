import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import { FaTrash } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import { DateFormat, Prifix, TimeFormat } from "../../utils/helper";
import { selectedPatient } from "../../redux/patientAppointments/patientSlice";
import { useParams } from "react-router-dom";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import { deleteLabDataAsync, labWorkDoneAsync } from "../../redux/lab/labSlice";
import { deleteLabData, labWorkDone } from "../../redux/lab/labApi";
import ShowPhotoModel from "../modal/showPhotoModel";

export default function LabDetails() {
    const { id } = useParams();
    const patientDetails = useSelector(selectedPatient);
    const dispatch = useDispatch();
    const [labData, setLabData] = useState([])
    const [photoModelOpen, setPhotoModelOpen] = useState(false)
    const [modelData, setModelData] = useState()

    // for delete lab Data
    async function handleDelete(labId) {
        const is = window.confirm("Are You Sure ?");
        try {
            if (is) {
                let response = await deleteLabData(labId)
                dispatch(deleteLabDataAsync(response))
                if (response.status) {
                    setLabData(labData.filter(item => item._id !== response.data._id))
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    // for lab item received
    async function handleReceived(id) {
        const is = window.confirm("Are You Sure ?");
        try {
            if (is) {
                let response = await labWorkDone(id)
                if (response.status) {
                    let index = labData.findIndex(item => item._id === response.data._id)
                    labData.splice(index, 1)
                    setLabData([...labData, response.data])
                }
                dispatch(labWorkDoneAsync(response))
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        async function getLabDetails() {
            try {
                let response = await axiosClient().get(`${webURLs.GET_LAB_DATA_BY_PATIENT_ID}${id}`)
                if (response.data.status) {
                    setLabData(response.data.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        getLabDetails();
    }, [id]);

    return (
        <>
            <div className="mb-2">
                <div className="flex justify-between w-full p-3">
                    <div className="flex">
                        <span>
                            <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
                        </span>
                        <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                            Patient Name -
                        </p>
                        <p className="text-gray-600 text-sm pt-[5px] pl-2">
                            {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
                        </p>
                    </div>
                    <div className="flex mr-2">
                        <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                            File Number -
                        </p>
                        <p className="text-gray-600 text-sm pt-[5px] pl-2">
                            {patientDetails?.file_number}
                        </p>
                    </div>
                </div>
                <section
                    id="medicine-section"
                    className="bg-white  overflow-y-auto mb-2"
                >
                    <div className="w-full pl-2 bg-gray-300 rounded-t-sm text-white items-center">
                        <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
                            Total Lab Report
                        </h1>
                    </div>
                    <hr />
                    {/* //======================file display table section start============================= */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white rounded-lg">
                            <thead className="text-xs text-gray-700 uppercase">
                                <tr className="bg-gray-100 text-center">
                                    <th className="py-2 px-2 font-medium">
                                        Sr.No.
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Lab Name
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Works
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Cost
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Units
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Note
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Date
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Received
                                    </th>
                                    <th className="py-2 px-2 font-medium">
                                        Options
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {labData &&
                                    labData.map((item, idx) => (
                                        <tr className="text-center text-xs border-t border-gray-200 hover:bg-gray-50" key={idx}>
                                            <td className="py-2 px-2">
                                                {idx + 1}
                                            </td>
                                            <td className="py-2 px-2">
                                                {item.lab?.lab_name}
                                            </td>
                                            <td className="py-2 px-2">
                                                {item.works}
                                            </td>
                                            <td className="py-2 px-2">
                                                {item.cost}
                                            </td>
                                            <td className="py-2 px-2">
                                                {item.units?.replace("_", ",")}
                                            </td>
                                            <td className="py-2 px-2">
                                                {item.note}
                                            </td>
                                            <td className="py-2 px-2">
                                                <span>{DateFormat(item.createdAt)}</span>
                                                <br />
                                                <span>{TimeFormat(item.createdAt)}</span>
                                            </td>
                                            <td className="py-2 px-2">

                                                {item.isReceived ?
                                                    <button className="text-2xl text-green-500"><TiTick /></button>
                                                    : <button
                                                        onClick={() => {
                                                            handleReceived(item._id);
                                                        }}

                                                        className="bg-yellow-500 hover:bg-yellow-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                                                        title="Received button"
                                                    >
                                                        Received
                                                    </button>
                                                }

                                            </td>
                                            <td className="py-2 px-2">
                                                <div className="flex gap-3 justify-center">
                                                    <button onClick={() => { setPhotoModelOpen(true); setModelData(item) }} className="text-blue hover:text-purple-900 text-lg" title="Delete Lab Data "><FaEye /></button>
                                                    <button onClick={() => { handleDelete(item._id) }} className="text-red-500 hover:text-red-400 text-lg" title="Delete Lab Data "><FaTrash /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    {/* //======================file display table section start============================= */}
                </section>
            </div>
            {/* edit prescription model show */}
            <ShowPhotoModel
                content={<>
                    <div className="flex flex-col md:flex-row w-full justify-evenly p-1 border">
                        <div className="w-full md:w-1/3 p-5 rounded-lg ">
                            <h2 className="text-blue mb-2 font-medium text-sm">Lab Details</h2>
                            <hr />
                            <ul className="grid gap-4 p-4">
                                <li><strong className="text-blue">Lab Name</strong> - {modelData && modelData.lab.lab_name}</li>
                                <li><strong className="text-blue">Works</strong> - {modelData && modelData.works}</li>
                                <li><strong className="text-blue">Cost</strong> - {modelData && modelData.cost}</li>
                                <li><strong className="text-blue">Units</strong> - {modelData && modelData.units}</li>
                            </ul>
                        </div>
                        <div className="w-full flex justify-center md:w-2/3 p-3 rounded-md">
                            {modelData?.photo ? <img src={modelData && modelData.photo} alt={modelData && modelData.lab.lab_name} className="h-96 rounded" />
                                : <img src="/asset/images/unknown-image.jpg" alt={modelData && modelData.lab.lab_name} className="h-96 rounded" />}
                        </div>
                    </div>
                </>
                }
                open={photoModelOpen}
                setOpen={setPhotoModelOpen}
            />
        </>
    );
}
