import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW TREATMENT API =======================
export function addFee(feeDetails) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().post(
            webURLs.ADD_FEE,
            feeDetails
        );
        resolve(response.data);
    });
}
//=================== GET ALL TREATMENT API =======================
export function getAllFee() {
    return new Promise(async (resolve) => {
        const response = await axiosClient().get(webURLs.GET_FEE);
        resolve(response.data)
    });
}

//========================= DELETE TREATMENT API ==================

export function deleteFee(id) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().delete(
            `${webURLs.DELETE_FEE}${id}`
        );
        resolve(response.data);
    });
}
