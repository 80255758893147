import React, { useEffect, useState } from 'react'
import SquareButton from '../../components/Ui Components/SquareButton'
import { FaEdit } from 'react-icons/fa'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { GetTime } from '../../utils/helper'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import toast from 'react-hot-toast'
import { LiaTeethSolid } from "react-icons/lia";
import { addTreatmentAsync, deleteTreatmentAsync, getAllTreatmentAsync, selectTreatment } from '../../redux/treatment/treatmentSlice'
import { useDispatch, useSelector } from 'react-redux'

export default function Treatment() {
    const allTreatment = useSelector(selectTreatment);
    const dispatch = useDispatch();
    const [addProcedureUi, setProcedureUi] = useState([])
    const [treatmentPhase, setTreatmentPhase] = useState([])
    const [editTreatment, setEditTreatment] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [editModelOpen, setEditModelOpen] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // for add more visits
    function AddMore() {
        const id = GetTime()
        if (addProcedureUi.length < 6) {
            setProcedureUi([...addProcedureUi, id])
            const Obj = {
                id,
                procedure_name: "",
                procedure_cost: ""
            }
            setTreatmentPhase([...treatmentPhase, Obj])
        }
    }

    // for remove more visits
    function RemoveMore(idx) {
        setProcedureUi(addProcedureUi.filter(item => item !== idx))
        setTreatmentPhase(treatmentPhase.filter(item => item.id !== idx))
    }

    // add value to treatment phases
    function handleAddPhase(e, idx) {
        const { value, name } = e.target;
        setTreatmentPhase((pre) => {
            pre[idx][name] = value
            return pre
        })
    }

    // submit form

    const addTreatment = (data) => {
        setIsLoading(true)
        let arr = treatmentPhase && treatmentPhase.map((item) => {
            let ob = { procedure_name: item.procedure_name, procedure_cost: item.procedure_cost }
            return ob
        })
        const Obj = {
            treatment_name: data.treatment_name,
            total_cost: data.cost,
            phase: arr
        }

        try {
            dispatch(addTreatmentAsync(Obj));
            reset()
            setRefresh(!refresh)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error("something went wrong. Failed to add Medicine .");
        }
    };


    // delete medicine 
    const handleDelete = (id) => {
        setIsLoading(true)
        try {
            dispatch(deleteTreatmentAsync(id));
            setRefresh(!refresh)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message);
        }
    };

    // open edit medicine model
    function EditForm(data) {
        setEditTreatment({
            _id: data._id,
            treatment_name: data.treatment_name,
            total_cost: data.total_cost,
            phase: data.phase
        })
        setEditModelOpen(true)
    }

    // every api hit in this page refresh 
    useEffect(() => {
        dispatch(getAllTreatmentAsync())
    }, [refresh])

    return (
        <>
            <div className='container'>
                <div className='flex p-2'>
                    <div className='w-full'>
                        <div id="uploadfile-form-section" className="rounded-lg bg-white border-2 pb-3" >
                            <h1 className="pl-3 mb-4 text-sm font-medium py-2 text-center rounded-t-lg bg-gray-300 text-[#301762]">
                                Add Treatment
                            </h1>
                            <form className="flex flex-col gap-3 items-start pl-3" onSubmit={handleSubmit(addTreatment)}>
                                {/* ===============form fields start================= */}
                                <div className="flex justify-evenly gap-4">
                                    <div className="flex flex-col">
                                        <label
                                            htmlFor="treatment_name"
                                            className="bg-white left-2 top-3 text-sm text-gray-600 w-fit font-medium font-sans relative"
                                        >
                                            Treatment Name
                                        </label>
                                        <input
                                            className="py-2 border-gray-300 border rounded px-1 text-sm  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                                            type="text"
                                            name="treatment_name"
                                            {...register("treatment_name", {
                                                required: "*treatment_name is required",
                                            })}
                                            placeholder="Enter Treatment Name"
                                            id="lab"
                                            list='suggestion'
                                        />
                                        <datalist id="suggestion">
                                            <option>Root Canal Treatment</option>
                                            <option>Scaling and curettage with polishing</option>
                                            <option>Extraction</option>
                                            <option>Disimpaction </option>
                                            <option>Crown Bridges</option>
                                            <option>Orthodontic treatment</option>
                                            <option>Fixed Partial Denture</option>
                                            <option>Removable Partial Denture</option>
                                            <option>Complete Dentures </option>
                                            <option>Smile Designing</option>
                                        </datalist>
                                        <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                            <ErrorMessage errors={errors} name="treatment_name" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <label
                                            htmlFor="cost"
                                            className="bg-white left-2 top-3 text-sm text-gray-600 w-fit font-medium font-sans relative"
                                        >
                                            Total Approx. Cost
                                        </label>
                                        <input
                                            className="py-2 border-gray-300 border rounded px-1 text-sm  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                                            type="text"
                                            name="cost"
                                            placeholder="Enter Treatment Cost"
                                            id="cost"
                                            {...register("cost", {
                                                required: "*treatment_cost is required",
                                            })}
                                        />
                                        <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                                            <ErrorMessage errors={errors} name="cost" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col py-5">
                                        <SquareButton
                                            btnType={"button"}
                                            isLoading={false}
                                            handleClick={AddMore}
                                            sqicon={<span className="text-sm">Add More Visits</span>}
                                        />
                                    </div>
                                </div>
                                {addProcedureUi && addProcedureUi.map((item, idx) => (
                                    <div className="flex justify-evenly gap-4" key={idx}>
                                        <div className="flex flex-col">
                                            <label
                                                htmlFor="procedure_name"
                                                className="bg-white left-2 top-3 text-sm text-gray-600 w-fit font-medium font-sans relative"
                                            >
                                                Procedure Name
                                            </label>
                                            <input
                                                className="py-2 border-gray-300 border rounded px-1 text-sm  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                                                type="text"
                                                name="procedure_name"
                                                placeholder="Enter Treatment procedure Name"
                                                defaultValue={item.procedure_name}
                                                list='suggestion1'
                                                onChange={(e) => { handleAddPhase(e, idx) }}
                                            />
                                            <datalist id="suggestion1">
                                                <option>RCT 1</option>
                                                <option>RCT 2 </option>
                                                <option>RCT 3 </option>
                                                <option>Impression</option>
                                                <option>Bridge Prepration</option>
                                                <option>Metal trial</option>
                                                <option>Final cementation</option>
                                                <option>Temporization</option>
                                                <option>Primary Impression</option>
                                                <option>Secondary Impression</option>
                                                <option>Jaw Relation</option>
                                                <option>Trials</option>
                                            </datalist>
                                            <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                <ErrorMessage errors={errors} name="procedure_name" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <label
                                                htmlFor="contains"
                                                className="bg-white left-2 top-3 text-sm text-gray-600 w-fit font-medium font-sans relative"
                                            >
                                                Approx. Cost
                                            </label>
                                            <input
                                                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                                                type="text"
                                                name="procedure_cost"
                                                placeholder="Enter Treatment Cost"
                                                defaultValue={item.procedure_cost}
                                                onChange={(e) => { handleAddPhase(e, idx) }}
                                            />
                                            <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                <ErrorMessage errors={errors} name="procedure_cost" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col py-5">
                                            <SquareButton
                                                className={'bg-red-600'}
                                                btnType={"button"}
                                                isLoading={false}
                                                handleClick={() => { RemoveMore(item) }}
                                                sqicon={<span className="text-sm">-</span>}
                                            />
                                        </div>
                                    </div>
                                ))}
                                {/* ===============form fields start=================  */}
                                <hr className='text-black' />
                                <div className=''>
                                    <SquareButton
                                        btnType={"submit"}
                                        isLoading={isLoading}
                                        sqicon={<span className="text-sm">Add Treatment</span>}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <section
                id="medicine-section"
                className="rounded-sm border-2 mt-3 pb-3 h-96 overflow-y-auto w-full"
            >
                <div className="flex bg-gray-300 pl-2 rounded-t-sm text-white items-center sticky top-0">
                    <LiaTeethSolid className="text-2xl p-0 m-0" />
                    <h1 className="pl-3 text-sm py-2 text-[#301762]">
                        Total Treatments({allTreatment &&
                            allTreatment.length})
                    </h1>
                </div>
                {/* //======================file display table section start============================= */}
                <table className="w-full text-xs text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  sticky top-9 text-center">
                        <tr>
                            <th scope="col" className="px-2 py-2 font-medium">
                                S.No.
                            </th>
                            <th scope="col" className="px-2 py-2 font-medium">
                                Treatment Name
                            </th>
                            <th scope="col" className="px-2 py-2 font-medium">
                                Total Approx Cost
                            </th>
                            <th scope="col" className="px-2 py-2 font-medium">
                                Treatment Procedure
                            </th>
                            <th scope="col" className="px-2 py-2 font-medium">
                                Edit
                            </th>
                            <th scope="col" className="px-2 py-2 font-medium">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody className="capitalize">
                        {allTreatment &&
                            allTreatment.map((item, index) => (
                                <tr key={index} className='text-center'>
                                    <td className="px-2 py-2 font-medium">
                                        {index + 1}
                                    </td>
                                    <td className="px-2 py-2">
                                        {item?.treatment_name}
                                    </td>
                                    <td className="px-2 py-2">{item?.total_cost}</td>
                                    <td className="px-2 py-2"><ul>{item?.phase?.map((ele, idx) => (
                                        <li key={idx}><b>Procedure Name</b> : {ele?.procedure_name}<br />
                                            <b>Procedure Approx cost</b> : {ele?.procedure_cost}
                                        </li>
                                    ))} </ul></td>
                                    <td className="px-2 py-2 flex justify-center items-center">
                                        <SquareButton
                                            sqicon={
                                                <span className="text-xl" title="Edit Medicine">
                                                    <FaEdit />
                                                </span>
                                            }
                                            handleClick={() => { EditForm(item) }}
                                        />
                                    </td>
                                    <td className="px-2 py-2">
                                        <button
                                            onClick={() => {
                                                handleDelete(`${item._id}`);
                                            }}
                                            className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                                            title="Delete Medicine"
                                        >
                                            <RiDeleteBin6Line className="p-0 m-0 text-xl" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {/* //======================file display table section start============================= */}
            </section>
        </>
    )
}
