import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addLab, getLab, deleteLab, getLabData, getLabDataByPatientId } from "./labApi";
import toast from "react-hot-toast";

const initialState = {
    Labs: [],
    LabData: [],
    status: "idle",
};

//=============GET Lab THUNK================
export const getLabAsync = createAsyncThunk(
    "lab/getLab",
    async () => {
        const response = await getLab();
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);

//============= ADD Lab THUNK =============
export const addLabAsync = createAsyncThunk(
    "lab/addLab",
    async (data) => {
        const response = await addLab(data);
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);

//===============DELETE lab API ====================
export const deleteLabAsync = createAsyncThunk(
    "lab/deleteLab",
    async (labId) => {
        try {
            const response = await deleteLab(labId);
            if (response.status) {
                return response.data;
            } else {
                toast.error(response.message);
                return false;
            }
        } catch (error) {
            console.log(error.message);
        }
    }
);

//=============GET Lab Data THUNK================
export const getLabDataAsync = createAsyncThunk(
    "lab/getLabData",
    async () => {
        const response = await getLabData();
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);

//=============GET Lab Data by Patient Id THUNK================
export const getLabDataByPatientIdAsync = createAsyncThunk(
    "lab/getLabDataByPatientId",
    async (patientId) => {
        const response = await getLabDataByPatientId(patientId);
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);


//============= ADD Lab data THUNK =============
export const addLabDataAsync = createAsyncThunk(
    "lab/addLabData",
    async (response) => {
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);


//===============DELETE Lab Data API ====================
export const deleteLabDataAsync = createAsyncThunk(
    "lab/deleteLabData",
    async (response) => {
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);


//=============== work lab done API ====================
export const labWorkDoneAsync = createAsyncThunk(
    "lab/labWorkDone",
    async (response) => {
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);



export const labSlice = createSlice({
    name: "lab",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLabAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getLabAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.Labs = action.payload;
                }
            })
            .addCase(addLabAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLabAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.Labs.push(action.payload);
                    toast.success("Lab added successfully");
                } else {
                    toast.error("Something went wrong .failed to add lab");
                }
            })
            .addCase(deleteLabAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteLabAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Labs = state.Labs.filter(item => item._id !== action.payload._id)
                    toast.success("lab deleted successfully");
                }
            }).addCase(getLabDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getLabDataAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.LabData = action.payload;
                }
            }).addCase(addLabDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLabDataAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.LabData.push(action.payload);
                    toast.success("Lab Data added successfully");
                } else {
                    toast.error("Something went wrong .failed to add lab");
                }
            }).addCase(deleteLabDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteLabDataAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    state.LabData = state.LabData.filter(item => item._id !== action.payload._id)
                    toast.success("lab Data deleted successfully");
                }
            }).addCase(labWorkDoneAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(labWorkDoneAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    let index = state.LabData.findIndex(item => item._id === action.payload._id)
                    state.LabData.splice(index, 1)
                    state.LabData = [...state.LabData, action.payload]
                    toast.success("lab Data received successfully");
                }
            });
    },
});

export const selectLabs = (state) => state.lab.Labs;
export const selectLabData = (state) => state.lab.LabData;

export default labSlice.reducer;
