import React, { useState } from 'react'
import SquareButton from '../../components/Ui Components/SquareButton'
import { ImLab } from "react-icons/im";
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useDispatch, useSelector } from 'react-redux'
import { addLabAsync, deleteLabAsync, selectLabs } from '../../redux/lab/labSlice'
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function LabDetails() {
    const dispatch = useDispatch()
    const Labs = useSelector(selectLabs)
    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    function handleAddLab(data) {
        setIsLoading(true)
        dispatch(addLabAsync(data))
        reset()
        setIsLoading(false)
    }

    // for delete lab Data
    async function handleLabDelete(labId) {
        const is = window.confirm("Are You Sure ?");
        try {
            if (is) {
                dispatch(deleteLabAsync(labId))
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="flex flex-col md:flex-row w-full p-1">
                {/* First Box - One Third Width */}
                <div className="w-full md:w-1/3 p-1 rounded-lg md:mb-0">
                    <div id="uploadfile-form-section" className="rounded bg-white border-2 pb-3" >
                        <h1 className="text-sm py-2 text-center  bg-gray-300 text-[#301762]">
                            Lab Form
                        </h1>
                        <div className="max-w-md mx-auto p-6 bg-white rounded-lg">
                            <form onSubmit={handleSubmit(handleAddLab)}>
                                <div className="mb-4">
                                    <label htmlFor="lab_name" className="block text-sm font-medium text-gray-600 mb-2">
                                        Lab Name
                                    </label>
                                    <input
                                        type="text"
                                        name="lab_name"
                                        id="lab_name"
                                        {...register("lab_name", {
                                            required: "*lab name is required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Lab name"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="lab_name" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="lab_Owner" className="block text-sm font-medium text-gray-600 mb-2">
                                        Lab Owner Name
                                    </label>
                                    <input
                                        type="text"
                                        name="lab_Owner"
                                        id="lab_Owner"
                                        {...register("lab_Owner", {
                                            required: "*lab Owner name is required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Lab Owner Name"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="lab_Owner" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="mobile_number" className="block text-sm font-medium text-gray-600 mb-2">
                                        Mobile
                                    </label>
                                    <input
                                        type="text"
                                        name="mobile_number"
                                        id="mobile_number"
                                        {...register("mobile_number", {
                                            required: "*mobile number is required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter your mobile number"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="mobile_number" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="lab_address" className="block text-sm font-medium text-gray-600 mb-2">
                                        Address
                                    </label>
                                    <textarea
                                        name="lab_address"
                                        id="lab_address"
                                        {...register("lab_address", {
                                            required: "*lab Address is required",
                                        })}
                                        className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Lab address"
                                        rows="3"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="lab_address" />
                                    </div>
                                </div>
                                <SquareButton
                                    isLoading={isLoading}
                                    sqicon={<span className='text-xs'>Add</span>}
                                    className={"w-full bg-[#301762] text-white p-2"}
                                    btnType={"submit"}
                                />
                            </form>
                        </div>
                    </div>
                </div>

                {/* Second Box - Two Thirds Width */}
                <div className="w-full md:w-2/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 h-[100%] overflow-y-auto w-full">
                        <div className="flex bg-gray-300 pl-2 rounded-t-sm text-white items-center sticky top-0">
                            <ImLab className="text-xl p-0 m-0" />
                            <h1 className="pl-3 text-sm py-2 text-[#301762]">
                                Labs ({Labs && Labs.length})
                            </h1>
                        </div>
                        {/* //======================file display table section start============================= */}
                        <div className="overflow-x-auto">
                            <table className="min-w-full text-xs bg-white rounded-lg">
                                <thead>
                                    <tr className="bg-gray-100 text-gray-600">
                                        <th className="py-2 px-2 text-left font-medium">Sr No</th>
                                        <th className="py-2 px-2 text-left font-medium">Lab Name</th>
                                        <th className="py-2 px-2 text-left font-medium">Owner Name</th>
                                        <th className="py-2 px-2 text-left font-medium">Mobile</th>
                                        <th className="py-2 px-2 text-left font-medium">Address</th>
                                        <th className="py-2 px-2 text-left font-medium">Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Labs && Labs.map((item, idx) => (
                                        <tr className="border-t border-gray-200 hover:bg-gray-50 text-gray-700" key={idx}>
                                            <td className="py-2 px-2">{idx + 1}</td>
                                            <td className="py-2 px-2"><Link className='hover:text-blue-700' to={`/lab-details/${item._id}`}>{item.lab_name}</Link></td>
                                            <td className="py-2 px-2">{item.lab_Owner}</td>
                                            <td className="py-2 px-2">{item.mobile_number}</td>
                                            <td className="py-2 px-2">{item.lab_address}</td>
                                            <td className="py-2 px-2">
                                                <button onClick={() => { handleLabDelete(item._id) }} className="text-red-500 hover:text-red-400 text-xl" title="Delete Lab"><FaTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
