// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
    display: grid;
    place-items: center;
    background: var(--theme-blue);
    border-radius: 20rem;
    padding: 1em;
    border: 1px solid rgba(0,0,0,0);
    font-size: 1em;
    cursor: pointer;
    transition: transform 0.5s;
  }
  
  .btn:hover {
    box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.2),
            inset -4px -4px 6px -1px rgba(255,255,255,0.7),
            -0.5px -0.5px 0px rgba(255,255,255,1),
            0.5px 0.5px 0px rgba(0,0,0,0.15),
            0px 12px 10px -10px rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0.1);
    transform: translateY(0.2em);
    background: #e3edf7;
    
  }
  
  .btn svg {
    transition: transform 0.5s;
  }
  
  .btn:hover svg {
    transform: scale(0.9);
    fill: #333333;
  }

  
  `, "",{"version":3,"sources":["webpack://./src/components/Ui Components/RoundedButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,oBAAoB;IACpB,YAAY;IACZ,+BAA+B;IAC/B,cAAc;IACd,eAAe;IACf,0BAA0B;EAC5B;;EAEA;IACE;;;;gDAI4C;IAC5C,iCAAiC;IACjC,4BAA4B;IAC5B,mBAAmB;;EAErB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,qBAAqB;IACrB,aAAa;EACf","sourcesContent":[".btn {\n    display: grid;\n    place-items: center;\n    background: var(--theme-blue);\n    border-radius: 20rem;\n    padding: 1em;\n    border: 1px solid rgba(0,0,0,0);\n    font-size: 1em;\n    cursor: pointer;\n    transition: transform 0.5s;\n  }\n  \n  .btn:hover {\n    box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.2),\n            inset -4px -4px 6px -1px rgba(255,255,255,0.7),\n            -0.5px -0.5px 0px rgba(255,255,255,1),\n            0.5px 0.5px 0px rgba(0,0,0,0.15),\n            0px 12px 10px -10px rgba(0,0,0,0.05);\n    border: 1px solid rgba(0,0,0,0.1);\n    transform: translateY(0.2em);\n    background: #e3edf7;\n    \n  }\n  \n  .btn svg {\n    transition: transform 0.5s;\n  }\n  \n  .btn:hover svg {\n    transform: scale(0.9);\n    fill: #333333;\n  }\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
