import React, { useCallback, useEffect, useState } from "react";
import { GiMedicines } from "react-icons/gi";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxDropDown from "../../components/dropdown/CheckBoxDropDown";
import { selectMedicine } from "../../redux/medicines/medicineSlice";
import { addMedicinesGroupAsync, deleteMedicineGroupAsync, getMedicinesGroupAsync, selectMedicineGroup } from "../../redux/medicineGroup/medicineGroupSlice";

const MedicineGroup = () => {
    const dispatch = useDispatch()
    const allMedicines = useSelector(selectMedicine);
    const [medicineGroup, setMedicineGroup] = useState([])
    const getAllMedicineGroup = useSelector(selectMedicineGroup)
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const addMedicineGroup = async (data) => {
        setIsLoading(true)
        try {
            let Obj = {
                group_name: data.group,
                medicines: medicineGroup
            }
            if (medicineGroup.length > 2) {
                dispatch(addMedicinesGroupAsync(Obj))
                reset()
                setRefresh(!refresh)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                toast.error("Please select atlease Three Medicine")
            }
        } catch (error) {
            setIsLoading(false)
            toast.error("something went wrong. Failed to add Medicine .");
        }
    };

    const handleDelete = async (id) => {
        setIsLoading(true)
        try {
            dispatch(deleteMedicineGroupAsync(id))
            setRefresh(!refresh)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message);
        }
    };

    function handleSelected(data) {
        let selected = data && data.map((item) => ({ medicine_name: item.value }))
        setMedicineGroup(selected)
    }

    // every api hit in this page refresh 
    useEffect(() => {
        dispatch(getMedicinesGroupAsync())
    }, [refresh, dispatch])

    const ConvertToCheckbox = useCallback(() => {
        let medicineData = allMedicines && allMedicines.map((item) => ({
            value: item._id,
            label: item.medicine_name
        }));
        return medicineData
    }, [allMedicines])

    return (
        <>
            <div
                id="uploadfile-form-section"
                className="rounded-sm bg-white border-2 pb-3 mt-1"
            >
                <h1 className="pl-3 mb-4 text-sm font-medium py-2 rounded-t-sm text-center bg-gray-300 text-[#301762]">
                    Add New Medicine Group
                </h1>
                <form
                    className="flex flex-col gap-3 items-start pl-3"
                    onSubmit={handleSubmit(addMedicineGroup)}
                >
                    {/* ===============form fields start================= */}
                    <div className="grid grid-cols-3 w-full">
                        <div className="flex flex-col w-3/4">
                            <label
                                className="bg-white left-2 top-3 text-sm text-gray-600 w-fit font-medium font-sans relative"
                            >
                                Medicine Group Name
                            </label>
                            <input
                                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                                type="text"
                                name="group"
                                {...register("group", {
                                    required: "*medicine group name is required",
                                })}
                                placeholder="Enter Medicine Group Name"
                                id="medicine"
                            />
                            <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                <ErrorMessage errors={errors} name="group" />
                            </div>
                        </div>
                        <div className="flex flex-col w-3/4">
                            <label
                                className="bg-white z-40 left-2 top-3 text-sm text-gray-600 w-fit font-medium font-sans relative"
                            >
                                Select Medicines
                            </label>
                            <CheckBoxDropDown data={ConvertToCheckbox(allMedicines)} getData={handleSelected} />
                            <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                <ErrorMessage errors={errors} name="contains" />
                            </div>
                        </div>
                    </div>
                    {/* ===============form fields start=================  */}
                    <div type="submit">
                        <SquareButton
                            isLoading={isLoading}
                            sqicon={<span className="text-sm">Save Medicine Group</span>}
                        />
                    </div>
                </form>
            </div>
            <section
                id="medicine-section"
                className="rounded-sm border-2 pb-3 mt-2 overflow-y-scroll"
            >
                <div className="flex pl-2 text-white bg-gray-300 items-center sticky top-0">
                    <GiMedicines className="text-2xl p-0 m-0" />
                    <h1 className="pl-3 text-sm font-medium py-2 text-[#301762]">
                        Medicine Group({getAllMedicineGroup && getAllMedicineGroup.length})
                    </h1>
                </div>
                {/* //======================file display table section start============================= */}
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-9">
                        <tr>
                            <th scope="col" className="text-center px-2 py-2 font-medium">
                                S.No.
                            </th>
                            <th scope="col" className="text-center px-2 py-2 font-medium">
                                Medicine Group Name
                            </th>
                            <th scope="col" className="text-center px-2 py-2 font-medium">
                                Medicines
                            </th>
                            <th scope="col" className="text-center px-2 py-2 font-medium">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody className="capitalize text-xs">
                        {getAllMedicineGroup && getAllMedicineGroup?.map((item, idx) => (
                            <tr key={idx} className="border-t odd:bg-white even:bg-gray-50">
                                <td className="px-2 py-2 text-center font-medium">
                                    {idx + 1}
                                </td>
                                <td className="px-2 py-2 text-center">
                                    {item?.group_name}
                                </td>
                                <td className="px-2 py-2 text-center">{
                                    <ol>
                                        {item?.medicines?.map((ele, ind) => (
                                            <li key={ind}>{ele?.medicine_name?.medicine_name}</li>
                                        ))}
                                    </ol>
                                }</td>
                                <td className="px-2 py-2 text-center">
                                    <button
                                        onClick={() => {
                                            handleDelete(item._id);
                                        }}
                                        className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                                        title="Delete Medicine Group"
                                    >
                                        <RiDeleteBin6Line className="p-0 m-0 text-sm" />
                                    </button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                {/* //======================file display table section start============================= */}
            </section>
        </>
    );
};

export default MedicineGroup;
