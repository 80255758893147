import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const CheckBoxDropDown = ({ data, getData, isClear, alreadySelected, alreadyAdvice }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [Options, setOptions] = useState([])

    const handleChange = (selected) => {
        setSelectedOptions(selected)
        getData(selected)
    }

    useEffect(() => {
        setOptions(data)
    }, [data])

    useEffect(() => {
        if (isClear) {
            setSelectedOptions([]);
        }
    }, [isClear])

    useEffect(() => {
        if (alreadySelected) {
            let arr = alreadySelected && alreadySelected.map((item) => (
                { value: item._id, label: item.medicine_name }))
            setSelectedOptions(arr)
        }
        if (alreadyAdvice) {
            let arr = alreadyAdvice.length>0 && alreadyAdvice.split("_").map((item) => (
                { value: item, label: item }))
            setSelectedOptions(arr)
        }
    }, [alreadySelected, alreadyAdvice])

    return (
        <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={selectedOptions}
            isMulti
            options={Options}
            onChange={handleChange}
            className="text-sm"
        />
    );
};

export default CheckBoxDropDown;