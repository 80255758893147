// Import component
import * as COMPONENT_ROUTE from "../../routes/ComponentRoute"

const LoginPage = () => {
  return (
    <>
      <COMPONENT_ROUTE.LOGIN_FORM />
    </>
  );
};

export default LoginPage;
