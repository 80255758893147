import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelPatientAppointmentsAsync, selectPatientAppointment, updateAppointmentAsync, updateAppointmentFieldsAsync } from "../../redux/patientAppointments/patientSlice";
import { Link } from "react-router-dom";
import { DateFormat, GetTime, Prifix, TimeFormat } from "../../utils/helper";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ModalComponent from "../../components/modal/ModalComponent";
import { updateAppointmentFields } from "../../redux/patientAppointments/patientAppointmentAPI";

const Followup = () => {
  const dispatch = useDispatch();
  const [filteredPatient, setFilter] = useState([])
  const [editModelOpen, setEditModelOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [edit, setEdit] = useState({})
  const appointment = useSelector(selectPatientAppointment);
  const filteredAppointment = appointment && appointment.filter((item) =>
    (item.booked === true && item.isAppointmentDone === false && item.missed === true) && item)?.sort((a, b) => GetTime(a.date) - GetTime(b.date))

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const handleFilter = (data) => {
    let from = DateFormat(data.from)
    let filteredPatient = filteredAppointment && filteredAppointment.filter((patient) =>DateFormat(patient.date) === from && patient);
    if (filteredPatient.length === 0) {
      toast.error("No Appointments")
    }
    setFilter(filteredPatient)
  };

  function clearFilter() {
    setFilter([])
    reset()
  }

  // for delete appointment
  async function handleDelete(item) {
    const is = window.confirm("Are You Sure ?");
    try {
      if (is) {
        dispatch(cancelPatientAppointmentsAsync(item._id));
      }
    } catch (error) {
      console.log(error);
    }
  }

  function Reschedule(data) {
    setEdit(data)
    setEditModelOpen(true)
  }

  // update resechdule appointment
  async function handleEdit(e) {
    e.preventDefault()
    setIsLoading(true)
    let form = e.target
    let obj = {};
    new FormData(form).forEach((value, key) => {
      obj[key] = value;
    });
    let dt = new Date(obj.date)
    obj.time = TimeFormat(dt)
    obj.date = dt.getTime()
    obj.last_appointment = edit.last_appointment ? edit?.last_appointment + `_${DateFormat(edit?.date)}` : DateFormat(edit?.date);
    try {
      let response = await updateAppointmentFields({ id: edit?._id, obj })
      setIsLoading(false)
      if (response.status) {
        e.target.reset()
        setEdit({})
        setEditModelOpen(false)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
      dispatch(updateAppointmentFieldsAsync(response))
    } catch (error) {
      setIsLoading(false)
      console.log(error.message);
    }
  }

  // for booked done appointment

  async function handleChecked(item) {
    if (!item.isAppointmentDone) {
      const is = window.confirm("Are You Sure ?");
      if (is) {
        dispatch(updateAppointmentAsync(item._id));
      }
    }
  }

  return (
    <>
      <div className="mt-1 items-baseline w-full p-1 flex justify-between bg-gray-200 shadow border-b-[1px]">
        <h1 className="text-sm font-medium text-[#301762] mb-1 ml-3">
          Missed Appointments({filteredPatient?.length > 0 ? filteredPatient.length : filteredAppointment.length})
        </h1>
        <div className="flex gap-2">
          <form action="" className="flex gap-2" onSubmit={handleSubmit(handleFilter)}>
            <input
              className="p-2 rounded-sm text-xs"
              type="date"
              {...register("from")}
            />
            <SquareButton
              btnType={"submit"}
              sqicon={<span className="text-[13px]">Search</span>}
            />
          </form>
          <SquareButton
            handleClick={clearFilter}
            sqicon={<span className="text-[13px]">Reset</span>}
          />
        </div>
      </div>
      <div className="relative rounded-b-lg border-2 overflow-y-scroll">
        {appointment?.length ? (
          <table className="w-[100%]">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
              <tr className="text-center border-b-2">
                <th className="px-2 py-2 font-medium">
                  S.No.
                </th>
                <th className="px-2 py-2 font-medium">
                  Name
                </th>
                <th className="px-2 py-2 font-medium">
                  Mobile No.
                </th>
                <th className="px-2 py-2 font-medium">
                  File No.
                </th>
                <th className="px-2 py-2 font-medium">
                  Last Appointment Date
                </th>
                <th className="px-2 py-2 font-medium">
                  Appointment Date
                </th>
                <th className="px-2 py-2 font-medium">
                  Appointment Time
                </th>
                <th className="px-2 py-2 font-medium">
                  status
                </th>
                <th className="px-2 py-2 font-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {(filteredPatient?.length > 0 ? filteredPatient : filteredAppointment)?.map((item, index) => (
                <tr
                  className="text-center odd:bg-white even:bg-blue-50 text-gray-700 text-[13px] border-b-2"
                  key={index}
                >
                  <td
                    className="px-2 py-2 font-medium bg-gray-50 "
                  >
                    {index + 1}
                  </td>

                  <td className="px-2 py-2">
                    <Link
                      className="text-gray-700 hover:text-blue-700"
                      to={`/patient/patientdetails/${item?.patient_name?._id}`}
                    >
                      <h3 className={`px-2 py-2 ${item?.patient_name?.importance === "low" && "border-l-8 border-red-500"} ${item?.patient_name?.importance === "medium" && "border-l-8 border-green-500"} ${item?.patient_name?.importance === "high" && "border-l-8 border-yellow-500"}`}>{Prifix(item?.patient_name)} {item?.patient_name?.patient_name}&nbsp;{item?.patient_name?.surname} </h3>
                    </Link>
                  </td>
                  <td className="px-2 py-2">{item?.patient_name?.mobile}</td>
                  <td className="px-2 py-2">{item?.patient_name?.file_number}</td>
                  <td className="px-2 py-2">{item.last_appointment.split("_")?.map((ele, idex) => (
                    <li type="square" key={idex}>{ele}</li>
                  ))}</td>
                  <td className="px-2 py-2">{DateFormat(item?.date)}</td>
                  <td className="px-2 py-2">{item?.time}</td>
                  <td className="px-2 py-2">
                    <div className="grid gap-1">
                      <button
                        onClick={() => {
                          handleChecked(item);
                        }}
                        className="bg-green-600 hover:bg-green-400 hover:scale-90 duration-300 cursor-pointer text-white p-1 px-2 rounded-sm"
                        title="Appointent Done"
                      >
                        {item.isAppointmentDone ? "Checked" : "Done"}
                      </button>
                      {item.isAppointmentDone ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            handleDelete(item);
                          }}
                          className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-1 px-2 rounded-sm"
                          title="Cancel Appointment"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="px-2 py-2 flex justify-center items-center gap-1 pt-5">
                    <button
                      onClick={() => { Reschedule(item) }}
                      className="bg-yellow-400 hover:bg-yellow-600 text-[13px] hover:scale-90 duration-300 cursor-pointer text-white p-1 px-2 rounded-sm"
                      title="Re-schedule Appointment"
                    >
                      Re-schedule
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <img src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527139.jpg?w=740&t=st=1715321245~exp=1715321845~hmac=ac03b291ef85c469e2935df5bdc8e42a6af065a8259681c1f1033ecd4776deba" height="310px" width="310px" alt="noData" />

            <div className="flex flex-col items-center justify-center p-1 mb-12">
              <p className="text-3xl md:text-3xl lg:text-3xl text-gray-800 mt-1">
                Data Not Found!
              </p>
              <p className="md:text-lg lg:text-xl text-gray-600 pb-4 pt-2">
                Sorry, No Such Data Found
              </p>
              <Link className="p-2 border border-blue-900 rounded text-blue-900" to="/">Return To Home</Link>
            </div>
          </div>
        )}
      </div>
      <ModalComponent
        content={
          <form className="space-y-6  p-3  bg-slate-100" onSubmit={handleEdit}>
            <div className=" grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="last_appointment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last Appointment
                </label>
                <div className="mt-2">
                  <input
                    name="last_appointment"
                    type="text"
                    defaultValue={DateFormat(edit?.date)}
                    readOnly
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="contains"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Re-sechdule Date
                </label>
                <div className="mt-2">
                  <input
                    name="date"
                    type="datetime-local"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 flex gap-3">
              <SquareButton
                isLoading={isLoading}
                sqicon={
                  <span className="text-sm">
                    Save
                  </span>
                }
              />
            </div>
            <hr />
          </form>
        }
        open={editModelOpen}
        setOpen={setEditModelOpen}
      />
    </>
  );
};

export default Followup;

