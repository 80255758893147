import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteProcedure, getAllProcedure } from "./procedureApi";
import toast from "react-hot-toast";

const initialState = {
    procedure: [],
    status: "idle",
};
//=============GET MEDICINE THUNK================
export const getAllProcedureAsync = createAsyncThunk(
    "procedure/get_procedure)",
    async (id) => {
        const response = await getAllProcedure(id);
        if (response.status) {
            return response.data;
        } else {
            // toast.error(response.message);
            return false;
        }
    }
);

//===============DELETE MEDICINE API ====================
export const deleteProcedureAsync = createAsyncThunk(
    "procedure/delete_procedure",
    async (medicineId) => {
        let isDel = window.confirm("Are You Sure!");
        try {
            if (isDel) {
                const response = await deleteProcedure(medicineId);
                if (response.status) {
                    return response.data;
                } else {
                    toast.error(response.message);
                    return false;
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const procedureSlice = createSlice({
    name: "procedure",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProcedureAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAllProcedureAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.procedure = action.payload;
                } else {
                    state.procedure = []
                }
            })
            .addCase(deleteProcedureAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteProcedureAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const index = state.procedure.findIndex(
                        (procedure) => procedure._id === action.payload._id
                    );
                    state.procedure.splice(index, 1);
                    toast.success("treatment deleted successfully");
                }
            });
    },
});

export const selectProcedure = (state) => state.procedure.procedure;

export default procedureSlice.reducer;
