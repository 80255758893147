import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";
import { FaRegCircleUser } from "react-icons/fa6";
import { DateFormat, Prifix, TimeFormat } from "../../utils/helper";
import {
  bookPatientAppointmentAsync,
  cancelPatientAppointmentsAsync,
  selectPatientAppointment,
  selectedPatient,
  updateAppointmentAsync,
} from "../../redux/patientAppointments/patientSlice";
import { useForm } from "react-hook-form";
import { selectTreatment } from "../../redux/treatment/treatmentSlice";
import { Link, useParams } from "react-router-dom";
import SquareButton from "../Ui Components/SquareButton";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import { selectProcedure } from "../../redux/procedure/procedureSlice";
import { fetchCurrentPatientAppointment } from "../../redux/patientAppointments/patientAppointmentAPI";
import toast from "react-hot-toast";

export default function AllAppointments() {
  const { id } = useParams();
  const allAppointment = useSelector(selectPatientAppointment);
  const [appointment, setAppointment] = useState([]);
  const patientDetails = useSelector(selectedPatient);
  const allTreatment = useSelector(selectTreatment);
  const allProcedure = useSelector(selectProcedure);
  const dispatch = useDispatch();
  const [doctors, setDoctors] = useState();
  const [filterProcedure, setFilterProcedure] = useState([]);
  const [alreadyDone, setAlreadyDone] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [otherTeatmentTekon, setOtherTeatmentTekon] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    let dt = new Date(data.date)
    const Obj = {
      patient_name: id,
      date: dt.getTime(), // like 1789937839938
      doctor: data.doctor,
      treatment: data.treatment === "other" ? "" : data.treatment,
      other_treatment: data.other_treatment,
      treatment_phase: data.treatment_phase,
      time: TimeFormat(data.date) // like 5:30pm
    };

    try {
      dispatch(bookPatientAppointmentAsync(Obj));
      reset();
      setIsLoading(false);
      setOtherTeatmentTekon(false)
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  // for chnage treatment
  function changeTreatment(e) {
    const { value } = e.target;
    if (value !== "other") {
      setOtherTeatmentTekon(false)
      let isAlready =
        allProcedure &&
        allProcedure.filter((item) => item.procedure_name._id === value);
      setAlreadyDone(isAlready && isAlready);
      setFilterProcedure(
        allTreatment && allTreatment.filter((item) => item._id === value)
      );
    } else {
      setOtherTeatmentTekon(true)
    }
  }

  useEffect(() => {
    async function users() {
      try {
        let user = await axiosClient().get(webURLs.GET_DOCTORS);
        if (user.data.status) {
          setDoctors(user.data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    users();
  }, []);

  function getPhase(arr, id) {
    let nm = arr && arr.find((item) => item._id === id);
    return nm;
  }

  // for delete appointment

  async function handleDelete(item) {
    const is = window.confirm("Are You Sure ?");
    try {
      if (is) {
        dispatch(cancelPatientAppointmentsAsync(item._id));
      }
    } catch (error) {
      console.log(error);
    }
  }

  // for booked done appointment

  async function handleChecked(item) {
    if (!item.isAppointmentDone) {
      const is = window.confirm("Are You Sure ?");
      if (is) {
        dispatch(updateAppointmentAsync(item._id));
      }
    }
  }

  useEffect(() => {
    async function getSelectedAppointments() {
      const response = await fetchCurrentPatientAppointment(id);
      const data = response.data;
      setAppointment(data);
    }
    getSelectedAppointments();
  }, [allAppointment,id]);

  return (
    <>
      <div className="mb-2">
        <div className="flex justify-between w-full p-3">
          <div className="flex">
            <span>
              <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
            </span>
            <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
              Patient Name -
            </p>
            <p className="text-gray-600 text-sm pt-[5px] pl-2">
              {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
            </p>
          </div>
          <div className="flex mr-2">
            <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
              File Number -
            </p>
            <p className="text-gray-600 text-sm pt-[5px] pl-2">
              {patientDetails?.file_number}
            </p>
          </div>
        </div>
        <div className="bg-white rounded border-t-2 border-b-2">
          <div className="flex items-center bg-gray-300 rounded-t-sm justify-center space-x-2 text-[#301762] text-sm font-medium px-4 py-2">
            <span className="tracking-wide">Book Appointment</span>
          </div>
          <hr />
          <form className="py-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full m-4 px-4 flex flex-raw flex-wrap gap-8">
              <div>
                <label
                  htmlFor="treatment"
                  className="block text-sm font-medium text-gray-600"
                >
                  Treatment Taken
                </label>
                <div className="mt-2">
                  <select
                    id="treatment"
                    name="treatment"
                    {...register("treatment", {
                      required: "*treatment is required",
                    })}
                    onChange={changeTreatment}
                    autoComplete="treatment"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  >
                    <option value="">-Select-</option>
                    {allTreatment &&
                      allTreatment.map((item, indx) => (
                        <option value={item._id} key={indx}>
                          {item.treatment_name}
                        </option>
                      ))}
                    <option value="other">other</option>
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="treatment" />
                  </div>
                </div>
              </div>
              {otherTeatmentTekon ? <div>
                <label
                  htmlFor="treatment"
                  className="block text-sm font-medium text-gray-600"
                >
                  Other Treatment
                </label>
                <div className="mt-2">
                  <input
                    id="other_treatment"
                    name="other_treatment"
                    {...register("other_treatment", {
                      required: "*treatment is required",
                    })}
                    autoComplete="other_treatment"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="other_treatment" />
                  </div>
                </div>
              </div> : null}
              <div>
                <label
                  htmlFor="treatment_phase"
                  className="block text-sm font-medium text-gray-600"
                >
                  Procedure
                </label>
                <div className="mt-2">
                  <select
                    id="treatment_phase"
                    name="treatment_phase"
                    {...register("treatment_phase")}
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  >
                    <option value="">-Select-</option>
                    {filterProcedure &&
                      filterProcedure[0]?.phase
                        ?.filter(
                          (item, ind) =>
                            item._id !== alreadyDone[ind]?.procedure_phase
                        )
                        ?.map((ele, idx) => (
                          <option value={ele._id} key={idx}>
                            {ele.procedure_name}
                          </option>
                        ))}
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="treatment_phase" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="doctor"
                  className="block text-sm font-medium text-gray-600"
                >
                  Doctor
                </label>
                <div className="mt-2">
                  <select
                    id="doctor"
                    name="doctor"
                    {...register("doctor", {
                      required: "*Doctor is required",
                    })}
                    autoComplete="Select"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  >
                    <option value="">-Select-</option>
                    {doctors?.map((doctor, index) => (
                      <option key={index} value={doctor._id}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="doctor" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-600"
                >
                  Appointment Date And Time
                </label>
                <div className="mt-2">
                  <input
                    id="date"
                    {...register("date", {
                      required: "*date is required",
                    })}
                    name="date"
                    type="datetime-local"
                    autoComplete="date"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="date" />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-8">
              <SquareButton
                btnType={"submit"}
                isLoading={isLoading}
                sqicon={<span className="text-xs">Book Appointment</span>}
              />
            </div>
          </form>
        </div>
        <section
          id="medicine-section"
          className="bg-white rounded overflow-y-auto mb-2"
        >
          <div className="w-[100%] pl-2 bg-gray-300 rounded-t-sm text-white items-center">
            <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
              Total Appoinments
            </h1>
          </div>
          <hr />
          {/* //======================file display table section start============================= */}
          <table className="w-[100%] text-sm text-left text-gray-500 dark:text-gray-400 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr className="text-center">
                <th scope="col" className="px-2 py-2 font-medium">
                  Sr.No.
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Treatment Taken
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Procedure
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Send To
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Referred To
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Booked On
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Scheduled Time
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {appointment &&
                appointment.map((item, idx) => (
                  <tr className="text-center text-xs" key={idx}>
                    <td className="px-2 py-2 font-medium">
                      {idx + 1}
                    </td>
                    <td className="px-2 py-2">
                      {item.other_treatment ? item.other_treatment : item.treatment.treatment_name}
                    </td>
                    <td className="px-2 py-2">
                      {
                        getPhase(item?.treatment?.phase, item.treatment_phase)
                          ?.procedure_name
                      }
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex justify-center">
                        <SquareButton
                          sqicon={
                            <span className="text-xl" title="Send Prescription To Whatsapp">
                              <Link
                                to={`https://web.whatsapp.com/send?phone=+91${patientDetails?.mobile}&text=Hi%20${patientDetails?.patient_name}%20Your%20Appointment%20Has%20been%20Booked%20for%20${item?.treatment?.treatment_name}%20at%20${DateFormat(item?.date)}%20time:${item?.time}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                                target="_blank"
                                className="flex items-center gap-3 text-white hover:no-underline hover:text-black"
                              >
                                <FaWhatsapp />
                              </Link>
                            </span>
                          }
                          color={"#25D366"}
                        />
                      </div>
                    </td>
                    <td className="px-2 py-2">
                      {item.doctor?.name}
                    </td>
                    <td className="px-2 py-2">
                      <span>{DateFormat(item.createdAt)}</span>
                      <br />
                      <span>{TimeFormat(item.createdAt)}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{DateFormat(item.date)}</span>
                      <br />
                      <span>{item.time}</span>
                    </td>
                    <td className="text-center">
                      <div className="grid gap-2">
                        <button
                          onClick={() => {
                            handleChecked(item);
                          }}
                          className="bg-green-600 hover:bg-green-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                          title="Delete Prescription"
                        >
                          {item.isAppointmentDone ? "Checked" : "Done"}
                        </button>
                        {item.isAppointmentDone ? (
                          ""
                        ) : (
                          <button
                            onClick={() => {
                              handleDelete(item);
                            }}
                            className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                            title="Delete Prescription"
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* //======================file display table section start============================= */}
        </section>
      </div>
    </>
  );
}
