import { useEffect, useState } from "react";
// import { FaWhatsapp } from "react-icons/fa";
import SquareButton from "../Ui Components/SquareButton";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectedPatient } from "../../redux/patientAppointments/patientSlice";
import CheckBoxDropDown from "../dropdown/CheckBoxDropDown";
import { selectMedicine } from "../../redux/medicines/medicineSlice";
import { selectMedicineGroup } from "../../redux/medicineGroup/medicineGroupSlice";
import { useForm } from "react-hook-form";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import toast from "react-hot-toast";
// import { selectDiseasePrecation } from "../../redux/precautions/precautionSlice";
import GenerateMedicineInvoice from "../invoices/GenerateMedicineInvoice";
import adviceData from "../../data/adviceData";

const AddPrescription = () => {
  const { id } = useParams();
  const patientDetails = useSelector(selectedPatient);
  const allMedicines = useSelector(selectMedicine);
  const allMedicineGroup = useSelector(selectMedicineGroup);
  const [medicine, setMedicine] = useState([]);
  const [advice, setAdvice] = useState('');
  const [otherAdvice, setOtherAdvice] = useState('');
  const [groupId, setGroupId] = useState("");
  const [GroupMedicine, setGroupMedicine] = useState([]);
  const [mainMedicine, setMainMedicine] = useState([])
  const [extraMedicine, setExtraMedicine] = useState([]);
  const [medicineData, setMedicineData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isClear, setIsClear] = useState(false);
  const [isClearOtherMedicine, setIsClearOtherMedicine] = useState(false);
  const [cheifComplaint, setCheifComplaint] = useState("");
  const [checkbox2AllMedicine, setCheckbox2AllMedicine] = useState([])
  const [cheifComplaintErr, setCheifComplaintErr] = useState("");
  const [note, setNote] = useState([]);

  const {
    // register,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm();

  // for additional medicines
  function handleSelectedMedicine(data) {
    let commonMedicine = [];
    allMedicines?.forEach((element) => {
      if (data.filter((item) => item.value === element._id).length > 0) {
        commonMedicine.push(element);
      }
    });
    setExtraMedicine(commonMedicine);
    let mediData = data.map((item) => ({
      medicine_name: item.value,
    }));
    setMedicine(mediData)
  }

  // for Add Some other medicines
  function handleSelectedOtherMedicines(data) {
    setFormError("")
    if (groupId) {
      toast.error("Please Remove Selected Medicine Group")
      setIsClearOtherMedicine(data)
      return;
    }
    let commonMedicine = [];
    allMedicines?.forEach((element) => {
      if (data?.filter((item) => item.value === element._id).length > 0) {
        commonMedicine.push(element);
      }
    });
    setGroupMedicine(commonMedicine)
    let mediData = data.map((item) => ({
      medicine_name: item.value,
    }));
    setMainMedicine(mediData)
  }

  // for select other Advice
  function handleSelectedAdvice(data) {
    const arr = data.map(item => (`${item.label}`))
    setAdvice(arr.join("_"));
  }

  // for select medicine group
  function handleSelectGroup(e) {
    setFormError("")
    let id = e.target.value
    setGroupId(id)
    setIsClearOtherMedicine(id)
    let filterGroup = allMedicineGroup?.filter((item) => item._id === id);
    if (filterGroup.length > 0) {
      let gm = filterGroup[0]?.medicines?.map((item) => (item?.medicine_name))
      setGroupMedicine(gm);
    } else {
      setGroupMedicine([]);
    }
  }

  async function savePriscription(data) {

    if (!cheifComplaint) {
      setCheifComplaintErr("Cheif Complaint Is Require...");
      return;
    }
    if (!GroupMedicine.length > 0) {
      if (!medicine.length > 0) {
        setFormError("*Select Medicine Group Or Medicine");
        return;
      }
    }
    setIsLoading(true);
    toast.loading("loading...");
    try {
      const input = document.getElementById("pdf");
      let stringFile = await html2canvas(input, { scale: 3 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        // Function to add a page with background color
        const addPageWithBackground = (pdf) => {
          pdf.addPage();
          pdf.setFillColor(248, 230, 206); // Example: light red background
          pdf.rect(
            0,
            0,
            pdf.internal.pageSize.getWidth(),
            pdf.internal.pageSize.getHeight(),
            "F"
          );
          pdf.setFontSize(9);
        };

        // Add background to the first page
        pdf.setFillColor(248, 230, 206); // Example: light red background
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        pdf.setFontSize(9);
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          addPageWithBackground(pdf);
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        const pdfDataUri = pdf.output("blob");
        let blob = new Blob([pdfDataUri], { type: "application/pdf" });
        return blob;
      });

      if (stringFile) {
        let formData = new FormData();
        formData.append("patient_id", id);
        formData.append("cheif_complaint", cheifComplaint.trim());
        formData.append("note", note);
        formData.append("prescription", JSON.stringify(medicine));
        formData.append("main_medicine", JSON.stringify(mainMedicine));
        formData.append("madicine_group", groupId);
        formData.append("additional_advice", advice);
        formData.append("pdf_file", stringFile, "invoice.pdf");

        let response = await axiosClient().post(
          webURLs.ADD_PRESCRIPTION,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status) {
          toast.dismiss();
          reset();
          setGroupId("");
          setIsClear(response.data.status);
          setIsClearOtherMedicine(response.data.status);
          setExtraMedicine([]);
          toast.success(response.data.message);
          setIsLoading(false);
        } else {
          toast.dismiss();
          toast.error(response.data.message);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // for filter all medicine not selected in group
  useEffect(() => {
    let commonMedicine = [];
    allMedicines?.forEach((element) => {
      if (GroupMedicine.filter((item) => item._id === element._id).length === 0) {
        commonMedicine.push(element);
      }
    });
    let medi = commonMedicine && commonMedicine.map((item) => ({
      value: item._id,
      label: item.medicine_name
    }));
    // for additional medicine
    setMedicineData(medi);
  }, [GroupMedicine,allMedicines]);

  // for checkbox 2 add some other medicine
  useEffect(() => {
    let medicineData = allMedicines && allMedicines.map((item) => ({
      value: item._id,
      label: item.medicine_name
    }));
    setCheckbox2AllMedicine(medicineData)
  }, [allMedicines])

  // for not select medicine group 
  useEffect(() => {
    setFormError("");
  }, [groupId]);

  return (
    <>
      <div className="w-[94%] h-54 mt-4 mx-8 rounded-xl border-2 mb-4">
        <div className="flex items-center space-x-2 justify-center text-[#301762] text-sm px-4 py-2 bg-gray-100">
          <span className="tracking-wide font-medium">Prescription Form</span>
        </div>
        <hr />
        <form onSubmit={handleSubmit(savePriscription)}>
          <div className="flex flex-col p-4">
            <label className="text-sm text-gray-600 font-medium">Cheif complaints</label>
            <input
              className="text-sm rounded-md bg-slate-100 py-1.5 px-2 text-gray-600 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              name="cheif"
              id=""
              cols="50"
              rows="1"
              onChange={(e) => {
                setCheifComplaint(e.target.value);
                setCheifComplaintErr("");
              }}
              required
            />
            <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
              <p>{cheifComplaintErr}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col p-4 w-full">
              <label className="text-sm text-gray-600 font-medium">Clinical Advice Note</label>
              <input
                className="text-sm rounded-md bg-slate-100 py-1.5 px-2 text-gray-600 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
                name="note"
                id=""
                type="text"
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col p-4 w-[50%]">
              <label className="text-sm text-gray-600 font-medium">Medicines Groups</label>
              <select
                name="madicine_group"
                onChange={handleSelectGroup}
                className="text-sm border rounded-md h-10 py-1.5 px-2 text-gray-600 focus:ring-1 focus:ring-inset focus:ring-[#301762] sm:text-sm"
              >
                <option value="">-Select-</option>
                {allMedicineGroup &&
                  allMedicineGroup.map((item, idx) => (
                    <option value={item._id} key={idx}>
                      {item.group_name}
                    </option>
                  ))}
              </select>
              <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                <label>{formError}</label>
              </div>
            </div>
            <div className="flex flex-col p-4 w-[50%]">
              <label className="text-sm text-gray-600 font-medium">Additional Medicines</label>
              <CheckBoxDropDown
                data={medicineData}
                getData={handleSelectedMedicine}
                isClear={isClear}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col p-4 w-[50%]">
              <label className="text-sm text-gray-600 font-medium">Add Some Other Medicines</label>
              <CheckBoxDropDown
                data={checkbox2AllMedicine}
                getData={handleSelectedOtherMedicines}
                isClear={isClearOtherMedicine}
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                <label>{formError}</label>
              </div>
            </div>
            <div className="flex flex-col p-4 w-[50%]">
              <label className="text-sm text-gray-600 font-medium">Other Advice</label>
              <CheckBoxDropDown
                data={adviceData}
                getData={handleSelectedAdvice}
                isClear={isClear}
              />
            </div>
            <div className="flex flex-col p-4 w-[50%]">
              <label className="text-sm text-gray-600 font-medium">Add Some Other Advice</label>
              <input
                className="text-sm rounded-md bg-slate-100 py-1.5 px-2 text-gray-600 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
                name="advice"
                id=""
                type="text"
                onChange={(e) => { setOtherAdvice(e.target.value) }}
              />
            </div>
          </div>
          <div className="relative">
            <div className="h-96 overflow-y-auto">
              <GenerateMedicineInvoice
                medicines={GroupMedicine}
                advice={advice}
                otherAdvice={otherAdvice}
                extraMedicine={extraMedicine}
                patientDetails={patientDetails}
                cheifComplaint={cheifComplaint}
                note={note}
              />
            </div>
            <div className="flex w-full ml-5 gap-3 mt-4 mb-3">
              <SquareButton
                isLoading={isLoading}
                sqicon={<span className="text-xs">Add Prescription</span>}
              />
            </div>
          </div>
        </form>
      </div>
      {/* ----------- */}
    </>
  );
};

export default AddPrescription;
