import React, { useEffect, useState } from "react";
import { FaAngleRight, FaAngleLeft, FaAngleDown } from "react-icons/fa";
import "./DateSlotTab.css";
import moment from "moment";
import NewPatientAppointmentForm from "../forms/NewPatientAppointmentForm";
import ModalComponent from "../modal/ModalComponent";
import TimeSlotComponent from "./TimeSlotComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientAppointment,
} from "../../redux/patientAppointments/patientSlice";
import {
  selectEveningSlots,
  selectMorningSlots,
} from "../../redux/bookingSlot/bookingSlotSlice";

//--------------slotdividing function start ------------------
// function divideIntervals(startTime, endTime, duration) {
//   let intervals = [];
//   let currentStartTime = new Date(startTime);
//   while (currentStartTime < endTime) {
//     const currentEndTime = new Date(currentStartTime.getTime() + duration);
//     intervals.push({
//       startTime: moment(currentStartTime).format("hh:mm A"),
//       endTime: moment(currentEndTime).format("hh:mm A"),
//       booked: false,
//     });
//     currentStartTime = currentEndTime;
//   }
//   return intervals;
// }

//--------------slotdividing function end -----------------

// ---------------slot scheduling calendar function start ------------------------
function getDatesWithDayNames(year, month, startDate) {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const datesArray = [];

  // console.log(todaysDate)

  for (let day = startDate; day <= daysInMonth; day++) {
    const date = new Date(year, month, day);
    const dateOnly = moment(date).format("Do");
    const dayOnly = moment(date).format("dddd");
    datesArray.push({ date: dateOnly, dayName: dayOnly, ActualDate: date });
  }

  return datesArray;
}
//------------------ slot scheduling calendar function end ------------------------

//------------Month name for ui display using index no -------------------
const monthsName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
//------------Month name for ui display using index no -------------------

//------------------Main Component start---------------------------
const DateSlotTab = () => {
  // const dispatch = useDispatch();
  const morningSlots = useSelector(selectMorningSlots);
  const eveningSlots = useSelector(selectEveningSlots);
  // const [appointment, setAppointment] = useState([]);
  const bookedAppointment = useSelector(selectPatientAppointment);

  //------------slot opening and modal opening states start--------------------
  const [open, setOpen] = useState(false);
  const [activeSlot, setActiveSlot] = useState("");
  const [isMorning, setisMorning] = useState(false);
  //------------slot opening and modal opening states start--------------------

  //-------------default values for calendar ------------------
  const CurrentDate = Date.now(); //(Bringing todays date)
  const currentDate = moment(CurrentDate).format("Do"); //(fomating today date (eg:- if date is 14 than formated date 14th))
  const currentMonth = new Date().getUTCMonth(); //--(Bringing current month (gives number 0,1,2 etc using it as index to get month name from monthName)
  const todaysDate = new Date().getUTCDate();
  //-------------default values for calendar ------------------

  //-------------states for calendar start----------------
  const [calendarStarting, setCalendarStarting] = useState(todaysDate);
  const [year, setYear] = useState(2024);
  const [month, setMonth] = useState(currentMonth);
  const datesWithDayName = getDatesWithDayNames(2024, month, calendarStarting);
  const totalDatesInMonth = new Date(year, 2 + 1, 0).getDate();
  const [startdate, setStartDate] = useState(0);
  const paginatedDates = datesWithDayName.slice(startdate, startdate + 7);
  const [selectedDate, setSelectedDate] = useState(0);
  const [openMonthSelector, setOpenMonthSelector] = useState(false);
  //--------------state for calendar end --------------------

  //-----------------calendar handle functions start--------------------------
  const nextDateHandler = () => {
    if (selectedDate < paginatedDates.length) {
      setSelectedDate(selectedDate + 1);
    }
    if (selectedDate >= paginatedDates.length - 1) {
      setSelectedDate(0);
      setStartDate(startdate + 7);
    }
    if (
      datesWithDayName[datesWithDayName.length - 1]?.date ===
      paginatedDates[selectedDate]?.date
    ) {
      setMonth(month + 1);
      setCalendarStarting(1);
      setStartDate(0);
      setSelectedDate(0);
    }
    if (
      month === 11 &&
      datesWithDayName[datesWithDayName.length - 1]?.date ===
      paginatedDates[selectedDate]?.date
    ) {
      setYear(year + 1);
      setMonth(0);
      setSelectedDate(0);
    }
  };

  const prevDateHandler = () => {
    //selection indent contidions (active day) start

    if (
      selectedDate === 0 &&
      `${paginatedDates[selectedDate]?.date} ${monthsName[month]}` !==
      `${currentDate} ${monthsName[currentMonth]}`
    ) {
      setSelectedDate(0);
      setStartDate(startdate - 1);
    }
    if (selectedDate > 0) {
      setSelectedDate(selectedDate - 1);
    }
    if (selectedDate === 0 && paginatedDates[selectedDate]?.date === "1st") {
      setMonth(month - 1);
      setCalendarStarting(1);
      setStartDate(totalDatesInMonth - 7);
      setSelectedDate(5);
    }

    if (
      selectedDate === 1 &&
      startdate === 0 &&
      paginatedDates[selectedDate]?.date === `1st`
    ) {
      setMonth(month - 1);
      setStartDate(1);
    }
  };
  //-----------------calendar handle functions end --------------------------

  //----------------- formating date for input field i.e yyyy-MM-DD (e.g:- 2024-03-10)) --------
  let appointmentsDate = moment(`${paginatedDates[selectedDate]?.ActualDate}`, ["YYYY-MM-DD"])
  //-------end---------

  let bookingDay = paginatedDates[selectedDate].dayName; //api calling
  let bookingDate = moment(`${paginatedDates[selectedDate]?.ActualDate}`, ["YYYY-MM-DD"])
 
  // useEffect(() => {
    // dispatch(fetchMorningSlotsAsync("Monday"))
    // dispatch(fetchEveningSlotsAsync("Monday"))

  // }, [bookingDay])


  return (
    <div className="border-2 rounded-lg h-[270px]">
      <div className="bg-white">
        <p
          className="m-auto text-center cursor-pointer text-gray-700 font-medium py-2 text-sm"
          onClick={() => {
            !openMonthSelector
              ? setOpenMonthSelector(true)
              : setOpenMonthSelector(false);
          }}
        >
          {monthsName[month]}
        </p>
        <FaAngleDown
          className={openMonthSelector ? "text-lg m-auto" : "hidden"}
        />
        <div className={openMonthSelector ? null : "hidden"}>
          <ul className="grid grid-cols-6 bg-white text-black  gap-2 items-center justify-items-center p-2 ">
            {monthsName.map((month, index) => (
              <li
                key={index}
                className="text-xs font-medium text-gray-600 capitalize hover:text-blue-700 cursor-pointer hover:scale-105"
                onClick={() => {
                  setMonth(index);
                  if (currentMonth != month) {
                    setCalendarStarting(1);
                    setStartDate(0);
                  }
                  setOpenMonthSelector(false);
                }}
              >
                {month}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="slot-date-container relative bg-blue text-white flex z-20 items-center w-full gap-5">
        <div
          className="hover:text-[dodgerblue] cursor-pointer"
          onClick={prevDateHandler}
        >
          <FaAngleLeft className="text-xl" />
        </div>
        <div className="flex justify-around items-center py-3 w-full">
          {paginatedDates.map((date, index) => (
            <div
              key={index}
              className={
                selectedDate === index
                  ? "slot-date slot-date-active cursor-pointer px-2 capitalize flex gap-1 flex-row-reverse"
                  : "slot-date cursor-pointer px-2 capitalize flex gap-1 flex-row-reverse"
              }
              onClick={(e) => {
                setSelectedDate(index);
                // console.log(e.target.innerText + " " + monthsName[month]);
              }}
            >
              {date.date} {date.dayName}
            </div>
          ))}
        </div>
        <div
          className="hover:text-[dodgerblue] cursor-pointer"
          onClick={nextDateHandler}
        >
          <FaAngleRight className="text-xl text-[inherit]" />
        </div>
      </div>

      {/* slot booking section  start*/}
      <div className="flex w-full py-1 px-4 bg-white">
        <div id="morning-slot-timming-tab" className="w-1/2 px-3">
          <h1 className="bg-gray-300 rounded-t-2xl text-center text-md py-1 font-medium text-blue">
            Morning
          </h1>
          <div className="grid grid-cols-5 py-2 ">
            {morningSlots.map((slot, index) => (
              <div
                key={index}
                onClick={(e) => {
                  setOpen(true);
                  setisMorning(true);
                  setActiveSlot(slot.startTime);
                  // console.log(activeSlot);
                }}
              >
                <TimeSlotComponent
                  time={slot.startTime}
                  date={bookingDate}
                  appointments={bookedAppointment}
                />
              </div>
            ))}
            {isMorning ? (
              <ModalComponent
                content={
                  <NewPatientAppointmentForm
                    time={activeSlot}
                    date={appointmentsDate}
                  />
                }
                open={open}
                setOpen={setOpen}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div id="morning-slot-timming-tab" className="w-1/2">
          <h1 className="bg-gray-300 text-center rounded-t-2xl text-md py-1 font-medium text-blue">
            Evening
          </h1>
          <div className="grid grid-cols-6 py-2">
            <>
              {eveningSlots.map((slot, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    setOpen(true);
                    setisMorning(true);
                    setActiveSlot(slot.startTime);
                    // console.log(activeSlot);
                  }}
                >
                  <TimeSlotComponent
                    time={slot.startTime}
                    date={bookingDate}
                    appointments={bookedAppointment}
                  />
                </div>
              ))}
              {!isMorning ? (
                <ModalComponent
                  content={
                    <NewPatientAppointmentForm
                      time={activeSlot}
                      date={appointmentsDate}
                    />
                  }
                  open={open}
                  time={activeSlot}
                  setOpen={setOpen}
                />
              ) : (
                ""
              )}
            </>
          </div>
        </div>
      </div>
      {/* slot booking section end*/}
    </div>
  );
};

export default DateSlotTab;
