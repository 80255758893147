import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import { chnagePassApi, sendOtp, verifyOtp } from "../../redux/user/userAPI";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ForgotPassForm = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [otpUiSetup, setOtpUiSetup] = useState({
        emailForm: true,
        otpForm: false,
        changePassUi: false
    })

    const { register,
        formState: { errors },
        handleSubmit, reset } = useForm()

    const sendOtpFn = async (data) => {
        setIsLoading(true);
        try {
            let res = await sendOtp(data);

            if (res.status) {
                toast.success(res.message)
                setIsLoading(false);
                window.sessionStorage.setItem("sbldcocp", res.data.otp)
                setOtpUiSetup({ ...otpUiSetup, emailForm: false, otpForm: true })
                reset()
            } else {
                toast.error(res.message)
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    const verifyOtpFn = async (data) => {
        setIsLoading(true);
        try {
            let res = await verifyOtp(data);

            if (res.status) {
                toast.success(res.message)
                setIsLoading(false);
                window.sessionStorage.clear()
                window.sessionStorage.setItem("sbldcvocp", res.data.token)
                setOtpUiSetup({ ...otpUiSetup, otpForm: false, changePassUi: true })
            } else {
                toast.error(res.message)
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }

    async function changePassFn(data) {
        setIsLoading(true);

        if(data.password !== data.confirm_password){
            setIsLoading(false);
           return toast.error("Password does Not Match !")
        }

        try {
            let res = await chnagePassApi(data);

            if (res.status) {
                toast.success(res.message)
                setIsLoading(false);
                window.sessionStorage.clear()
                setOtpUiSetup({ ...otpUiSetup, emailForm: true, changePassUi: false })
            } else {
                toast.error(res.message)
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }

    useEffect(() => {
        return () => {
            setOtpUiSetup({
                emailForm: true,
                otpForm: false,
                changePassUi: false
            })
        }
    }, [])

    return <>
        <div>
            <div id="header-top" className="flex justify-between items-center px-10 py-2">
                <img src="/asset/images/LOGO.png" alt="logo" className="lg:h-[120px] sm:h-[120px] w-64 sm:w-64 lg:w-auto md:w-64" />
                <img src={`/asset/images/balaji.png`} className="aspect-auto h-[120px]" alt="logo-side" />
            </div>
        </div>
        <div className="bg-[#301762] p-1 w-full"></div>
        <div id="form-container">
            <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
                <div id="form-sidebox" className="sm:p-6 p-6 sm:rounded-none rounded-se-3xl">

                </div>
                <div
                    id="form-box"
                    className="doctor-login-form h-auto bg-white m-auto py-3 px-6 rounded-ee-3xl sm:rounded-e-3xl">
                    <img
                        src={`/asset/icons/Blue Matte Simple Dentist Logo.png`}
                        alt="reception"
                        className="w-14 mx-auto h-auto"
                    />
                    <h1 className="text-center font-bold text-[#301762] font-serif text-3xl ">
                        Hello User!
                    </h1>
                    <h6 className="text-center">Let's help you reset your Password</h6>
                    {otpUiSetup.otpForm ? <div>
                        <form
                            id="login-form"
                            className="my-4 sm:px-0 flex justify-center lg:px-12 flex-col"
                            onSubmit={handleSubmit(verifyOtpFn)}
                        >
                            <div className="flex flex-col gap-3">
                                {/* otp */}
                                <div className="input-container flex items-center gap-2 p-2">
                                    <FaLock />
                                    <input
                                        className=" focus:outline-none outline-none w-full "
                                        type="tel"
                                        id="otp"
                                        placeholder="Enter OTP"
                                        {...register("otp", {
                                            required: "Please Enter OTP !",
                                            pattern: {
                                                value: /^\d{6}$/,
                                                message: "Enter six Digits Otp And Use Only Number !",
                                            },
                                        })}
                                    />
                                </div>
                                {errors.otp && <p role="alert" className="p-0 text-red-600 text-sm m-0">{errors?.otp?.message}</p>}
                            </div>
                            <SquareButton
                                btnType={"submit"}
                                sqicon={<span className="text-sm w-full">Verify Otp</span>}
                                isLoading={isLoading}
                                className={"mt-4 bg-[#301762]"}
                            />
                        </form>
                    </div> : null}
                    {otpUiSetup.emailForm ? <div>
                        <form
                            id="login-form"
                            className="my-4 sm:px-0 flex justify-center lg:px-12 flex-col"
                            onSubmit={handleSubmit(sendOtpFn)}
                        >
                            <div className="flex flex-col gap-3">
                                <div className="input-container  flex items-center gap-2 py-2 px-2">
                                    <MdEmail />
                                    <input
                                        className=" focus:outline-none outline-none w-full "
                                        type="email"
                                        id="email"
                                        autoComplete="false"
                                        placeholder="Enter your email"
                                        {...register("email", { required: "email required !" })}
                                    />
                                </div>
                                {errors.email && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.email?.message}</p>}
                            </div>
                            <SquareButton
                                btnType={"submit"}
                                sqicon={<span className="text-sm w-full">Send Otp</span>}
                                isLoading={isLoading}
                                className={"mt-4 bg-[#301762]"}
                            />
                            <p className="mt-3"><strong>Note : </strong>OTP Will be Send To Your Registered Email Id</p>
                        </form>
                    </div> : null}
                    {otpUiSetup.changePassUi ? <div>
                        <form
                            id="login-form"
                            className="my-4 sm:px-0 flex justify-center lg:px-12 flex-col"
                            onSubmit={handleSubmit(changePassFn)}
                        >
                            <div className="flex flex-col gap-3">
                                <div className="input-container flex items-center gap-2 py-2 px-2">
                                    <input
                                        className=" focus:outline-none outline-none w-full "
                                        type="text"
                                        id="password"
                                        autoComplete="false"
                                        placeholder="Enter New Password"
                                        {...register("password", {
                                            required: "password required !",
                                            pattern: {
                                                value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/,
                                                message: "Use Strong Password ! (Like xyz@123A)",
                                            },
                                        })}
                                    />
                                </div>
                                {errors.password && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.password?.message}</p>}
                                <div className="input-container  flex items-center gap-2 py-2 px-2">
                                    <input
                                        className="focus:outline-none outline-none w-full"
                                        type="password"
                                        autoComplete="false"
                                        placeholder="Re Enter New Password"
                                        {...register("confirm_password", { required: "confirm-password required !" })}
                                    />
                                </div>
                                {errors.confirm_password && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.confirm_password?.message}</p>}
                            </div>
                            <SquareButton
                                btnType={"submit"}
                                sqicon={<span className="text-sm w-full">Change Password</span>}
                                isLoading={isLoading}
                                className={"mt-7 bg-[#301762]"}
                            />
                        </form>
                    </div> : null}
                    <div className="flex justify-end pr-12 pb-3">
                        <button className="text-blue-800 font-semibold underline" onClick={() => { navigate(-1) }}>Back</button>
                    </div>
                </div>
            </div>
        </div>
        <footer className="bg-[#301762] py-4 flex justify-center relative bottom-0 z-auto">
            <div className="flex flex-col justify-center items-center">
                <h6 className="font-center text-white font-medium">
                    ©All copy rights reserved by Shree Balaji Dental Clinic
                </h6>
                <h6 className="font-center text-white font-semibold">2023-2025</h6>
            </div>
        </footer>
    </>

}

export default ForgotPassForm;