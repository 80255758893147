import { Fragment, useRef } from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";


export default function ReportGenerate({ open, setOpen }) {
  //   const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-1000"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg  bg-slate-100 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  {/* <div className="sm:flex sm:items-start"> */}

                  <div className="  bg-slate-100  flex min-h-full flex-1 flex-col justify-center px-6 py-4 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                      <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Patient Report
                      </h2>
                    </div>

                    <div className="mt-6 sm:mx-auto  sm:grid sm:grid:cols-2 sm:w-full sm:max-w-sm">
                      <form className=" w-fit " action="#" method="POST">
                        <div>
                          <div className=" grid grid-cols-3 gap-4">
                            <div>
                              <label
                                htmlFor="filenumber"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                File Number
                              </label>
                              <div className="mt-2">
                                <input
                                  id="filenumber"
                                  name="filenumber"
                                  type="text"
                                  autoComplete="filenumber"
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="cliniclocation"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Clinic Location
                              </label>
                              <div className="mt-2">
                                <select
                                  id="cliniclocation"
                                  name="cliniclocation"
                                  // type="text"
                                  autoComplete="Select"
                                  required
                                  className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="select">Select</option>
                                  <option value="indore">Indore</option>
                                  <option value="bhopal">Bhopal</option>
                                </select>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="group"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Group
                              </label>
                              <div className="mt-2">
                                <select
                                  id="group"
                                  name="group"
                                  // type="text"
                                  autoComplete="Select"
                                  required
                                  className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="select">Select</option>
                                  <option value="A">A</option>
                                  <option value="B">B</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className=" grid grid-cols-2 mt-2 gap-4">
                            <div>
                              <label
                                htmlFor="notes"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Notes
                              </label>
                              <div className="mt-2">
                                <input
                                  id="notes"
                                  name="notes"
                                  type="text"
                                  maxLength="2"
                                  autoComplete="notes"
                                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="referredby"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Referred By
                              </label>
                              <div className="mt-2">
                                <input
                                  id="referredby"
                                  name="referredby"
                                  type="name"
                                  autoComplete="name"
                                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <h1 className="mt-3 mb-1">Medical Conditions</h1>
                            <hr />

                            <div className="mt-3 grid grid-cols-3 gap-2">
                              <div className="flex flex-row items-center">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  defaultValue=""
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="link-checkbox"
                                  className="ms-2 text-sm font-medium text-green-900 dark:text-black-300"
                                >
                                  {" "}
                                  Blood Pressure
                                </label>
                              </div>

                              <div className="flex flex-row items-center">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  defaultValue=""
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="link-checkbox"
                                  className="ms-2 text-sm font-medium text-green-900 dark:text-black-300"
                                >
                                  {" "}
                                  Blood Pressure
                                </label>
                              </div>

                              <div className="flex flex-row items-center">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  defaultValue=""
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="link-checkbox"
                                  className="ms-2 text-sm font-medium text-green-900 dark:text-black-300"
                                >
                                  {" "}
                                  Blood Pressure
                                </label>
                              </div>

                              <div className="flex flex-row items-center">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  defaultValue=""
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="link-checkbox"
                                  className="ms-2 text-sm font-medium text-green-900 dark:text-black-300"
                                >
                                  {" "}
                                  Blood Pressure
                                </label>
                              </div>
                              <div className="flex flex-row items-center">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  defaultValue=""
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="link-checkbox"
                                  className="ms-2 text-sm font-medium text-green-900 dark:text-black-300"
                                >
                                  {" "}
                                  Blood Pressure
                                </label>
                              </div>
                              <div className="flex flex-row items-center">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  defaultValue=""
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="link-checkbox"
                                  className="ms-2 text-sm font-medium text-green-900 dark:text-black-300"
                                >
                                  {" "}
                                  Blood Pressure
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
