import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addPrescription, getPrescriptions } from "./prescriptionAPI";
import toast from "react-hot-toast";


const initialState = {
    prescription: [],
    status: "idle"
};

//{ ===============PRESCRIPTION THUNKS START================== }
//=============add new Prescription =====================
export const addNewPrescriptionAsync = createAsyncThunk(
    "prescription/Add_New_Prescription",
    async (prescriptionData) => {
        const response = await addPrescription(prescriptionData);
        if (response.status) {
            return response.data
        } else {
            toast.error(response.message);
            return false
        }
    }
);

//============= get Prescription =====================
export const getPrescriptionsAsync = createAsyncThunk(
    "prescription/get_Prescriptions",
    async (id) => {
        const response = await getPrescriptions(id);
        if (response.status) {
            return response.data
        } else {
            toast.error(response.message);
            return false
        }
    }
);

//{ ===============PRESCRIPTION THUNKS END================== }

export const prescriptionSlice = createSlice({
    name: "prescription",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(getPrescriptionsAsync.pending, (state) => {
                state.status = "loading"
            })
            .addCase(getPrescriptionsAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.prescription = action.payload;
                }
            })
            .addCase(addNewPrescriptionAsync.pending, (state) => {
                state.status = "loading"
            })
            .addCase(addNewPrescriptionAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.prescription.push(action.payload);
                    toast.success("Prescription add Successfully")
                }
            })
    }
})

export const getAllPrescription = (state) => state.prescription.prescription

export default prescriptionSlice.reducer;