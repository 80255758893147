import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectAllPatient, selectPatientAppointment } from "../../redux/patientAppointments/patientSlice";
import FollowUpAppointment from "../forms/FollowUpAppointment";

const FollowUpTable = () => {
  const allPatient = useSelector(selectAllPatient);
  const appointments= useSelector(selectPatientAppointment);
  const [followUpPatient,setFollowUpPatient] = useState([]) 
  const [open, setOpen] = useState(false);
  
  const extractLastAppointmentDate =(patientFile)=>{
    const appointmentDate = appointments.filter((patient)=>(patient.file_number === patientFile))
  }
 
  return (
    <>
      <div className="relative shadow-md sm:rounded-lg order-solid border-2 border-[#9F78FF] p-1">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-gray-800">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.No.
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Mobile No.
              </th>
              <th scope="col" className="px-6 py-3">
                File No.
              </th>
              <th scope="col" className="px-6 py-3">
                Last Appointment Date
              </th>
              <th scope="col" className="px-6 py-3">
                Follow Up Date
              </th>
              <th scope="col" className="px-6 py-3">
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {allPatient.filter((patient)=>(patient.followUpDate)).map((patient,index) => (
              <tr className="odd:bg-white even:bg-gray-50 text-gray-900">
                <th scope="row" className="px-6 py-4 font-medium bg-gray-50 ">
                  {index+1}
                </th>
                <td className="px-6 py-4">{patient.patient_name}</td>
                <td className="px-6 py-4">{patient.mobile}</td>
                <td className="px-6 py-4">{patient.file_number}</td>
                <td className="px-6 py-4">{extractLastAppointmentDate(patient.file_number)}</td>
                <td className="px-6 py-4">{patient.followUpDate}</td>
                <button
                  onClick={() => {setFollowUpPatient(patient);setOpen(true)}}
                  className="bg-purple-500 text-white py-1 px-2 my-4 rounded-lg"
                >
                  Follow Up
                </button>
                {/* <td className="px-6 py-4 text-2xl"><TiTick /></td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {open && (
        <div className="fixed h-full w-[85vw] flex justify-center items-center top-0 modal_style">
          <div
            onClick={() => setOpen(false)}
            className="fixed h-full w-[85vw]  z-0"
          >
            .
          </div>
          <div className="z-10">
            <FollowUpAppointment patientDetails={followUpPatient} openStatus={open} getOpen={(value)=>setOpen(value)} />
          </div>
        </div>
      )}
    </>
  );
};

export default FollowUpTable;
