import React from 'react';
import "./SquareButton.css";


export default function SquareButton({ sqicon, btnType, handleClick, color, isLoading, className, disable }) {
  return (
    <>
      {isLoading ? <div className='flex justify-center'>
        <div className='loader ml-6 mt-2' style={{ borderTopColor: "#3b2d57" }}></div>
      </div> :
        <button onClick={handleClick} disabled={disable} type={btnType} style={{ backgroundColor: `${color}` }} className={`sqbtn text-white ${className ? className : 'bg-[#301762]'} `}>{sqicon}</button>}
    </>
  )
}
