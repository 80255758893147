import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { addFee, deleteFee, getAllFee } from "./feeApi";

const initialState = {
    fee: [],
    status: "idle",
};
//=============GET MEDICINE THUNK================
export const getAllFeeAsync = createAsyncThunk(
    "fee/get_fee)",
    async () => {
        const response = await getAllFee();
        if (response.status) {
            return response.data;
        } else {
            // toast.error(response.message);
            return false;
        }
    }
);

//============= ADD MEDICINES THUNK =============
export const addFeeAsync = createAsyncThunk(
    "fee/add_fee",
    async (medicineDetails) => {
        const response = await addFee(medicineDetails);
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);

//===============DELETE MEDICINE API ====================
export const deleteFeeAsync = createAsyncThunk(
    "fee/delete_fee",
    async (id) => {
        let isDel = window.confirm("Are You Sure!");
        try {
            if (isDel) {
                const response = await deleteFee(id);
                if (response.status) {
                    return response.data;
                } else {
                    toast.error(response.message);
                    return false;
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const feeSlice = createSlice({
    name: "fee",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllFeeAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAllFeeAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.fee = action.payload;
                }
            })
            .addCase(addFeeAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addFeeAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.fee.push(action.payload);
                    toast.success("fee added successfully");
                } else {
                    toast.error("Something went wrong .failed to add medicine");
                }
            })
            .addCase(deleteFeeAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFeeAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const index = state.fee.findIndex(
                        (fee) => fee._id === action.payload._id
                    );
                    state.fee.splice(index, 1, action.payload)
                    toast.success("fee deleted successfully");
                }
            });
    },
});

export const selectFee = (state) => state.fee.fee;
export default feeSlice.reducer;
