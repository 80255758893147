import { Link } from "react-router-dom";
import { pathOrigin } from "../../utils/helper";


const Setting = () => {

  return (
    <>
      <div className="flex flex-wrap gap-10 ms-20">
        <div className="bg-white w-[220px] h-[200px] shadow">
          <div className="flex justify-center items-center p-2">
            <div className="p-5 bg-red-100 rounded-[50%] ">
              <img
                src={`${pathOrigin()}/asset/icons/dental-checkup.png`}
                className="w-20"
                alt="Procedure"
              />
            </div>
          </div>
          <p className="text-center text-gray-600 font-medium text-sm">
            Procedure
          </p>
        </div>
        <div className="bg-white w-[220px] h-[200px] shadow">
          <Link to="/setting/material">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/material.png`}
                  className="w-20"
                  alt="material"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">
              Materials
            </p>
          </Link>
        </div>
        <div className="bg-white w-[220px] h-[200px] shadow">
          <Link to="/setting/appointmenttiming">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/schedule.png`}
                  className="w-20"
                  alt="appointTime"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">
              Appointment Timing
            </p>
          </Link>
        </div>
        <div className="bg-white w-[220px] h-[200px] shadow">
          <Link to="/setting/fee">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/money.png`}
                  className="w-20"
                  alt="appointTime"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">Fee</p>
          </Link>
        </div>
        <div className="bg-white cursor-pointer w-[220px] h-[200px] shadow">
          <Link to="/setting/uploadfile">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/uploadfile.png`}
                  className="w-20"
                  alt="appointTime"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">
              uploadfiles
            </p>
          </Link>
        </div>
        <div className="bg-white cursor-pointer w-[220px] h-[200px] shadow">
          <Link to="/setting/medicine">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/drugs.png`}
                  className="w-20"
                  alt="appointTime"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">
              Medicines
            </p>
          </Link>
        </div>
        <div className="bg-white cursor-pointer w-[220px] h-[200px] shadow">
          <Link to="/setting/medicine-group">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/medicineGroup.png`}
                  className="w-20"
                  alt="appointTime"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">
              Medicines Group
            </p>
          </Link>
        </div>
        <div className="bg-white cursor-pointer w-[220px] h-[200px] shadow">
          <Link to="/setting/treatment">
            <div className="flex justify-center items-center p-2">
              <div className="p-5 bg-red-100 rounded-[50%] ">
                <img
                  src={`${pathOrigin()}/asset/icons/treatment-ico.png`}
                  className="w-20"
                  alt="appointTime"
                />
              </div>
            </div>
            <p className="text-center text-gray-600 font-medium text-sm">
              Treatments
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Setting;
