import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import { FaTrash } from "react-icons/fa6";
import ModalComponent from "../modal/ModalComponent";
import { useSelector } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import { DateFormat, Prifix, TimeFormat } from "../../utils/helper";
import { selectedPatient } from "../../redux/patientAppointments/patientSlice";
import { Link, useParams } from "react-router-dom";
import ShowPhotoModel from "../modal/showPhotoModel";
import { deleteOrtho, getAllOrthoByPatientId, updateOrtho } from "../../redux/ortho section/orthoApi";
import toast from "react-hot-toast";
import SquareButton from "../Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import { FaUserEdit, FaWhatsapp } from "react-icons/fa";

export default function OrthoDetails() {
    const { id } = useParams();
    const patientDetails = useSelector(selectedPatient);
    const [orthoData, setOrthoData] = useState([]);
    const [photoModelOpen, setPhotoModelOpen] = useState(false);
    const [modelData, setModelData] = useState();
    const [editModelOpen, setEditModelOpen] = useState(false);
    const [editOrtho, setEditOrtho] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // for delete Ortho
    async function handleTaskDelete(Id) {
        const is = window.confirm("Are You Sure ?");
        toast.loading("Loading...")
        try {
            if (is) {
                let response = await deleteOrtho(Id)
                if (response.status) {
                    toast.dismiss()
                    setOrthoData(orthoData.filter(item => item._id !== response.data._id))
                    toast.success("Deleted Sucessfully")
                } else {
                    toast.dismiss()
                }
            }
        } catch (error) {
            console.log(error);
            toast.dismiss()
        }
    }

    useEffect(() => {
        async function getOrthoDetails() {
            try {
                let response = await getAllOrthoByPatientId(id)
                if (response.status) {
                    setOrthoData(response.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        getOrthoDetails();
    }, [refresh]);

    // edit Ortho details
    async function handleEdit(data) {
        setIsLoading(true)
        try {
            let response = await updateOrtho(id,editOrtho._id,data)
            if (response.status) {
                setRefresh(!refresh)
                setEditOrtho({})
                setIsLoading(false)
                setEditModelOpen(false)
                toast.success(response.message)
            } else {
                setIsLoading(false)
                toast.error(response.message)
            }
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message)
        }
    }
    return (
        <>
            <div className="mb-2">
                <div className="flex justify-between w-full p-3">
                    <div className="flex">
                        <span>
                            <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
                        </span>
                        <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                            Patient Name -
                        </p>
                        <p className="text-gray-600 text-sm pt-[5px] pl-2">
                            {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
                        </p>
                    </div>
                    <div className="flex mr-2">
                        <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                            File Number -
                        </p>
                        <p className="text-gray-600 text-sm pt-[5px] pl-2">
                            {patientDetails?.file_number}
                        </p>
                    </div>
                </div>
                <section
                    id="medicine-section"
                    className="bg-white  overflow-y-auto mb-2"
                >
                    <div className="w-full pl-2 bg-gray-300 rounded-t-sm text-white items-center">
                        <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
                            Total Ortho Report({orthoData?.length})
                        </h1>
                    </div>
                    <hr />
                    {/* //======================file display table section start============================= */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white rounded-lg">
                            <thead className="text-xs text-gray-700 uppercase">
                                <tr className="bg-gray-100 text-center">
                                    <th className="py-2 px-2 font-medium ">Sr. No.</th>
                                    <th className="py-2 px-2 font-medium">Work Done</th>
                                    <th className="py-2 px-2 font-medium">Breakage</th>
                                    <th className="py-2 px-2 font-medium">Breakage Amount</th>
                                    <th className="py-2 px-2 font-medium">Total Amount</th>
                                    <th className="py-2 px-2 font-medium"> Deposite</th>
                                    <th className="py-2 px-2 font-medium">Less</th>
                                    <th className="py-2 px-2 font-medium">Braces</th>
                                    <th className="py-2 px-2 font-medium">Date</th>
                                    <th className="py-2 px-2 font-medium">Option</th>
                                    <th className="py-2 px-2 font-medium">WhatsApp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orthoData &&
                                    orthoData.map((item, idx) => (
                                        <tr className="border-t border-b border-gray-200 hover:bg-gray-50 text-gray-700 text-center text-xs" key={idx}>
                                            <td className="py-2 px-2 ">{idx + 1}</td>
                                            <td className="py-2 px-4">{item.work_done}</td>
                                            <td className="py-2 px-4">{item.breakage}</td>
                                            <td className="py-2 px-4">{item.breakage_amount}</td>
                                            <td className="py-2 px-4">{item.total_amount}₹</td>
                                            <td className="py-2 px-4">{item.deposite}₹</td>
                                            <td className="py-2 px-4">{item.less}₹</td>
                                            <td className="py-2 px-4">{item.braces}</td>
                                            <td className="py-2 px-1">{DateFormat(item.send_date)}</td>
                                            <td className="py-2 px-2">
                                                <div className="flex justify-center items-center gap-2">
                                                    <button
                                                        className="text-xl text-blue-900 hover:text-blue-700"
                                                        onClick={() => {
                                                            setEditOrtho({
                                                                work_done: item.work_done,
                                                                breakage: item.breakage,
                                                                breakage_amount: item.breakage_amount,
                                                                total_amount: item.total_amount,
                                                                deposite: item.deposite,
                                                                less: item.less,
                                                                braces: item.braces,
                                                                send_date: item.send_date,
                                                                _id : item._id
                                                            });
                                                            setEditModelOpen(true);
                                                        }}
                                                    ><FaUserEdit /></button>
                                                    <button onClick={() => { handleTaskDelete(item._id) }} className="text-red-500 hover:text-red-400 text-xl" title="Delete Task"><FaTrash /></button>
                                                </div>

                                            </td>
                                            <td>
                                                <div className="flex justify-center">
                                                    <SquareButton
                                                        sqicon={
                                                            <span className="text-lg" title="Send Prescription To Whatsapp">
                                                                <Link
                                                                    to={`https://web.whatsapp.com/send?phone=+91${item?.patient?.mobile}&text=Hello ${item?.patient?.patient_name}  ${DateFormat(item.send_date)}, 🌟%0a%0aHope you're doing well! Here's the update on your ortho report from Shri Balaji Dental Care, Indore:%0a

                                                                        *🦷 Work Completed:*  ${item?.work_done}
                                                                        *⚙️ Breakage Details:*  ${item?.breakage}
                                                                        *💰 Breakage Amount:*  ${item?.breakage_amount}
                                                                        *🦷Braces:*  ${item?.braces}
                                                                        %0a
                                                                        *Bill Breakup-*%0a
                                                                        *Total:*  ₹ ${item?.total_amount}
                                                                        *Deposited:*  ₹ ${item?.deposite}
                                                                        *Discount:*  ₹ ${item?.less}
                                                                                                                                
                                                                        %0a
                                                                        If you have any questions or need further assistance, feel free to reach out. We're here to help! 😊%0a
                                                                        %0a
                                                                        Best regards,  %0a
                                                                        Shri Balaji Dental Care Team`}
                                                                    target="_blank"
                                                                    className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                                                                >
                                                                    <FaWhatsapp />
                                                                </Link>
                                                            </span>
                                                        }
                                                        color={"#25D366"}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    {/* //======================file display table section start============================= */}
                </section>
            </div>
            {/* edit prescription model show */}

            <ModalComponent
                content={
                    <form className="space-y-6  p-3  bg-slate-100" onSubmit={handleSubmit(handleEdit)}>
                        <div className=" grid grid-cols-2 gap-4">
                            <div>
                                <label
                                    htmlFor="name"
                                    className="block text-sm text-gray-600 font-medium mb-2"
                                >
                                    Patient Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="patient_name"
                                        type="text"
                                        autoComplete="patient_name"
                                        defaultValue={patientDetails?.patient_name}
                                        disabled
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="work_done" className="block text-sm text-gray-600 font-medium mb-2">
                                    Work Done
                                </label>
                                <input
                                    name="work_done"
                                    id="work_done"
                                    type='text'
                                    defaultValue={editOrtho?.work_done}
                                    {...register("work_done")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="breakage" className="block text-sm text-gray-600 font-medium mb-2">
                                    Breakage
                                </label>
                                <input
                                    name="breakage"
                                    id="breakage"
                                    type='text'
                                    defaultValue={editOrtho?.breakage}
                                    {...register("breakage")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="breakage_amount" className="block text-sm text-gray-600 font-medium mb-2">
                                    Breakage Amount
                                </label>
                                <input
                                    name="breakage_amount"
                                    id="breakage_amount"
                                    type='text'
                                    defaultValue={editOrtho?.breakage_amount}
                                    {...register("breakage_amount")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="total_amount" className="block text-sm text-gray-600 font-medium mb-2">
                                    Total Amount
                                </label>
                                <input
                                    name="total_amount"
                                    id="total_amount"
                                    type='number'
                                    defaultValue={editOrtho?.total_amount}
                                    {...register("total_amount")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="deposite" className="block text-sm text-gray-600 font-medium mb-2">
                                    Deposite
                                </label>
                                <input
                                    name="deposite"
                                    id="deposite"
                                    type='text'
                                    defaultValue={editOrtho?.deposite}
                                    {...register("deposite")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="less" className="block text-sm text-gray-600 font-medium mb-2">
                                    Less
                                </label>
                                <input
                                    name="less"
                                    id="less"
                                    type='number'
                                    defaultValue={editOrtho?.less}
                                    {...register("less")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="braces" className="block text-sm text-gray-600 font-medium mb-2">
                                    Braces
                                </label>
                                <input
                                    name="braces"
                                    type='text'
                                    id="braces"
                                    defaultValue={editOrtho?.braces}
                                    {...register("braces")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="send_date" className="block text-sm text-gray-600 font-medium mb-2">
                                    Date
                                </label>
                                <input
                                    name="send_date"
                                    id="send_date"
                                    type='date'
                                    defaultValue={editOrtho?.send_date}
                                    {...register("send_date")}
                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                />
                            </div>
                        </div>
                        <div className="mt-3 flex gap-3">
                            <SquareButton
                                isLoading={isLoading}
                                sqicon={
                                    <span className="text-xs">
                                        Save
                                    </span>
                                }
                            />
                        </div>
                    </form>
                }
                open={editModelOpen}
                setOpen={setEditModelOpen}
            />

            <ShowPhotoModel
                content={<>
                    <div className="flex flex-col md:flex-row w-full justify-evenly p-1 border">
                        <div className="w-full md:w-1/3 p-5 rounded-lg ">
                            <h2 className="text-blue mb-2 font-medium text-sm">Lab Details</h2>
                            <hr />
                            <ul className="grid gap-4 p-4">
                                <li><strong className="text-blue">Lab Name</strong> - {modelData && modelData.lab.lab_name}</li>
                                <li><strong className="text-blue">Works</strong> - {modelData && modelData.works}</li>
                                <li><strong className="text-blue">Cost</strong> - {modelData && modelData.cost}</li>
                                <li><strong className="text-blue">Units</strong> - {modelData && modelData.units}</li>
                            </ul>
                        </div>
                        <div className="w-full flex justify-center md:w-2/3 p-3 rounded-md">
                            {modelData?.photo ? <img src={modelData && modelData.photo} alt={modelData && modelData.lab.lab_name} className="h-96 rounded" />
                                : <img src="/asset/images/unknown-image.jpg" alt={modelData && modelData.lab.lab_name} className="h-96 rounded" />}
                        </div>
                    </div>
                </>
                }
                open={photoModelOpen}
                setOpen={setPhotoModelOpen}
            />
        </>
    );
}
