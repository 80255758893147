import React, { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import { webURLs } from "../../../webServices/GateWay";
import { axiosClient } from "../../../webServices/WebURL";
import moment from "moment";

function AppointmentTiming() {
  // let testTime = "2024-03-15T14:30:10.000Z";
  // let testformat = moment(testTime).format("hh:mm:ss");
  // console.log(testformat);
  // let isoFormat = new Date(`October 15, 1996 ${testformat}`).toISOString()
  // console.log(isoFormat)
  // console.log(moment(`October 15, 1996 ${testformat}`).format("hh:mm"));
  // const[mtimeSlot,setmTimeSlot] =useState({
  //   openningTime:"",
  //   closingTime:"",
  //   duration:"",
  //   day:""
  // })
  // const [mgetTime, setmGetTime] = useState([]);
  const [valDur,setValDur]=useState("")
  const [duration, setDuration] = useState(""); //for morning
  const [mgetTime, setmGetTime] = useState([]); //for morning
  // const [updateopT, setUpdateopt] = useState();
  const [egetTime, seteGetTime] = useState([]); //for evening
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    //for evening
    axiosClient()
      .get(`${webURLs.ALL_EVENING_SLOTS}`)
      .then((res) => {
        seteGetTime(res.data.data);
      })
      .catch((error) => console.log(error.message));

 

    //  console.log(egetTime[0].openningTime)
    //  const hii =moment(egetTime[0]['openningTime']).format("hh:mm")
    //   console.log( moment(hii).format("hh:mm"))

    //for morning
    axiosClient()
      .get(`${webURLs.ALL_MORNING_SLOTS}`)
      .then((res) => {
        setmGetTime(res.data.data);console.log(res.data.data);
      })
      .catch((error) => console.log(error.message));
    // console.log("hello 2")
    // console.log(mgetTime)
  }, []);

  //for updating changes in input field
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setmTimeSlot((prev) => ({
  //     ...prev,
  //     [name]: value
  //   }));
  // };

  //for morning
  const handleChange = (e, index, field) => {
    // const testObj={
    //   fname:"test"
    // }
    // console.log(testObj[0].fname);
    const { value } = e.target;
    // console.log(`${value}:00`);
    // let testformat = moment(`${value}:00`).format("hh:mm:ss");
    //  console.log(testformat)
    let isoFormat = new Date(`October 15, 1996 ${value}:00`).toISOString();
    // setUpdateopt(isoFormat);

    // console.log(isoFormat);
    let updatedMorningSlots = [...mgetTime];
    updatedMorningSlots[index] = {
      ...mgetTime[index],
      [field]: isoFormat,
    };

    // console.log(updatedMorningSlots[index]["openningTime"]);
    // updatedMorningSlots[index] = isoFormat;
    setmGetTime(updatedMorningSlots);
    // console.log(updatedMorningSlots);
    // console.log("hello 3")
  };

  //for updating time in morning slots
  const onSaveM = async (index) => {
    const updateTime = mgetTime[index];
    try {
      await axiosClient().put(
        `${webURLs.UPDATE_MORNING_SLOTS}${updateTime._id}`,
        {
          openningTime: updateTime.openningTime,
          closingTime: updateTime.closingTime,
          duration: duration,
        },
        { new: true }
      );
      // console.log("Updated slot data:", api.data.data); 
    } catch (error) {
      console.log(error.message);
    }
  };

  //for evening
  const handleChange2 = (e, index, field) => {
    const { value } = e.target;
    // console.log(`${value}:00`);
    // let testformat = moment(`${value}:00`).format("hh:mm:ss");
    //  console.log(testformat)
    let isoFormat = new Date(`October 15, 1996 ${value}:00`).toISOString();
    // setUpdateopt(isoFormat)

    // console.log(isoFormat);
    let updatedEveningSlots = [...egetTime];
    updatedEveningSlots[index] = {
      ...egetTime[index],
      [field]: isoFormat,
    };

    // console.log(updatedEveningSlots[index]["closingTime"]);
    // updatedEveningSlots[index] = isoFormat;
    setmGetTime(updatedEveningSlots);

    console.log(updatedEveningSlots);
    // console.log("hello 4")
  };

  //for updating time in evening slots
  const onSaveE= async (index) => {
    const updateTime = egetTime[index];
    try {
      await axiosClient().put(
        `${webURLs.UPDATE_EVENING_SLOTS}${updateTime._id}`,
        {
          openningTime: updateTime.openningTime,
          closingTime: updateTime.closingTime,
          duration: duration,
        },
        { new: true }
      );
      // console.log("Updated slot data:", api.data.data); 
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <div className="p-4 shadow-sm shadow-slate-200 ">
        <label
          htmlFor="slot"
          className="block text-lg font-medium leading-6 text-gray-900"
        >
          Time Slot (Minutes)
        </label>
        <div className="mt-2 flex flex-cols gap-4 ">
          <select
            id="slot"
            name="slot"
            onChange={(e) => {
              setValDur(e.target.value)
              
            }}
            autoComplete="Select"
            required
            className="block w-[20%] rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="">-Select Duration-</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="60">60</option>
          </select>

          <button
           onClick={()=> setDuration(valDur)}
            type="submit"
            className="flex w-fit justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
      <div className="flex flex-col  h-[420px]">
        <div className="flex flex-cols-3 gap-x-5">
          <div>
            <div className=" mt-5 items-baseline p-3 px-4 shadow-2xl border-b-[1px]">
              <p className=" font-bold  mb-2">Day</p>
            </div>

            <div className="sm:mx-auto   w-[100%]  bg-slate-100   border-b-[1px]  py-4 px-4">
              <form className="space-y-[33.5px]  w- " action="#" method="POST">
                {days.map((element, index) => (
                  <div key={index}>
                    {/* Use key prop to provide a unique identifier for each element */}
                    <p className="text-xl">{element}</p>
                  </div>
                ))}
              </form>
            </div>
          </div>
          <div>
            <div className=" mt-5 items-baseline w-full p-3 px-4 shadow-2xl border-b-[1px]">
              <p className="   font-bold  mb-2">Morning</p>
            </div>

            <div className=" bg-slate-100 border-b-[1px]  py-4 px-4">
              <form className="space-y-6 ">
                {mgetTime.map((element, index) => (
                  <div
                    className=" grid grid-cols-3 justify-items-center gap-4 "
                    key={index}
                  >
                    <div>
                      <input
                        name={`morningOpenningTime${index}`}
                        type="time"
                        defaultValue={moment(element.openningTime).format(
                          "hh:mm"
                        )}
                        onChange={(e) => handleChange(e, index, "openningTime")}
                        autoComplete="off"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div>
                      <input
                        name={`morningClosingTime${index}`}
                        type="time"
                        defaultValue={moment(element.closingTime).format(
                          "hh:mm"
                        )}
                        onChange={(e) => handleChange(e, index, "closingTime")}
                        autoComplete="off"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        onSaveM(index);
                      }}
                      className="flex items-center justify-center w-8 h-8 bg-indigo-600 rounded-full text-white hover:bg-indigo-500 focus:outline-none"
                    >
                      <IoIosSave />
                    </button>
                  </div>
                ))}
              </form>
            </div>
          </div>
          <div>
            <div className=" mt-5 items-baseline w-full p-3 px-4  shadow-2xl border-b-[1px]">
              <p className="   font-bold  mb-2">Evening</p>
            </div>

            <div className=" bg-slate-100 border-b-[1px]  py-4 px-4">
              <form className="space-y-6 " action="#" method="POST">
                {egetTime.map((element, index) => (
                  <div
                    className=" grid grid-cols-3 justify-items-center gap-4 "
                    key={index}
                  >
                    <div>
                      <input
                        name={`eveningOpenningTime${index}`}
                        type="time"
                        defaultValue={moment(element.openningTime).format(
                          "hh:mm"
                        )}
                        onChange={(e) =>
                          handleChange2(e, index, "openningTime")
                        }
                        autoComplete="off"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>


                    <div>
                      <input
                        name={`eveningClosingTime${index}`}
                        type="time"
                        defaultValue={moment(element.closingTime).format(
                          "hh:mm"
                        )}
                        onChange={(e) => handleChange2(e, index, "closingTime")}
                        autoComplete="off"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        onSaveE(index);
                      }}
                      className="flex items-center justify-center w-8 h-8 bg-indigo-600 rounded-full text-white hover:bg-indigo-500 focus:outline-none"
                    >
                      <IoIosSave />
                    </button>
                  </div>
                ))}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentTiming;
