import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPrecationFile,
  deletePrecautionFile,
  getAllPrecautionFiles,
} from "./precautionAPI";
import toast from "react-hot-toast";

const initialState = {
  precationFile: [],
  status: "idle",
};

//================= ADD PRECAUTION ASYNC THUNK================
export const addPrecationFileAsync = createAsyncThunk(
  "precaution/add_precation_file",
  async (fileDetails) => {
    toast.loading("Loading...")
    const response = await addPrecationFile(fileDetails);
    if (response.status) {
      toast.dismiss()
      return response.data
    } else {
      toast.dismiss()
      toast.error(response.message);
      return false
    }
  }
);

//============ GET ALL PRECAUTION ASYNC THUNK ==================
export const getAllPrecautionFilesAsync = createAsyncThunk(
  "precaution/get_all_precationsFiles",
  async () => {
    const response = await getAllPrecautionFiles();
    if (response.status) {
      return response.data
    } else {
      return false
    }
  }
);

//====================  DELETE PRECAUTION ASYNC THUNK ===================
export const deletePrecautionFileAsync = createAsyncThunk(
  "precaution/delete_precautions",
  async (id) => {
    let isDel = window.confirm("Are You Sure ! ");
    try {
      if (isDel) {
        const response = await deletePrecautionFile(id);
        if (response.status) {
          return response.data
        } else {
          toast.error(response.message);
          return false
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const precationSlice = createSlice({
  name: "precation",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPrecautionFilesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllPrecautionFilesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.precationFile = action.payload;
        }
      })
      .addCase(addPrecationFileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPrecationFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.precationFile.push(action.payload);
          toast.success("precaution file for selected disease uploaded successfully");
        }
      })
      .addCase(deletePrecautionFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          const index = state.precationFile.findIndex(
            (precation) => precation._id === action.payload._id
          );
          state.precationFile.splice(index, 1);
          toast.success("medicine deleted successfully");
          return;
        }
      });
  },
});

export const selectDiseasePrecation = (state) =>
  state.diseasePrecations.precationFile;

export default precationSlice.reducer;
