import axios from "axios";
import Cookies from "js-cookie";
const apiUrl = process.env.API_URL || "https://api.shribalajilaserdentalclinic.com"

export function axiosClient() {
  const token = Cookies.get("token");
  return axios.create({
    baseURL: apiUrl,
    headers: {
      "Content-Type": "Application/JSON",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  })
}
