import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW MEDICINES API =======================
export function addMedicines(medicineDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      webURLs.ADD_MEDICINES,
      medicineDetails
    );
    resolve(response.data);
  });
}
//=================== GET ALL MEDICINES API =======================
export function getMedicines() {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(webURLs.GET_MEDICINES);
    resolve(response.data)
  });
}

//========================= DELETE MEDICINES API ==================

export function deleteMedicine(medicineId) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().delete(
      `${webURLs.DELETE_MEDICINES}/${medicineId}`
    );
    resolve(response.data);
  });
}
