import React from "react";
import { Link } from "react-router-dom";
import {
  IoPeople,
  IoEye,
} from "react-icons/io5";
import { CiMemoPad } from "react-icons/ci";
import { FaHospitalUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  selectAllPatient,
  selectPatientAppointment,
} from "../../redux/patientAppointments/patientSlice";
import { DateFormat, GetTime } from "../../utils/helper";

const DashboardGrid = () => {
  const todayDate = new Date()
  let date = DateFormat(todayDate)
  const TotalPatient = useSelector(selectAllPatient)?.length;
  const patientAppointment = useSelector(selectPatientAppointment);
  const filteredAppointment = patientAppointment && patientAppointment.filter((item) =>
    (item.booked === true && item.isAppointmentDone === false && item.missed === false))?.sort((a, b) => GetTime(a.date) - GetTime(b.date))
  const todayAppointment = filteredAppointment && filteredAppointment.filter(item => DateFormat(item.date) === date)?.length
  const upcomingAppointment = filteredAppointment && filteredAppointment.length

  return (
    <>
      <div className="flex flex-col gap-2">
        <Link to="/patient" style={{ textDecoration: "none" }}>
          <div className="box1 p-4 flex-1 flex items-center">
            <div className="rounded-full h-10 w-10 flex items-center justify-center bg-sky-500">
              <FaHospitalUser className="text-xl text-white" />
            </div>
            <div className="pl-4 text-blue hover:text-blue-700">
              <span className="text-xs">
                Total Patients
              </span>
              <div className="flex items-center">
                <strong className="text-sm font-medium">
                  {TotalPatient}
                </strong>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/upcomming-appointment">
          <div className="box2 p-4 flex-1 flex items-center">
            <div className="rounded-full h-10 w-10 flex items-center justify-center bg-orange-600">
              <CiMemoPad className="text-xl text-white" />
            </div>
            <div className="pl-4">
              <span className="text-xs text-blue font-light">
                Upcoming Appointment
              </span>
              <div className="flex items-center">
                <strong className="text-sm text-blue font-medium">
                  {upcomingAppointment}
                </strong>
                <span className="text-sm text-blue pl-2">
                  <IoEye className="text-xl text-[dodgerblue] hover:text-green-700 cursor-pointer" />
                </span>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/upcomming-appointment">
          <div className="box3 p-4 flex-1  flex items-center">
            <div className="rounded-full h-10 w-10 flex items-center justify-center bg-yellow-400">
              <IoPeople className="text-xl text-white" />
            </div>
            <div className="pl-4">
              <span className="text-xs text-blue font-light">
                Today's Appointment
              </span>
              <div className="flex items-center">
                <strong className="text-sm text-blue font-medium">
                  {todayAppointment}
                </strong>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default DashboardGrid;
