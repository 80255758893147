import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW VOICE RECORDING API =======================
export async function addVoiceRecording(patientId, obj) {
    const response = await axiosClient().post(
        `${webURLs.ADD_RECORDING}${patientId}`,
        obj
    );
    return response.data;
}


//=================== GET ALL voice recording API =======================
export async function getAllVoiceRecording() {
    const response = await axiosClient().get(webURLs.GET_ALL_RECORDINGS);
    return response.data;
}


//===================  get voice recording by Patient Id API =======================
export async function getVoiceRecordingByPatientId(patientId) {
    const response = await axiosClient().get(`${webURLs.GET_RECORDINGS_BY_PATIENT}${patientId}`);
    return response.data
}

//========================= DELETE voice recording API ==================
export async function deleteVoiceRecording(Id) {
    const response = await axiosClient().delete(
        `${webURLs.DELETE_RECORDING}${Id}`
    );
    return response.data;
}

