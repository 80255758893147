import React, { useRef, useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import { updatePatientDetailsAsync } from "../../redux/patientAppointments/patientSlice";
const FollowUpAppointment = ({ patientDetails,openStatus, getOpen }) => {
  const [open, setOpen] = useState(openStatus);
  const dispatch = useDispatch();
  const dateRef = useRef();
  const patientInfo = patientDetails;
  const patientId = patientDetails._id;


  const handleUpdate = (e) => {
    e.preventDefault();
    console.log(dateRef.current.value);
    dispatch(
      updatePatientDetailsAsync({
        id: patientId,
        patientDetails: { followUpDate: dateRef.current.value },
      })
    );
    setOpen(false)
    // axiosClient
    //   .patch(`${webURLs.UPDATE_PATIENT}/${patientInfo._id}`, {
    //     followUpDate: dateRef.current.value,
    //   })
    //   .then((res) =>{res.data.status===true ?toast.success(`FollowUp updated successfully!`):toast.error("failed to update , please try again.");window.location.reload()})
    //   .catch((err) => console.log(err));
  };

  useEffect(() => {
    getOpen(open);
  }, [open]);

  
  return (
    <div className="bg-white h-fit w-fit p-6 rounded-xl shadow-lg">
      <h1 className="text-2xl mb-7 mt-3 font-semibold text-center">
        Schedule FollowUp Date
      </h1>

      <form
        className="space-y-6  p-3 "
        onSubmit={(e) => {
          handleUpdate(e);
        }}
      >
        <div className=" grid grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Patient's Name
            </label>
            <div className="mt-2">
              <input
                id="name"
                name="name"
                value={patientInfo.patient_name}
                type="text"
                autoComplete="name"
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="mobile"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Mobile no.
            </label>
            <div className="mt-2">
              <input
                type="number"
                required
                value={patientInfo.mobile}
                className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="file"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              File No.
            </label>
            <div className="mt-2">
              <input
                type="text"
                required
                id="FileNo."
                value={patientInfo.file_number}
                className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="scheduleddate"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Next Follow Up Date
            </label>
            <div className="mt-2">
              <input
                id="scheduleddate"
                name="scheduleddate"
                type="date"
                ref={dateRef}
                defaultValue={Date.now()}
                autoComplete="date"
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Message
              </label>
              <div className="text-sm"></div>
            </div>
            <div className="mt-2">
              <input
                id="message"
                name="message"
                type="text"
                autoComplete="message"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        {/* <div className=" grid grid-cols-1 ">
          <div className="flex items-center justify-between">
            <label
              htmlFor="treatment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Treatment
            </label>
            <div className="text-sm"></div>
          </div>
          <div className="mt-2">
            <input
              id="treatment"
              name="treatment"
              type="text"
              autoComplete="treatment"
              required
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div> */}
        {/* <div className=" grid grid-cols-1 ">
          <div className="flex items-center justify-between">
            <label
              htmlFor="message"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
             Message
            </label>
            <div className="text-sm"></div>
          </div>
          <div className="mt-2">
            <input
              id="message"
              name="message"
              type="text"
              autoComplete="message"
              required
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div> */}
        <button className="bg-purple-500 text-white py-1 px-2 my-4 rounded-lg">
          Save
        </button>
      </form>
    </div>
  );
};

export default FollowUpAppointment;
