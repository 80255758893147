// Page Routes

export { default as LOGIN_PAGE } from '../pages/loginPage/LoginPage.jsx'
export { default as DASHBOARD_PAGE } from '../pages/dashboard/Dashboard.jsx'
export { default as PATIENTS_PAGE } from '../pages/patients/Patients.jsx'
export { default as PATIENT_DETAILS } from '../pages/patientDetails/PatientDetails.jsx'
export { default as FEE } from '../pages/setting/fee/feePage.jsx'
export {default as UPLOAD_FILE} from "../pages/setting/UploadFiles.jsx"
export {default as MEDICINE_GROUP} from "../pages/setting/medicineGroup.jsx"
export {default as MEDICINE} from "../pages/setting/Medicine.jsx"
export { default as APPOINTMENTS_PAGE } from '../pages/upcommingAppointments/UpCommingAppointments.jsx'
export { default as SETTING_PAGE } from '../pages/setting/Setting.jsx'
export { default as USER_PROFILE_PAGE } from '../pages/userprofile/Userprofile.jsx'
export { default as APPOINTMENT_TIMING_PAGE } from '../pages/setting/appointmentTiming/AppointmentTiming.jsx'
export { default as FOLLOW_UP } from '../pages/followup/FollowUp.jsx'
export { default as LAB_DETAILS } from '../pages/labDetails/labDetails.jsx'
export { default as TREATMENT } from '../pages/treatment/treatment.jsx'
export { default as MARERIALS } from '../pages/setting/materials.jsx'
export { default as TO_DO_LIST } from '../pages/toDoList/toDoList.jsx'
export { default as EACH_LAB_DATA } from '../pages/labDetails/eachLabData.jsx'
export { default as ORTHO_SECTION } from '../pages/ortho/orthosection.jsx'