// Import Components;
import * as COMPONENT_ROUTE from "../../routes/ComponentRoute";


const PatientDetails = () => {
  return (
    <>
      <COMPONENT_ROUTE.TABS />
    </>
  );
};

export default PatientDetails;
