import React from "react";
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaRegTrashCan } from "react-icons/fa6";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import { VscPreview } from "react-icons/vsc";
import {
  addPrecationFileAsync,
  deletePrecautionFileAsync,
  selectDiseasePrecation,
} from "../../redux/precautions/precautionSlice";
import { Link } from "react-router-dom";

const UploadFiles = () => {
  const dispatch = useDispatch();
  const diseaseList = useSelector(selectDiseasePrecation);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  async function saveFile(data) {
    dispatch(addPrecationFileAsync(data));
    reset()
  }

  const deletePdfFile = (id) => {
    dispatch(deletePrecautionFileAsync(id));
  };

  return (
    <>
      <div
        id="uploadfile-form-section"
        className="rounded-sm border-2 pb-3 overflow-hidden mt-1"
      >
        <h1 className="pl-3 mb-4 text-sm font-medium py-2 rounded-t-sm text-center bg-gray-300 text-[#301762]">
          Upload File
        </h1>
        <form
          className="flex justify-around items-center pl-3"
          onSubmit={handleSubmit(saveFile)}
        >
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-600">Upload File</label>
            <input
              className="block py-2 pl-2 text-white text-xs border border-gray-300 rounded cursor-pointer bg-gray-300 focus:outline-none "
              id="small_size"
              type="file"
              name="pdf"
              accept=".pdf"
              {...register("pdf", {
                required: "*file is required & only .pdf are allowed",
              })}
            />
            <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
              <ErrorMessage errors={errors} name="pdf" />
            </div>
          </div>
          <div className="flex flex-col w-[60%]">
            <label className="text-sm font-medium text-gray-700">Instructions</label>
            <input
              type="text"
              className="py-3 w-full rounded p-2"
              name="desease_name"
              placeholder="Instructions"
              list="suggestion"
              {...register("desease_name", {
                required: "*Disease is required",
              })}
            />
            <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
              <ErrorMessage errors={errors} name="desease_name" />
            </div>
            <datalist id="suggestion">
              <option>Root Canal Treatment- Three visit RCT 1</option>
              <option>RCT 2 </option>
              <option>RCT3 </option>
              <option>Scaling and curettage with polishing</option>
              <option>Extraction</option>
              <option>Disimpaction </option>
              <option>Orthodontic treatment</option>
              <option>Fixed Partial Denture</option>
              <option>Removable Partial Denture</option>
              <option>Complete Dentures </option>
              <option>Smile Designing</option>
            </datalist>
          </div>
          <div className="flex justify-start">
            <SquareButton sqicon={<span className="text-sm">Save</span>} />
          </div>
        </form>
      </div>
      <section
        id="disease-file-section"
        className="rounded-sm border-2 pb-3 mt-4 overflow-hidden"
      >
        <div className="flex bg-gray-300 pl-2 items-center">
          <LuFileSpreadsheet className="text-2xl p-0 m-0" />
          <h1 className="pl-3 text-sm font-medium py-2 text-[#301762]">Saved Files({diseaseList &&
              diseaseList.length})</h1>
        </div>
        {/* //======================file display table section start============================= */}
        <table className="w-[100%]">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-xs">
              <th scope="col" className="px-2 py-2 font-medium">
                S.No.
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Instructions
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Uploaded Date
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                View
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {diseaseList &&
              diseaseList.map((item, idx) => (
                <tr key={idx} className="text-center text-gray-700 text-xs">
                  <td className="px-2 py-2 font-medium">
                    {idx + 1}
                  </td>
                  <td className="px-2 py-2">{item?.desease_name}</td>
                  <td className="px-2 py-2">
                    {moment(item?.createdAt)?.format("DD-MMM-YY")}
                  </td>
                  <td className="px-2 py-2 flex justify-center">
                    <SquareButton
                      sqicon={<span className="text-sm" title="View File"><Link to={item?.pdf_file} className="text-white hover:no-underline hover:text-black text-center" target="_blank"><VscPreview /></Link></span>}
                    />
                  </td>
                  <td className="px-2 py-2">
                    <button onClick={() => {
                      deletePdfFile(item._id);
                    }} 
                    title="Delete File"
                    className="bg-red-600 text-sm hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-1 px-2 rounded-sm">
                      <FaRegTrashCan/>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* //======================file display table section start============================= */}
      </section>
    </>
  );
};

export default UploadFiles;
