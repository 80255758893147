import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";

//{ ===============PATIENT APPOINTMENT API's START ================== }
export function fetchCurrentPatientAppointment(id) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(
      `${webURLs.GET_SELECTED_PATIENT_APPOINTMENT}${id}`
    );
    // console.log(response);
    resolve(response.data);
  });
}

// ============fetch patient API===============
export function fetchPatientAppointments() {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(webURLs.GET_APPOINTMENT);
    resolve(response.data);
  });
}

//=============add Appointment API=======================
export function bookPatientAppointment(appointmentDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      webURLs.ADD_NEW_APPOINTMENT,
      appointmentDetails
    );
    resolve(response.data);
  });
}

//===============upadate appointment==================
export function updateAppointment(id) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().patch(
      `${webURLs.UPDATE_APPOINTMENT}${id}`
    );
    resolve(response.data);
  });
}

//===============resechdule appointment==================
export function updateAppointmentFields({ id, obj }) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().patch(
      `${webURLs.UPDATE_APPOINTMENT_FIELDS}${id}`, obj
    );
    resolve(response.data);
  });
}

//=============cancel patient appointment================
export function cancelPatientAppointments(id) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().delete(
      `${webURLs.DELETE_APPOINTMENT}?id=${id}`
    );
    resolve(response.data);
  });
}

//{ ===============PATIENT APPOINTMENT API's END ================== }

//{ ===============PATIENT  API's START ================== }

//=============Add new Patient API===============
export function addNewPatient(patientDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      webURLs.ADD_PATIENT,
      patientDetails
    );
    resolve(response.data);
  });
}
//==============All Patients API================
export function fetchAllPatients() {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(webURLs.PATIENTS);
    resolve(response.data);
  });
}

//==============fetch Patient Details by Id API===============
export async function fetchPatientById(patientId) {
  try {
    const response = await axiosClient().get(
      `${webURLs.PATIENTS}/${patientId}`
    );
    return response.data
  } catch (err) {
    return err.response.data
  }
}

export function updatePatientDetails(patientDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().patch(
      `${webURLs.UPDATE_PATIENT}/${patientDetails.id}`,
      patientDetails.patientDetails
    );
    resolve(response.data);
  });
}

// for delete patient api
export function deletePatientDetails(id) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().delete(
      `${webURLs.DELETE_PATIENT}/${id}`);
    resolve(response.data);
  });
}
//{ ===============PATIENT API's END ================== }

//{ ===============PATIENT BILL API's START ================== }

//================add new bill by patient Id =================
export function addNewBillByPatientId(billDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      `${webURLs.ADD_BILL}${billDetails.patientId}`,
      billDetails.billDetails
    );
    resolve(response.data);
  });
}

//===============fetch patient bill by id===============
export function fetchBillsByPatientId(patientId) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(`${webURLs.GET_BILL}${patientId}`);
    resolve(response.data);
  });
}

//================update bill payment Installment====================
export function updatePaymentBill(paymentDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().patch(
      `${webURLs.UPDATE_BILL}${paymentDetails.patientId}`,
      paymentDetails.patientDetails
    );
    resolve(response.data);
  });
}

//{ ===============PATIENT BILL API's END ================== }

// {================= PATIENT REPORTS API's START ======================}
export function addPatientReport(reportDetails) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().patch(
      `${webURLs.UPDATE_PATIENT}/${reportDetails.id}`,
      reportDetails.reportDetails,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    resolve(response.data);
  });
}
// {================= PATIENT REPORTS API's END ======================}

// =======================PATIENT REPORTS API's======================

export async function ApiRemoveReportDelete(patientId, reportId) {
  // console.log(patientId, reportId);
  try {
    const response = await axiosClient().patch(
      `${webURLs.DELETE_PATIENT_REPORT}${patientId}/${reportId}`
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}
