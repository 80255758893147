import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import { FaRegCircleUser, FaWhatsapp } from "react-icons/fa6";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import toast from "react-hot-toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import SquareButton from "../Ui Components/SquareButton";
import PrescriptionEdilModel from "../modal/prescriptionEditModel";
import { useDispatch, useSelector } from "react-redux";
import { selectMedicine } from "../../redux/medicines/medicineSlice";
import { selectMedicineGroup } from "../../redux/medicineGroup/medicineGroupSlice";
import { useForm } from "react-hook-form";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CheckBoxDropDown from "../dropdown/CheckBoxDropDown";
import GenerateMedicineInvoice from "../invoices/GenerateMedicineInvoice";
import ModalComponent from "../modal/ModalComponent";
import { DateFormat, Prifix, TimeFormat } from "../../utils/helper";
import { getAllExaminationAsync } from "../../redux/examination/examinationSlice";
import { fetchPatientByIdAsync, selectedPatient } from "../../redux/patientAppointments/patientSlice";
import { FaEdit } from "react-icons/fa";
import { VscPreview } from "react-icons/vsc";
import { FaUserEdit } from "react-icons/fa";
import adviceData from "../../data/adviceData";
import { getAllPrescription, getPrescriptionsAsync } from "../../redux/prescription/prescriptionSlice";

export default function Details() {
  const dispatch = useDispatch()
  const patientDetails = useSelector(selectedPatient)
  const allMedicines = useSelector(selectMedicine);
  const allMedicineGroup = useSelector(selectMedicineGroup)
  const allPrescriptions = useSelector(getAllPrescription)
  const [medicine, setMedicine] = useState([])
  const [groupId, setGroupId] = useState("")
  const [GroupMedicine, setGroupMedicine] = useState([])
  const [mainMedicine, setMainMedicine] = useState("")
  const [extraMedicine, setExtraMedicine] = useState([])
  const [extraMedicine2, setExtraMedicine2] = useState([])
  const [medicineData, setMedicineData] = useState([])
  const [checkbox2AllMedicine, setCheckbox2AllMedicine] = useState([])
  const [advice, setAdvice] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [priscriptionData, setPriscriptionData] = useState()
  const [formError, setFormError] = useState();
  const [isClear, setIsClear] = useState(false)
  const [isClearOtherMedicine, setIsClearOtherMedicine] = useState(false);
  const [open, setOpen] = useState(false)
  const { id } = useParams();
  const [refresh, setRefresh] = useState()
  const [note, setNote] = useState("")
  const [editModelOpen, setEditModelOpen] = useState(false)
  const [allDisease, setAllDisease] = useState([
    "Diabetes", "Hypertension", "Thyroid", "Blood thinners", "Any Drug Allergy", "Smoking", "Chewing Tobacco", "NAD", "Other"
  ])
  const [editPatient, setEditPatient] = useState()


  async function handleDelete(pricription_id) {
    let isDel = window.confirm("Are You Sure !")
    try {
      if (isDel) {
        let deleted = await axiosClient().delete(`${webURLs.DELETE_PRESCRIPTION}/${id}/${pricription_id}`)
        if (deleted.data.status) {
          toast.success(deleted.data.message)
          setRefresh(!refresh)
        } else {
          toast.error(deleted.data.message)
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // for additional medicines
  function handleSelected(data) {
    let commonMedicine = [];
    allMedicines?.forEach((element) => {
      if (data.filter((item) => item.value === element._id).length > 0) {
        commonMedicine.push(element);
      }
    });
    setExtraMedicine(commonMedicine);
    let mediData = data.map((item) => ({
      medicine_name: item.value,
    }));
    setMedicine(mediData)
  }

  // for select medicine group
  function handleSelectGroup(e, group) {
    setFormError("")
    let id;
    if (e) {
      id = e.target.value
      setGroupId(id)
    } else {
      id = group
    }
    setIsClearOtherMedicine(id)
    let filterGroup = allMedicineGroup?.filter((item) => item._id === id);
    if (filterGroup.length > 0) {
      let gm = filterGroup[0]?.medicines?.map((item) => (item?.medicine_name));
      setGroupMedicine(gm);
    } else {
      setGroupMedicine([]);
    }
  }

  // for Add some other medicines
  function handleSelectedOtherMedicines(data) {
    setFormError("")
    if (groupId) {
      toast.error("Please Remove Selected Medicine Group")
      setIsClearOtherMedicine(data)
      return;
    }
    let commonMedicine = [];
    allMedicines?.forEach((element) => {
      if (data?.filter((item) => item.value === element._id).length > 0) {
        commonMedicine.push(element);
      }
    });
    let mediData = data.map((item) => ({
      medicine_name: item.value,
    }));
    setMainMedicine(mediData)
    setGroupMedicine(commonMedicine)
  }

  // for select other Advice
  function handleSelectedAdvice(data) {
    const arr = data.map(item => (`${item.label}`))
    setAdvice(arr.join("_"));
  }

  async function EditPriscription() {

    if (!GroupMedicine.length > 0) {
      if (!medicine.length > 0) {
        setFormError("*Select Medicine Group Or Medicine");
        return;
      }
    }
    try {
      setIsLoading(true)
      toast.loading("loading...")
      const input = document.getElementById('pdf');
      let stringFile = await html2canvas(input, { scale: 3 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        // Function to add a page with background color
        const addPageWithBackground = (pdf) => {
          pdf.addPage();
          pdf.setFillColor(248, 230, 206); // Example: light red background
          pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F');
          pdf.setFontSize(9)
        };

        // Add background to the first page
        pdf.setFillColor(248, 230, 206); // Example: light red background
        pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F');
        pdf.setFontSize(9)
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          addPageWithBackground(pdf);
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        const pdfDataUri = pdf.output("blob");
        let blob = new Blob([pdfDataUri], { type: 'application/pdf' });
        return blob;
      });

      if (stringFile) {
        let formData = new FormData()
        formData.append('prescription', JSON.stringify(medicine));
        formData.append("main_medicine", JSON.stringify(mainMedicine));
        formData.append("additional_advice", advice);
        formData.append("note", note)
        formData.append('madicine_group', groupId ? groupId : "6644abcf7a9a423a24673fd5")
        formData.append("pdf_file", stringFile, "invoice.pdf")

        let response = await axiosClient().patch(`${webURLs.UPDATE_PRESCRIPTION}/${id}/${priscriptionData._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })

        if (response.data.status) {
          setOpen(false)
          setRefresh(!refresh)
          toast.dismiss()
          reset()
          setGroupId("")
          setIsClear(true)
          setExtraMedicine([])
          toast.success(response.data.message)
          setIsLoading(false)
        } else {
          toast.dismiss()
          toast.error(response.data.message);
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    dispatch(fetchPatientByIdAsync(id))
    dispatch(getPrescriptionsAsync(id))
  }, [refresh]);

  // for checkbox 2 add some other medicine
  useEffect(() => {
    let medicineData = allMedicines && allMedicines.map((item) => ({
      value: item._id,
      label: item.medicine_name
    }));
    setCheckbox2AllMedicine(medicineData)
  }, [allMedicines])

  // filter medicine arrived form backend 
  let convertToCheckBoxFormat = useCallback((data) => {
    let commonMedicine = []
    allMedicines?.forEach(element => {
      if (data?.filter((item) => item.medicine_name === element._id).length > 0) {
        commonMedicine.push(element);
      }
    })
    return commonMedicine;
  }, [allMedicines,])

  // for edit form model open
  function EditForm(data) {
    setPriscriptionData(data)
    setExtraMedicine(convertToCheckBoxFormat(data.prescription_medicines))
    setMedicine(data.prescription_medicines)
    let filterGroup = allMedicineGroup?.find((item) => item._id === data.madicine_group)
    if (filterGroup) {
      setGroupId(data.madicine_group)
      handleSelectGroup(false, data.madicine_group)
      setMainMedicine([])
      setExtraMedicine2([])
    } else {
      setGroupId("")
      setMainMedicine(data.main_medicine)
      let medi = convertToCheckBoxFormat(data.main_medicine)
      setExtraMedicine2(medi)
      setGroupMedicine(medi)
    }
    setAdvice(data.additional_advice);
    setOpen(true)
  }

  // edit patient details
  async function handleEdit(e) {
    e.preventDefault()
    setIsLoading(true)
    try {
      let response = await axiosClient().patch(`${webURLs.EDIT_PATIENT_INFO}/${id}`, editPatient)
      if (response.data.status) {
        setRefresh(!refresh)
        setIsLoading(false)
        setEditModelOpen(false)
        toast.success(response.data.message)
      } else {
        setIsLoading(false)
        toast.error(response.data.message)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  }
  // load clinical examinations 
  useEffect(() => {
    dispatch(getAllExaminationAsync(id))
  }, [])

  // for filter all medicine not selected in group
  useEffect(() => {
    let commonMedicine = [];
    allMedicines?.forEach((element) => {
      if (GroupMedicine?.filter((item) => item._id === element._id).length === 0) {
        commonMedicine.push(element);
      }
    });
    let medicineData1 = commonMedicine && commonMedicine.map((item) => ({
      value: item._id,
      label: item.medicine_name,
    }));
    // for additional medicine
    setMedicineData(medicineData1);
  }, [GroupMedicine]);

  return (
    <>
      <div className="mb-2">
        <SkeletonTheme baseColor="#e1dee8" highlightColor="#d1bffc">
          {patientDetails?.patient_name ? (<div className="flex justify-between w-full p-2">
            <div className="flex">
              <span>
                <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
              </span>
              <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                Patient Name -
              </p>
              <p className="text-gray-600 text-sm pt-[5px] pl-2">
                {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
              </p>
            </div>
            <div className="flex mr-2">
              <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
                File Number -
              </p>
              <p className="text-gray-600 text-sm pt-[5px] pl-2">
                {patientDetails?.file_number}
              </p>
            </div>
          </div>) :
            <Skeleton
              className="w-full rounded-2xl"
              height={50}
              width={"100%"}
            />}
        </SkeletonTheme>

        <SkeletonTheme baseColor="#e1dee8" highlightColor="#d1bffc">
          {patientDetails?.patient_name ? (
            <div className="border-b-2 border-t-2">
              <div className="bg-white rounded ">
                <div className={`flex items-center bg-gray-100 rounded-t-sm justify-center text-[#301762] text-sm px-4 py-2 ${patientDetails?.importance === "low" && "border-t-4 border-red-500"} ${patientDetails?.importance === "medium" && "border-t-4 border-green-500"} ${patientDetails?.importance === "high" && "border-t-4 border-yellow-500"}`}>
                  <span className="tracking-wide font-medium">Patient Details</span>
                </div>
                <hr />
                <div className="text-gray-700">
                  <div className="grid md:grid-cols-2 text-sm">
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Full Name :</div>
                      <div className="px-4 py-4">
                        {`${Prifix(patientDetails)} ${patientDetails && patientDetails?.patient_name} ${patientDetails && patientDetails?.surname}`}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Contact No. :</div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.mobile}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Gender :</div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.gender}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Diagnosis :</div>
                      <div className="px-4 py-4">
                        {patientDetails?.disease === "Other"
                          ? `${patientDetails?.other_disease}`
                          : `${patientDetails?.disease}`}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">
                        Permanant Address :
                      </div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.address}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">File Number :</div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.file_number}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Marital Status :</div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.marital_status}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Reference By :</div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.reference}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Patient History :</div>
                      <div className="px-4 py-4">
                        {patientDetails && patientDetails?.patient_history}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-4 font-medium">Registration Date :</div>
                      <div className="px-4 py-4">
                        {DateFormat(patientDetails && patientDetails?.createdAt)} Time -{TimeFormat(patientDetails && patientDetails?.createdAt)}
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-4 flex justify-end">
                    <SquareButton
                      sqicon={
                        <span className="text-xl" title="Edit Details">
                          <FaUserEdit />
                        </span>
                      }
                      handleClick={() => {
                        setEditPatient({
                          patient_name: patientDetails?.patient_name,
                          surname: patientDetails?.surname,
                          mobile: patientDetails?.mobile,
                          gender: patientDetails?.gender,
                          marital_status: patientDetails?.marital_status,
                          disease: patientDetails?.disease,
                          address: patientDetails?.address
                        })
                        setEditModelOpen(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton
              className="mt-4 mx-8 rounded-2xl"
              height={200}
              width={"94%"}
            />
          )}
        </SkeletonTheme>

        {/* edit basic patient details */}

        <ModalComponent
          content={
            <form className="space-y-6  p-3  bg-slate-100" onSubmit={handleEdit}>
              <div className=" grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <div className="mt-2">
                    <input
                      name="patient_name"
                      type="text"
                      autoComplete="patient_name"
                      defaultValue={patientDetails?.patient_name}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="file"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <div className="mt-2">
                    <input
                      name="surname"
                      type="text"
                      autoComplete="surname"
                      defaultValue={patientDetails?.surname}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className=" grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Mobile No
                  </label>
                  <div className="mt-2">
                    <input
                      name="mobile"
                      type="tel"
                      autoComplete="mobile"
                      defaultValue={patientDetails?.mobile}
                      maxLength={10}
                      minLength={10}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="file"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>
                  <div className="mt-2">
                    <select
                      id="gender"
                      name="gender"
                      autoComplete="Select"
                      defaultValue={patientDetails?.gender}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-9"
                    >
                      <option >-Select-</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className=" grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="age"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Age
                  </label>
                  <div className="mt-2">
                    <input
                      id="age"
                      name="age"
                      type="tel"
                      defaultValue={patientDetails?.age}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      maxLength="2"
                      autoComplete="age"
                      pattern="\d+"
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="file"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Merital Status
                  </label>
                  <div className="mt-2">
                    <select
                      id="marital_status"
                      name="marital_status"
                      autoComplete="Select"
                      defaultValue={patientDetails?.marital_status}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-9"
                    >
                      <option value="">-Select-</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="widow">other</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Address
                  </label>
                  <div className="mt-2">
                    <input
                      id="address"
                      name="address"
                      type="text"
                      defaultValue={patientDetails?.address}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      autoComplete="address"
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset outline-none focus:ring-[#301762] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="disease"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Disease
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="disease"
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      name="disease"
                      defaultValue={patientDetails?.disease}
                      placeholder="Enter disease"
                      list="list1"
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset outline-none focus:ring-[#301762] sm:text-sm sm:leading-6"
                    />
                    <datalist id="list1">
                      {allDisease.map((item, index) => (
                        <option value="" key={index}>{item}</option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="importance"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Priority
                  </label>
                  <div className="mt-2">
                    <select
                      id="importance"
                      name="importance"
                      autoComplete="Select"
                      defaultValue={patientDetails?.importance}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-9"
                    >
                      <option value="">-Select-</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="reference"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Reference
                  </label>
                  <div className="mt-2">
                    <input
                      id="reference"
                      name="reference"
                      type="text"
                      defaultValue={patientDetails?.reference}
                      onChange={(e) => { setEditPatient({ ...editPatient, [e.target.name]: e.target.value }) }}
                      autoComplete="reference"
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset outline-none focus:ring-[#301762] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 flex gap-3">
                <SquareButton
                  isLoading={isLoading}
                  sqicon={
                    <span className="text-xs">
                      Save
                    </span>
                  }
                />
              </div>
            </form>
          }
          open={editModelOpen}
          setOpen={setEditModelOpen}
        />

        {/* Next section */}
        <section
          id="medicine-section"
          className="bg-white rounded overflow-y-auto mb-2"
        >
          <div className="pl-2 bg-gray-300 rounded-t-sm text-white items-center">
            <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
              Total prescription({allPrescriptions && allPrescriptions.length})
            </h1>
          </div>
          <div className="overflow-x-auto">
            {/* //======================file display table section start============================= */}
            <table className="min-w-full bg-white rounded-lg">
              <thead className="text-sm text-gray-600 uppercase">
                <tr className="bg-gray-100 text-center text-xs">
                  <th className="py-2 px-2 font-medium">
                    Sr.No.
                  </th>
                  <th className="py-2 px-2 font-medium">
                    Cheif complaint
                  </th>
                  <th className="py-2 px-2 font-medium">
                    View File
                  </th>
                  <th className="py-2 px-2 font-medium">
                    Send To Whatsapp
                  </th>
                  <th className="py-2 px-2 font-medium">
                    Edit File
                  </th>
                  <th className="py-2 px-2 font-medium">
                    Date
                  </th>
                  <th className="py-2 px-2 font-medium">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {allPrescriptions && allPrescriptions?.map((item, index) => (
                  <tr key={index} className="text-center border-t hover:bg-gray-50 text-gray-600 text-xs">
                    <td className="py-2 px-2">
                      {index + 1}
                    </td>
                    <td className="py-2 px-2">
                      {item?.cheif_complaint}
                    </td>
                    <td className="py-2 px-2">
                      <SquareButton
                        sqicon={<span className="text-lg" title="View File">
                          <Link to={item?.pdf_file} target="_blank" className="flex justify-center items-center gap-3  text-white hover:no-underline hover:text-black"><VscPreview /></Link></span>}
                      />
                    </td>
                    <td className="py-2 px-2">
                      <div className="flex justify-center">
                        <SquareButton
                          sqicon={
                            <span className="text-lg" title="Send Prescription To Whatsapp">
                              <Link
                                to={`https://web.whatsapp.com/send?phone=+91${patientDetails?.mobile}&text=Hi%20${patientDetails?.patient_name}%20Your%20Prescription%20File%20Link%20Click%20Here%20${item?.pdf_file}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                                target="_blank"
                                className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                              >
                                <FaWhatsapp />
                              </Link>
                            </span>
                          }
                          color={"#25D366"}
                        />
                      </div>
                    </td>
                    <td className="py-2 px-2">
                      <div className="flex justify-center">
                        <SquareButton
                          sqicon={
                            <span className="text-lg" title="Edit Prescription Form">
                              <FaEdit />
                            </span>
                          }
                          handleClick={() => {
                            EditForm(item)
                          }}
                        />
                      </div>
                    </td>
                    <td className="py-2 px-2">
                      {DateFormat(item?.createdAt)}
                    </td>
                    <td className="py-2 px-2">
                      <div className="flex justify-center">
                        <button
                          onClick={() => {
                            handleDelete(item?._id);
                          }}
                          className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                          title="Delete Prescription"
                        >
                          <RiDeleteBin6Line className="p-0 m-0 text-lg" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* //======================file display table section start============================= */}
        </section>
      </div>

      {/* edit prescription model show */}
      <PrescriptionEdilModel
        content={
          <form onSubmit={handleSubmit(EditPriscription)}>
            <div className="flex flex-col p-6">
              <label className="text-sm text-gray-600">Cheif complaints</label>
              <textarea
                className="p-2 rounded-md text-sm bg-slate-200"
                name="cheif"
                id=""
                cols="50"
                rows="1"
                value={priscriptionData?.cheif_complaint}
                readOnly
              ></textarea>
              <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col p-6 w-full">
                <label className="text-sm text-gray-600">Advice Note</label>
                <input
                  className="p-2 mt-2 rounded-md text-sm bg-slate-200  "
                  name="note"
                  id=""
                  type="text"
                  onChange={(e) => { setNote(e.target.value) }}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col p-6 w-[50%]">
                <label className="text-sm text-gray-600">Medicines Groups</label>
                <select
                  name="madicine_group"
                  defaultValue={groupId}
                  onChange={handleSelectGroup}
                  className="h-10 rounded-md text-sm border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ">
                  <option value="">-Select-</option>
                  {allMedicineGroup &&
                    allMedicineGroup.map((item, idx) => (
                      <option value={item._id} key={idx}>
                        {item.group_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col p-6 w-[50%]">
                <label className="text-sm text-gray-600">Additional Medicines</label>
                <CheckBoxDropDown
                  data={medicineData}
                  getData={handleSelected}
                  isClear={isClear}
                  alreadySelected={extraMedicine}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col p-6 w-[50%]">
                <label className="text-sm text-gray-600">Add Some Other Medicines</label>
                <CheckBoxDropDown
                  data={checkbox2AllMedicine}
                  getData={handleSelectedOtherMedicines}
                  isClear={isClearOtherMedicine}
                  alreadySelected={extraMedicine2}
                />
                <div className="text-red-600 flex flex-row items-center gap-1">
                  <label className="text-xs">{formError}</label>
                </div>
              </div>
              <div className="flex flex-col p-6 w-[50%]">
                <label className="text-sm text-gray-600">Add Some Other Advice</label>
                <CheckBoxDropDown
                  data={adviceData}
                  getData={handleSelectedAdvice}
                  isClear={isClear}
                  alreadyAdvice={advice}
                />
              </div>
            </div>
            <div className="relative overflow-x-auto">
              <div className="h-96 overflow-y-auto">
                <GenerateMedicineInvoice
                  medicines={GroupMedicine}
                  extraMedicine={extraMedicine}
                  patientDetails={patientDetails}
                  note={note}
                  priscriptionData={priscriptionData}
                  advice={advice}
                />
              </div>
              <div className="flex w-full ml-5 gap-3 mt-4 mb-3">
                <SquareButton
                  isLoading={isLoading}
                  sqicon={<span className="text-xs">Save Prescription</span>}
                />
              </div>
            </div>
          </form>
        }
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
