import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW Examination API =======================
export function addExamination(patientId, data) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().post(`${webURLs.ADD_EXAMINATION}/${patientId}`,data);
        resolve(response.data);
    });
}

//=================== GET ALL EXAMINATION API =======================
export function getAllExamination(patientId) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().get(`${webURLs.GET_ALL_EXAMINATION}/${patientId}`);
        resolve(response.data)
    });
}

//========================= DELETE Examination API ==================

export function deleteExamination(patientId,examinationId) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().delete(`${webURLs.DELETE_EXAMINATION}/${patientId}/${examinationId}`);
        resolve(response.data);
    });
}

//=================== EDIT EXAMINATION API =======================
export function editExamination(patientId,examinationId, data) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().patch(`${webURLs.EDIT_EXAMINATION}/${patientId}/${examinationId}`,data);
        resolve(response.data);
    });
}

