import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPatientAppointment } from "../../redux/patientAppointments/patientSlice";

async function slotBookingChecker(time, date, appointments) {

  try {

    const isBooked = appointments?.filter((slot)=>{const filteredSlot = slot.time === time && slot.date===date  ;return filteredSlot})
    const res = isBooked?.length;
    // console.log(res)
    return res === 0 ? 'notBooked' : 'booked';
  } catch (error) {
    console.log('Error:', error);
    return 'error'; // Handle error case
  }
}

function TimeSlotComponent({ time, date,appointments}) {
  
  const [slotStatus, setSlotStatus] = useState(null);

  useEffect(() => {
    async function checkBooking() {
      const className = await slotBookingChecker(time, date,appointments);
      setSlotStatus(className);
    }
    checkBooking();
  }, [time,date,appointments]);
  

  return (
    <div className={`slot ${slotStatus}`}>
      <span>{time}</span>
      {/* {slotStatus === 'booked' && <span>Booked</span>}
      {slotStatus === 'notBooked' && <span>Available</span>}
      {slotStatus === 'error' && <span>Error</span>} */}
    </div>
  );
}

export default TimeSlotComponent;
