import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";

//{ ===============PRESCRIPTION API's START ================== }

// ============add priscription API===============
export function addPrescription(prescriptionData) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().post(
      webURLs.ADD_PRESCRIPTION,
      prescriptionData
    );
    resolve(response.data);
  });
}

export function getPrescriptions(id) {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(`${webURLs.GET_PRESCRIPTIONS}${id}`);
    resolve(response.data);
  });
}
