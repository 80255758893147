import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

// Import component
import * as COMPONENT_ROUTE from "./ComponentRoute";
import { useDispatch } from "react-redux";
import { fetchAllPatientAsync, fetchPatientAppointmentsAsync } from "../redux/patientAppointments/patientSlice";
import { loggedInUserDetailsAsync } from "../redux/user/userSlice";
import { useEffect } from "react";
import { getAllPrecautionFilesAsync } from "../redux/precautions/precautionSlice";
import { getMedicinesAsync } from "../redux/medicines/medicineSlice";
import { getMedicinesGroupAsync } from "../redux/medicineGroup/medicineGroupSlice";
import { getAllTreatmentAsync } from "../redux/treatment/treatmentSlice";
import { getAllFeeAsync } from "../redux/fee/feeSlice";
import { getLabAsync, getLabDataAsync } from "../redux/lab/labSlice";

const PrivateRoute = () => {
  const user = Cookies.get("user");
  const token = Cookies.get("token");

  const dispatch = useDispatch();
  const loggedInUser_ID = Cookies.get("userId")

  useEffect(() => {
    if (token && user) {
      dispatch(fetchAllPatientAsync())
      dispatch(fetchPatientAppointmentsAsync())
      dispatch(loggedInUserDetailsAsync(loggedInUser_ID))
      dispatch(getAllPrecautionFilesAsync())
      dispatch(getMedicinesAsync())
      dispatch(getMedicinesGroupAsync())
      dispatch(getAllTreatmentAsync())
      dispatch(getAllFeeAsync())
      dispatch(getLabAsync())
      dispatch(getLabDataAsync())
    }
  }, []);

  return (
    <>
      {user && token ? (
        <div className="flex h-screen">
          <COMPONENT_ROUTE.SIDEBAR />
          <div className="flex-1 flex flex-col">
            <COMPONENT_ROUTE.HEADER />
            <div className="flex-1 pl-4 pr-4 pt-0 overflow-y-auto bg-white">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default PrivateRoute;
