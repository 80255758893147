import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <>
            <div className="w-full flex flex-col items-center justify-center">
                <img src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527139.jpg?w=740&t=st=1715321245~exp=1715321845~hmac=ac03b291ef85c469e2935df5bdc8e42a6af065a8259681c1f1033ecd4776deba" height="310px" width="310px" alt="noData" />

                <div className="flex flex-col items-center justify-center p-1">
                    <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 mt-1">
                        404 Not Found
                    </p>
                    <p className="md:text-lg lg:text-xl text-gray-600 pb-4 pt-2">
                        Sorry, No Such Data or Page Found
                    </p>
                    <Link className="p-2 border border-blue-900 rounded text-blue-900" to="/">Return Home</Link>
                </div>
            </div>
        </>
    )
}
