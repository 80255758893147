import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";
//=============== ADD PRECATION API ====================
export function addPrecationFile(fileDetails) {
  return new Promise(async (resolve) => {
    const formdata = new FormData();
    formdata.append("desease_name", fileDetails.desease_name);
    formdata.append("desease-pdf", fileDetails.pdf[0]);
    let response = await axiosClient().post(webURLs.ADD_DESEASES, formdata);
    resolve(response.data);
  });
}
//================ GET ALL PRECAUTION API =====================
export function getAllPrecautionFiles() {
  return new Promise(async (resolve) => {
    const response = await axiosClient().get(webURLs.GET_DESEASES);
    resolve(response.data);
  });
}

//=================DELETE PRECATION API ======================

export function deletePrecautionFile(id){
    return new Promise (async(resolve)=>{
        const response = await axiosClient().delete(`${webURLs.DELETE_DESEASES}${id}`)
        resolve(response.data)
    })
}
