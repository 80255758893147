import moment from "moment";

export const divideIntervals=(startTime, endTime, duration)=> {
    let intervals = [];
    let currentStartTime = new Date(startTime);
    while (currentStartTime < endTime) {
      const currentEndTime = new Date(currentStartTime.getTime() + duration);
      intervals.push({
        startTime: moment(currentStartTime).format("hh:mm A"),
        endTime: moment(currentEndTime).format("hh:mm A"),
        booked: false,
      });
      currentStartTime = currentEndTime;
    }
    return intervals;
  }