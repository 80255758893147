import React, { useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ModalComponent from "../modal/ModalComponent";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  bookPatientAppointmentAsync,
  cancelPatientAppointmentsAsync,
} from "../../redux/patientAppointments/patientSlice";

// const doctors = [
//   { name: "Dr.Sumit Rathi", id: "660e662b102ebe2a3e2441ca" },
//   { name: "Dr.Ankita Rathi", id: "660e684ecc1f62dadcb1818e" },
// ];

const NewPatientAppointmentForm = ({ time, date }) => {

  const [open, setOpen] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [patientDetails, setPatientDetails] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isNewPatient, setIsNewPatient] = useState(true);
  const dispatch = useDispatch();

  //onsubmit form function
  const onSubmit = async (data) => {
    try {
      // const res = await axiosClient.post(webURLs.ADD_NEW_APPOINTMENT, data);
      // console.log(res);
      // if (res.data.status == false) {
      // toast.error(`Appointment is already booked by someone`);
      // }
      // if (res.data.status == true) {
      // setOpen(false);
      // toast.success(
      // `Appointment for ${res.data.data.patient_name} booked successfully Added Successfully`
      // );
      // console.log(res.data.data)
      // }
      dispatch(bookPatientAppointmentAsync(data));
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };
  //onsubmit form function

  // Delete Funtion
  const handleDelete = async () => {
    try {
      //   const res = await axiosClient.delete(
      //     `${webURLs.DELETE_APPOINTMENT}?id=${patientDetails[0]._id}`
      //   );
      //   if (res.status == 200) {
      //     toast.success("Appointment is canceled");
      //   setOpen(false);
      //   Navigate("/")
      // }
      dispatch(cancelPatientAppointmentsAsync(patientDetails[0]._id));
    } catch (error) {
      toast.error(`something went wrong`);
    }
  };

  useEffect(() => {
    axiosClient()
      .get(`${webURLs.GET_APPOINTMENT}?date=${date}&time=${time}`)
      .then((response) => {
        response.data.data?.length === 1
          ? setIsBooked(true)
          : setIsBooked(false);
        setPatientDetails(response.data.data);
      });
  }, []);
  // console.log(patientDetails);

  useEffect(() => {}, [isNewPatient]);
  // console.log();
  return (
    <div className="  bg-slate-100  flex min-h-full flex-1 flex-col justify-center px-6 py-4 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className=" mb-3  text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Appointment
        </h2>

        <div className=" flex  float-left">
          
            <button
              className="flex w-fit justify-center rounded-md bg-red-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setIsNewPatient((prevState) => !prevState)}
            >
              New Patient
            </button>
         
        </div>

        <hr className=" shadow-2xl" />
      </div>

      <div className="mt-6 sm:mx-auto  sm:grid sm:grid:cols-2 sm:w-full sm:max-w-sm">
        {/* for new patient */}
        
          <form className="space-y-6 " onSubmit={handleSubmit(onSubmit)}>
            <div className=" grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Patient's Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    defaultValue={patientDetails[0]?.patient_name}
                    autoComplete="name"
                    {...register("patient_name", {
                      required: "*Patient name is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="patient_name" />
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Gender
                </label>
                <div className="mt-2">
                  <select
                    id="gender"
                    name="gender"
                    value={patientDetails[0]?.gender}
                    autoComplete="Select"
                    // type="text"
                    {...register("gender", {
                      required: "*Gender is required",
                    })}
                    className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">-Select-</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="gender" />
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="scheduleddate"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Scheduled Date
                </label>
                <div className="mt-2">
                  <input
                    id="scheduleddate"
                    name="scheduleddate"
                    type="date"
                    defaultValue={date}
                    autoComplete="date"
                    {...register("date", {
                      required: "*Date this is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="date" />{" "}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="scheduledtime"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Scheduled Time
                  </label>
                  <div className="text-sm"></div>
                </div>
                <div className="mt-2">
                  <input
                    id="scheduledtime"
                    name="scheduledtime"
                    type="text"
                    value={time}
                    autoComplete="time"
                    {...register("time", {
                      required: "*Time is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="time" />
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="age"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Age
                </label>
                <div className="mt-2">
                  <input
                    id="age"
                    name="age"
                    type="number"
                    defaultValue={patientDetails[0]?.age}
                    maxLength="2"
                    autoComplete="age"
                    pattern="\d+"
                    {...register("age", {
                      required: "*Age is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    {" "}
                    <ErrorMessage errors={errors} name="age" />{" "}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="doctor"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Doctor Name
                </label>
                <div className="mt-2">
                  <select
                    id="doctor"
                    name="doctor"
                    type="text"
                    value={patientDetails[0]?.doctor}
                    autoComplete="doctor"
                    {...register("doctor", {
                      required: "*Doctor Name is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">-Select-</option>
                    {/* {doctors.map((doctor) => (
                      <option key={doctor} value={doctor.id}>{doctor.name}</option>
                    ))} */}
                    <option value="660e662b102ebe2a3e2441ca">
                      Dr.Sumit Rathi
                    </option>
                    <option value="660e684ecc1f62dadcb1818e">
                      Dr.Ankita Rathi
                    </option>
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="doctor" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mobile No.
                </label>
                <div className="mt-2">
                  <input
                    id="mobile"
                    defaultValue={patientDetails[0]?.mobile}
                    name="mobile"
                    {...register("mobile", { required: "*Mobile is required" })}
                    type="number"
                    autoComplete="mobile"
                    maxLength="10"
                    minLength="10"
                    pattern="\d+"
                    {...register("mobile", {
                      required: "*Mobile No. is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    {" "}
                    <ErrorMessage errors={errors} name="mobile" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="treatment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Treatment
                </label>
                <div className="mt-2">
                  <select
                    id="treatment"
                    name="treatment"
                    type="text"
                    value={patientDetails[0]?.treatment}
                    autoComplete="doctor"
                    {...register("treatment", {
                      required: "*Treatment is required",
                    })}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">-Select-</option>
                <option value="RTC 1">RTC 1</option>
                <option value="RTC 2">RTC 2</option>
                <option value="RTC 3">RTC 3</option>
                <option value="scaling and curettage with pollish">Scaling And Curettage With Pollish</option>
                <option value="extraction">Extraction</option>
                <option value="disimpaction">Disimpaction</option>
                <option value="orthodontic treatment">Orthodontic treatment</option>
                <option value="fixed partial denture">Fixed Partial Denture</option>
                <option value="removable partial denture">Removable Partial Denture</option>
                <option value="complete dentures">Complete Denture</option>
                <option value="smile designing">Smile Designing</option>
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="treatment" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Priority
                </label>
                <div className="mt-2">
                  <select
                    id="priority"
                    name="priority"
                    value={patientDetails[0]?.priority}
                    autoComplete="Select"
                    // type="text"
                    {...register("priority", {
                      required: "*Priority is required",
                    })}
                    className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">-Select-</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="priority" />
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              {!isBooked ? (
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={() => setOpen(true)}
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-800 px-3 py-1  text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                  Cancel Appointment
                </button>
              )}
            </div>
            <ModalComponent
              content={
                <div className="flex items-center justify-center flex-col">
                  {" "}
                  <div>
                    <h1 className="font-semibold">
                      Do you want to cancel Appointment of{" "}
                      {patientDetails[0]?.patient_name}?
                    </h1>
                  </div>
                  <div className="mt-3">
                    <button
                      onClick={handleDelete}
                      className="py-2 px-3 bg-green-600 rounded-lg text-white cursor-pointer hover:bg-green-300 duration-300"
                    >
                      Agree !
                    </button>
                  </div>
                </div>
              }
              open={open}
              setOpen={setOpen}
            />
          </form>
        
      </div>
    </div>
  );
};

export default NewPatientAppointmentForm;
