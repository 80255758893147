const UserPictureForm = ({ avt }) => {
    const img = "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352010-stock-illustration-default-placeholder-man-and-woman.jpg"
    return (
        <>
            <div className="p-3 border-2 rounded gap-2 flex items-center flex-col">
                <img src={avt || img} alt="user" className="size-56 rounded-full" />
                <input className="outline-2 border-2 p-1 my-1 rounded" type="file" name="picture" id="picture" />
            </div>
            <div>
                <button type="submit" className="bg-[#301762] text-white text-lg cursor-pointer hover:text-[#301762] hover:bg-gray-200 duration-300 p-1 my-1 rounded-lg">Save</button>
            </div>
        </>
    )
}

export default UserPictureForm;