import React, { useEffect, useState } from 'react'
import SquareButton from '../../components/Ui Components/SquareButton'
import { ImLab } from "react-icons/im";
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { addEmployee, deleteEmployee, getAllEmployees } from '../../redux/employees/employeesApi';
import toast from 'react-hot-toast';

export default function EmployeeDetails() {

    const [Employees, setEmployees] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    async function handleAddEmployee(data) {
        setIsLoading(true)
        try {
            let res = await addEmployee(data)
            if (res.status) {
                setEmployees([...Employees, res.data])
                toast.success(res.message)
                reset()
            }
        } catch (error) {
            console.log(error.message);
        }
        setIsLoading(false)
    }

    // for delete lab Data
    async function handleEmployeeDelete(empId) {
        const is = window.confirm("Are You Sure ?");
        if (is) {
            try {
                let res = await deleteEmployee(empId)
                if (res.status) {
                    toast.success(res.message)
                    setEmployees(Employees.filter((emp) => emp._id !== res.data._id))
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        (async function () {
            try {
                let res = await getAllEmployees()
                if (res.status) {
                    setEmployees(res.data)
                }
            } catch (error) {
                console.log(error);
            }
        })()
    }, [])

    return (
        <>
            <div className="flex flex-col md:flex-row w-full p-0">
                {/* First Box - One Third Width */}
                <div className="w-full md:w-1/3 p-1 rounded-lg md:mb-0">
                    <div id="uploadfile-form-section" className="rounded bg-white border-2 pb-3" >
                        <h1 className="text-sm py-2 text-center  bg-gray-300 text-[#301762]">
                            Employee Registration Form
                        </h1>
                        <div className="max-w-md mx-auto p-6 bg-white rounded-lg">
                            <form onSubmit={handleSubmit(handleAddEmployee)}>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-600 mb-2">
                                        Employee Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        {...register("name", {
                                            required: "*Employee name is required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Employee Name"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="name" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="gender" className="block text-sm font-medium text-gray-600 mb-2">
                                        Gender
                                    </label>
                                    <select
                                        name="mobile"
                                        id="mobile"
                                        {...register("gender", {
                                            required: "*Gender Is Required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    >
                                        <option value="">-Select-</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="gender" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-600 mb-2">
                                        email Id
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        {...register("email", {
                                            required: "*email is required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Email"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="email" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="mobile" className="block text-sm font-medium text-gray-600 mb-2">
                                        Mobile Number
                                    </label>
                                    <input
                                        type="text"
                                        name="mobile"
                                        id="mobile"
                                        {...register("mobile", {
                                            required: "*mobile Number Is Required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Your Mobile Number"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="mobile" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="qualification" className="block text-sm font-medium text-gray-600 mb-2">
                                        qualification
                                    </label>
                                    <input
                                        name="qualification"
                                        id="qualification"
                                        {...register("qualification", {
                                            required: "*qualification is required",
                                        })}
                                        className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter qualification"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="qualification" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-600 mb-2">
                                        Password
                                    </label>
                                    <input
                                        type="text"
                                        name="password"
                                        id="password"
                                        {...register("password", {
                                            required: "*password Is Required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Your Password"
                                    />
                                    <div className="text-red-600 text-sm">
                                        <ErrorMessage errors={errors} name="password" />
                                    </div>
                                </div>
                                <SquareButton
                                    isLoading={isLoading}
                                    sqicon={<span className='text-xs'>Add New</span>}
                                    className={"w-full bg-[#301762] text-white p-2"}
                                    btnType={"submit"}
                                />
                            </form>
                        </div>
                    </div>
                </div>

                {/* Second Box - Two Thirds Width */}
                <div className="w-full md:w-2/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 h-[100%] overflow-y-auto w-full">
                        <div className="flex bg-gray-300 pl-2 rounded-t-sm text-white items-center sticky top-0">
                            <ImLab className="text-xl p-0 m-0" />
                            <h1 className="pl-3 text-sm py-2 text-[#301762]">
                                Employees ({Employees && Employees.length})
                            </h1>
                        </div>
                        {/* //======================file display table section start============================= */}
                        <div className="overflow-x-auto">
                            <table className="min-w-full text-xs bg-white rounded-lg">
                                <thead>
                                    <tr className="bg-gray-100 text-gray-600 text-center">
                                        <th className="py-2 px-2 font-medium">Sr No</th>
                                        <th className="py-2 px-2 font-medium">Name</th>
                                        <th className="py-2 px-2 font-medium">Email</th>
                                        <th className="py-2 px-2 font-medium">Mobile</th>
                                        <th className="py-2 px-2 font-medium">Status</th>
                                        <th className="py-2 px-2 font-medium">Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Employees && Employees.map((item, idx) => (
                                        <tr className="border-t border-gray-200 hover:bg-gray-50 text-gray-700 text-center" key={idx}>
                                            <td className="py-2 px-2">{idx + 1}</td>
                                            <td className="py-2 px-2"><Link className='hover:text-blue-800' to={`/employees/${item._id}`} >{item.name}</Link></td>
                                            <td className="py-2 px-2">{item.email}</td>
                                            <td className="py-2 px-2">{item.mobile}</td>
                                            <td className="px-2 py-2 text-green-600 text-center border-0 cursor-pointer hover:text-blue-600">
                                                {item?.isActive ? <span className="text-[#28ac28] px-4 py-1 rounded-full bg-[#caffca]">Active</span> : <span className="text-[#f91e1e] px-4 py-1 rounded-full bg-[#ffb9b9]">Deactive</span>}
                                            </td>
                                            <td className="py-2 px-2">
                                                <button onClick={() => { handleEmployeeDelete(item._id) }} className="text-red-500 hover:text-red-400 text-xl" title="Delete Employee"><FaTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
