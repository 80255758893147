import React, { useEffect, useState } from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import { DateFormat } from "../../utils/helper";
import ModalComponent from "../../components/modal/ModalComponent";
import UserProfileForm from "./UserProfileForm";
import UserPictureForm from "./UserPictureForm";
import { useParams } from "react-router-dom";
import { loggedInUserDetails } from "../../redux/user/userAPI";

function Userprofile() {
  const { id } = useParams()
  const [userDetails, setUserDetails] = useState();
  const dummyImage = "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352010-stock-illustration-default-placeholder-man-and-woman.jpg"

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        let user = await loggedInUserDetails(id)
        if (user.status) {
          setUserDetails(user.data)
        }
      } catch (error) {
        console.log(error);
      }
    })()
  }, [id])

  return (
    <div>
      <div className="container mx-auto my-5">
        <div className="md:flex no-wrap md:-mx-2 ">
          {/* Left Side */}
          <div className="w-full md:w-3/12 md:mx-2 shadow border-2">
            {/* Profile Card */}
            <div className="bg-white p-3 ">
              <div
                className=" h-40 w-40 image mx-auto overflow-hidden rounded-[50%] border-4 border-[#301762] shadow-mg shadow-black bg-red"
                id="edit_profile_pic" title="Edit Profile"
                onClick={() => { setOpen(true); setModalContent(<UserPictureForm avt={userDetails?.avatar} />) }}
              >
                <img
                  className="h-auto w-full mx-auto tool"
                  src={userDetails?.avatar?userDetails?.avatar:dummyImage}
                  alt="profile_img"
                  id="tooltip-default"
                  loading="lazy"
                />
              </div>
              <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                {userDetails?.name}
              </h1>
              <h3 className="text-gray-600 font-lg text-semibold leading-6">
                {userDetails?.qualification}
              </h3>
              <h3 className="text-gray-600 font-lg text-semibold leading-6">
                {userDetails?.specialization}
              </h3>
              <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                <li className="flex items-center py-3">
                  <span>Status</span>
                  <span className="ml-auto">
                    <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                      {userDetails?.isActive ? "Active" : "Deactive"}
                    </span>
                  </span>
                </li>
                <li className="flex items-center py-3">
                  <span>Member since</span>
                  <span className="ml-auto">{DateFormat(userDetails?.createdAt)}</span>
                </li>
              </ul>
            </div>
            {/* End of profile card */}
          </div>
          {/* Right Side */}
          <div className="w-full md:w-9/12 mx-2">
            {/* Profile tab */}
            {/* About Section */}
            <><div className="bg-white p-3 shadow border-2 rounded">
              <div id="heading" className="flex items-center justify-center gap-2 font-semibold text-gray-900 leading-8">
                <span className="text-[#301762]">
                  <FaUser />
                </span>
                <span className="tracking-wide">About</span>
              </div>


              <div className="grid md:grid-cols-1 gap-1 text-gray-700" >
                <div className="grid grid-cols-2">
                  <div className="p-2 font-semibold flex items-center justify-start">Full Name :-</div>
                  <div className="p-2">
                    {userDetails?.name}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="p-2 font-semibold flex items-center justify-start">Role :-</div>
                  <div className="p-2">{userDetails?.role === 0 ? "Receptionist" : "Doctor"}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="p-2 font-semibold flex items-center justify-start">Contact No :-</div>
                  <div className="p-2">+91
                    {userDetails?.mobile}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="p-2 font-semibold flex items-center justify-start">Email :-</div>
                  <div className="p-2 lowercase">
                    <a
                      className="text-blue-800"
                      href={`mailto:${userDetails?.email}`}
                    >
                      {userDetails?.email}
                    </a>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="p-2 font-semibold flex items-center justify-start">Gender :-</div>
                  <div className="p-2">{userDetails?.gender}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="p-2 font-semibold flex items-center justify-start">
                    Permanent Address :-
                  </div>
                  <div className="p-2">
                    {userDetails?.address}
                  </div>
                </div>

              </div>

            </div>

              <button className="text-[#301762] text-2xl font-bold hover:bg-[#301762] rounded hover:text-white focus:outline-none focus:shadow-outline focus:bg-[#301762] focus:text-white hover:shadow-xs p-2 my-2">
                <FaEdit onClick={() => { setOpen(true); setModalContent(<UserProfileForm userDetails={userDetails} />) }} />
              </button>
            </>
            {/* End of about section */}

            {/* End of profile tab */}
          </div>
        </div>
      </div>
      <ModalComponent
        content={modalContent}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

export default Userprofile;
