import React from 'react'
import { useSelector } from 'react-redux'
import { selectLoggedInUser } from '../redux/user/userSlice'
import { Navigate, Outlet } from 'react-router-dom';

export default function DoctorRoute() {

    const User = useSelector(selectLoggedInUser);

    return (
        <>
            {User.role ? <Outlet /> : <Navigate to="/" />}
        </>
    )
}
