import { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SquareButton from "../Ui Components/SquareButton";
import { addNewPatientAsync } from "../../redux/patientAppointments/patientSlice";
import { selectLoggedInUser } from "../../redux/user/userSlice";
import { selectAllPatient } from "../../redux/patientAppointments/patientSlice";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();


const AddPatient = () => {
  const maritalStatus = "single"
  const [selectedDisease, setSelectedDisease] = useState("");
  const loggedInUser = useSelector(selectLoggedInUser);
  const patients = useSelector(selectAllPatient);
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const [doctors, setDoctors] = useState();
  const [fileNumber, setFileNumber] = useState()
  const allDisease =[
    "Diabetes", "Hypertension", "Thyroid", "Blood thinners", "Any Drug Allergy", "Smoking", "Chewing Tobacco", "NAD", "Other"
  ]
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [Options, setOptions] = useState([])

  const onSubmit = async (data) => {
    setIsLoading(true)
    data.file_number = fileNumber
    try {
      let isFile = patients?.filter((item) => item.file_number === data.file_number)
      if (isFile.length > 0) {
        toast.error("Patient file Number Is Already Registered");
        setIsLoading(false)
        return;
      }
      dispatch(
        addNewPatientAsync({
          ...data,
          user: loggedInUser._id,
          disease: selectedDisease,
        })
      );
      setIsLoading(false)
      setSelectedOptions([])
      reset();
    } catch (error) {
      toast.error(`${error.message}`);
      setIsLoading(false)
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  // console.log(selectedDisease)

  // select multiple Disease
  const handleChange = (selected) => {
    setSelectedOptions(selected)
    let arr = []
    selected.forEach((item) => {
      arr.push(item.value)
    })
    setSelectedDisease(arr.join(" "));
  }

  useEffect(() => {
    function options() {
      let arr = allDisease && allDisease.map((item) => (
        { value: item.replaceAll(" ", "-"), label: item }))
      setOptions(arr)
    }
    options()
  }, [])

  useEffect(() => {
    async function users() {
      try {
        let user = await axiosClient().get(webURLs.GET_DOCTORS)
        if (user.data.status) {
          setDoctors(user.data.data)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    users()
  }, [])

  useEffect(() => {
    setFileNumber(`${parseInt(patients[patients.length - 1]?.file_number) + 1}`)
  }, [patients])

  return (
    <div className="sm:mx-auto w-[100%] bg-white  border-b-[1px]  py-8 px-14">
      <form className="space-y-6 " onSubmit={handleSubmit(onSubmit)}>
        <div className=" grid grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="patient_name"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Patient's Name
            </label>
            <div className="mt-2">
              <input
                id="patient_name"
                {...register("patient_name", {
                  required: "*Patient Name is required",
                })}
                name="patient_name"
                type="text"
                autoComplete="patient_name"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="patient_name" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="surname"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Surname
            </label>
            <div className="mt-2">
              <input
                id="surname"
                {...register("surname", {
                  required: "*Surname is required",
                })}
                name="surname"
                type="text"
                autoComplete="surname"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                <ErrorMessage errors={errors} name="surname" />{" "}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="gender"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Gender
            </label>
            <div className="mt-2">
              <select
                id="gender"
                name="gender"
                {...register("gender", {
                  required: "*Gender is required",
                })}
                autoComplete="Select"
                className="block w-full text-sm rounded-md border-0 py-2 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm h-9"
              >
                <option value="" >-Select-</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="gender" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="filenumber"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              File Number
            </label>
            <div className="mt-2">
              <input
                id="filenumber"
                name="filenumber"
                {...register("file_number")}
                type="text"
                value={patients.length > 0 ? fileNumber : "5001"}
                autoComplete="filenumber"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
                readOnly={patients.length > 0 ? true : false}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="age"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Age
            </label>
            <div className="mt-2">
              <input
                id="age"
                name="age"
                type="tel"
                {...register("age", {
                  required: "*Age is required",
                })}
                maxLength="2"
                autoComplete="age"
                pattern="\d+"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="age" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="mobile"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Mobile No.
            </label>
            <div className="mt-2">
              <input
                id="mobile"
                name="mobile"
                {...register("mobile", {
                  required: "*Mobile no. is required",
                })}
                type="tel"
                autoComplete="mobile"
                maxLength="10"
                minLength="10"
                pattern="\d+"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="mobile" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="mobile"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                {...register("email")}
                type="email"
                autoComplete="email"
                // pattern="\d+"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="email" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Address
            </label>
            <div className="mt-2">
              <input
                id="address"
                {...register("address")}
                name="address"
                type="text"
                autoComplete="address"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="doctor"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Doctor
            </label>

            <div className="mt-2">
              <select
                id="doctor"
                name="doctor"
                {...register("doctor", {
                  required: "*Doctor is required",
                })}
                // type="text"
                autoComplete="Select"
                className="block w-full text-sm rounded-md border-0 py-2 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm h-9"
              >
                <option value="">-Select-</option>
                {doctors?.map((doctor, index) => (
                  <option key={index} value={doctor._id}>
                    {doctor.name}
                  </option>
                ))}
              </select>
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="doctor" />
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="dob"
                className="block text-sm font-medium leading-6 text-gray-600"
              >
                Date Of Birth (optional)
              </label>
            </div>
            <div className="mt-2">
              <input
                id="dob"
                name="dob"
                {...register("date_of_birth")}
                type="date"
                autoComplete="dob"
                className="block w-full text-sm rounded-md border-0 py-2 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm h-9"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="marital_status"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Marital Status (optional)
            </label>

            <div className="mt-2">
              <select
                id="marital_status"
                {...register("marital_status", {
                  required: "*marital_status is required",
                })}
                name="marital_status"
                autoComplete="Select"
                className="block w-full text-sm rounded-md border-0 py-2 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm h-9"
              >
                <option value="">-Select-</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="widow">other</option>
              </select>
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="marital_status" />
              </div>
            </div>
          </div>

          {(maritalStatus === "married") && (
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="dob"
                  className="block text-sm font-medium leading-6 text-gray-600"
                >
                  Date Of Anniversary
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="dob"
                  name="dob"
                  {...register("anniversary_date")}
                  type="date"
                  autoComplete="dob"
                  className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
                />
                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                  <ErrorMessage errors={errors} name="anniversary_date" />
                </div>
              </div>
            </div>
          )}
          <div>
            <label
              htmlFor="doctor"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Disease
            </label>

            <div className="mt-2">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={selectedOptions}
                isMulti
                options={Options}
                onChange={handleChange}
                classNames={{ control: (state) => state.className ? "w-full text-sm rounded-md border-1 text-gray-600 shadow-sm placeholder:text-gray-400 h-9 focus:ring-[#9F78FF]" : "" }}
              />
              {selectedDisease === "Other" && (
                <div className="mt-2">
                  <input
                    type="text"
                    id="other_disease"
                    {...register("other_disease")}
                    name="other_disease"
                    placeholder="Enter other disease"
                    className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="importance"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Priority
            </label>
            <div className="mt-2">
              <select
                id="priority"
                name="importance"
                autoComplete="Select"
                // type="text"
                {...register("importance", {
                  required: "*Priority is required",
                })}
                className="block w-full text-sm rounded-md border-0 py-2 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm h-9"
              >
                <option value="">-Select-</option>
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="importance" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="patient_history"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Patient History
            </label>
            <div className="mt-2">
              <input
                id="patient_history"
                {...register("patient_history")}
                name="patient_history"
                type="text"
                list="entry"
                defaultValue=""
                autoComplete="patient_history"
                className="text-sm block w-full rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <datalist id="entry">
                <option>Old Patient</option>
                <option>New Patient</option>
              </datalist>
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="patient_history" />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="patient_history"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              Reference By
            </label>
            <div className="mt-2">
              <textarea
                id=""
                {...register("reference")}
                name="reference"
                type="text"
                defaultValue=""
                autoComplete="reference"
                className="block w-full  text-sm rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] outline-none sm:text-sm"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="reference" />
              </div>
            </div>
          </div>
        </div>
        {/* Button */}
        <SquareButton
          sqicon={<span className="text-sm">Add Patient</span>}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default AddPatient;
