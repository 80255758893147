import { axiosClient } from "../../webServices/WebURL"
import {divideIntervals} from "../helper/divideIntervals"

export function fetchMorningSlots(bookingDay){
    return new Promise(async(resolve)=>{
        const res = await axiosClient().get(`api/v2/slot/get?day=${bookingDay}`)
        const st = Date.parse(res.data.data[0].openningTime);
        const ct = Date.parse(res.data.data[0].closingTime);
        const gap = parseInt(res.data.data[0].duration * 60000);
        const slot =  divideIntervals(st, ct, gap);
        resolve(slot)
    })
}
export function fetchEveningSlots(bookingDay){
    return new Promise(async(resolve)=>{
        const res = await axiosClient().get(`api/v2/eveningSlot/get?day=${bookingDay}`)
        const st = Date.parse(res.data.data[0].openningTime);
        const ct = Date.parse(res.data.data[0].closingTime);
        const gap = parseInt(res.data.data[0].duration * 60000);
        const slot =  divideIntervals(st, ct, gap);
        resolve(slot)
    })
}