import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW TREATMENT API =======================
export function addProcedure(patientId, treatmentDetails) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().post(
            `${webURLs.ADD_PROCEDURE}${patientId}`,
            treatmentDetails
        );
        resolve(response.data);
    });
}
//=================== GET ALL TREATMENT API =======================
export function getAllProcedure(id) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().get(`${webURLs.GET_PROCEDURE}${id}`);
        resolve(response.data)
    });
}

//========================= DELETE TREATMENT API ==================

export function deleteProcedure(treatmentId) {
    return new Promise(async (resolve) => {
        const response = await axiosClient().delete(
            `${webURLs.DELETE_PROCEDURE}/${treatmentId}`
        );
        resolve(response.data);
    });
}
