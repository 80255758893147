import React, { useCallback, useEffect, useState } from 'react'
import SquareButton from '../../components/Ui Components/SquareButton'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { DateFormat } from '../../utils/helper'
import { TiTick } from 'react-icons/ti'
import { FaClipboardList, FaTrash } from 'react-icons/fa';
import { addTask, deleteTask, getAllTask, updateTask } from '../../redux/todolist/toDoListApi';
import toast from 'react-hot-toast';

export default function ToDoList() {

    const [isLoading, setIsLoading] = useState(false)
    const [todayDate, setTodayDate] = useState(DateFormat(Date.now()))
    const [toDoList, setToDoList] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // add New Task
    async function handleAddLab(data) {
        setIsLoading(true)
        data.date = DateFormat(Date.now())
        try {
            let res = await addTask(data)
            if (res.status) {
                toast.success("Task Added")
                setToDoList([...toDoList, res.data])
                reset()
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    // for delete Task
    async function handleTaskDelete(taskId) {
        const is = window.confirm("Are You Sure ?");
        toast.loading("Loading...")
        try {
            if (is) {
                let response = await deleteTask(taskId)
                if (response.status) {
                    toast.dismiss()
                    setToDoList(toDoList.filter(item => item._id !== response.data._id))
                    toast.success("Deleted Sucessfully")
                } else {
                    toast.dismiss()
                }
            }
        } catch (error) {
            console.log(error);
            toast.dismiss()
        }
    }

    // for task Complite 
    async function handleTaskDone(taskId) {
        const is = window.confirm("Are You Sure ?");
        toast.loading("Loading...")
        try {
            if (is) {
                let response = await updateTask(taskId)
                if (response.status) {
                    toast.dismiss()
                    let arr = toDoList.filter(item => item._id !== response.data._id)
                    setToDoList([...arr, response.data])
                    toast.success("Complited Sucessfully")
                } else {
                    toast.dismiss()
                }
            }
        } catch (error) {
            console.log(error);
            toast.dismiss()
        }
    }

    let getTask = useCallback(async (date) => {
        try {
            let list = await getAllTask(date ? date : todayDate)
            if (list.status) {
                setToDoList(list.data)
            } else {
                setToDoList([])
            }
        } catch (error) {
            console.log(error);
        }

    }, [todayDate])

    useEffect(() => {
        getTask()
    }, [getTask])

    // filter functionality start
    // clear filter
    function clearFilter() {
        setTodayDate((value) => value = DateFormat(Date.now()))
        getTask()
    }

    // for filter by date
    const handleFilter = (e) => {
        e.preventDefault()
        let curent = DateFormat(Date.now())
        if (curent !== todayDate) {
            getTask()
        }
    };

    return (
        <>
            <div className="flex flex-col md:flex-row w-full p-1">
                {/* First Box - One Third Width */}
                <div className="w-full md:w-1/3 p-1 rounded-lg md:mb-0">
                    <div id="uploadfile-form-section" className="rounded bg-white border-2 pb-3" >
                        <h1 className="text-sm py-2 text-center  bg-gray-300 text-[#301762]">
                            Task Form
                        </h1>
                        <div className="max-w-md mx-auto p-6 bg-white rounded-lg">
                            <form onSubmit={handleSubmit(handleAddLab)}>
                                <div className="mb-4">
                                    <label htmlFor="task_name" className="block text-sm text-gray-600 font-medium mb-2">
                                        Task Name
                                    </label>
                                    <textarea
                                        name="task_name"
                                        id="task_name"
                                        {...register("task_name", {
                                            required: "*Task is required",
                                        })}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                        placeholder="Enter Task"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="task_name" />
                                    </div>
                                </div>
                                <SquareButton
                                    isLoading={isLoading}
                                    sqicon={<span className='text-xs'>Add Task</span>}
                                    className={"w-full bg-[#301762] text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-[#301762]"}
                                    btnType={"submit"}
                                />
                            </form>
                        </div>
                    </div>
                </div>

                {/* Second Box - Two Thirds Width */}
                <div className="w-full md:w-2/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 overflow-y-auto w-full">
                        <div className="flex bg-gray-300 justify-between pl-2 p-2 rounded-t-sm text-white items-center sticky top-0">
                            <div className='flex items-center'>
                                <FaClipboardList className="text-2xl p-0 m-0" />
                                <h1 className="pl-3 text-sm py-2 text-[#301762]">
                                    Toady's Task ({toDoList?.length})
                                </h1>
                            </div>
                            <div className='flex gap-3'>
                                <form action="" className="flex gap-2" onSubmit={handleFilter}>
                                    <input
                                        className="p-2 text-sm rounded-sm text-gray-600"
                                        type="date"
                                        defaultValue={todayDate.split("-").reverse().join("-")}
                                        onChange={(e) => { setTodayDate(DateFormat(e.target.value)) }}
                                    />
                                    <SquareButton
                                        btnType={"submit"}
                                        sqicon={<span className="text-xs">Search</span>}
                                    />
                                    <SquareButton
                                        btnType={"reset"}
                                        handleClick={clearFilter}
                                        // disable={DateFormat(Date.now()) === todayDate}
                                        sqicon={<span className="text-xs">Today List</span>}
                                    />
                                </form>
                            </div>
                        </div>
                        {/* //======================file display table section start============================= */}
                        {toDoList.length > 0 ? <div className="overflow-x-auto">
                            <table className="min-w-full bg-white rounded-lg">
                                <thead>
                                    <tr className="bg-gray-100 text-center text-sm">
                                        <th className="py-2 px-2 font-medium text-gray-600">Sr No</th>
                                        <th className="py-2 px-2 font-medium text-gray-600">Task</th>
                                        <th className="py-2 px-2 font-medium text-gray-600">Date</th>
                                        <th className="py-2 px-2 font-medium text-gray-600">Status</th>
                                        <th className="py-2 px-2 font-medium text-gray-600">Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {toDoList && toDoList.map((item, idx) => (
                                        <tr className="border-t border-gray-200 hover:bg-gray-50 text-center text-xs" key={idx}>
                                            <td className="py-2 px-2 text-gray-700">{idx + 1}</td>
                                            <td className="py-2 px-4 text-gray-700">{item.task_name}</td>
                                            <td className="py-2 px-1 text-gray-700">{item.date}</td>
                                            <td className="py-2 px-2 text-gray-700 text-center">
                                                {item.isComplite ? <div className='flex justify-center'><TiTick className='text-yellow-500' fontSize={30} /></div> : <button onClick={() => { handleTaskDone(item._id) }} title='Task Complete' className="text-xs text-green-500 border border-green-500 hover:text-white hover:bg-green-500 p-2 rounded-sm text-center">Done</button>}
                                            </td>
                                            <td className="py-2 px-2 text-gray-700">
                                                <button onClick={() => { handleTaskDelete(item._id) }} className="text-red-500 hover:text-red-400 text-xl" title="Delete Task"><FaTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> :
                            <div className='flex justify-center'>
                                <h2 className='mt-4 mb-4'>No Task</h2>
                            </div>
                        }
                    </section>
                </div>
            </div>
        </>
    )
}
