import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateFormat, paginationHelper, Prifix } from "../../utils/helper";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { getAllVoiceRecording } from "../../redux/voiceRecordSection/voiceApis";

const VoiceMessagePage = () => {
    const [Recording, setRecordings] = useState([])
    const [filteredPatient, setFilter] = useState([])

    const itemPerPage = 10
    const [page, setPage] = useState(1)
    const [selectedPage, setSelectedPage] = useState(1)

    const arrLength = paginationHelper(Recording?.length, itemPerPage)

    const {
        register,
        handleSubmit,
        reset,
    } = useForm();

    const handleFilter = (data) => {
        let from = DateFormat(data.from)
        let filteredPatient = Recording && Recording.filter((item) => DateFormat(item.createdAt) === from && item);
        if (filteredPatient.length === 0) {
            toast.error("No Recordings Available")
        }
        setFilter(filteredPatient)
    };

    function clearFilter() {
        setFilter([])
        reset()
    }

    useEffect(() => {
        (async function () {
            try {
                let response = await getAllVoiceRecording()
                if (response.status) {
                    setRecordings(response.data)
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        })()
    }, [])

    return (
        <>
            <div className="mt-1 items-baseline w-full p-1 flex justify-between bg-gray-200 border">
                <h1 className="text-sm font-medium text-[#301762] mb-1 ml-3">
                    Voice Messages({filteredPatient?.length > 0 ? filteredPatient.length : Recording?.length})
                </h1>
                <div className="flex gap-2">
                    <form action="" className="flex gap-2" onSubmit={handleSubmit(handleFilter)}>
                        <input
                            className="p-2 rounded-sm text-xs"
                            type="date"
                            {...register("from")}
                        />
                        <SquareButton
                            btnType={"submit"}
                            sqicon={<span className="text-sm">Search</span>}
                        />
                    </form>
                    <SquareButton
                        handleClick={clearFilter}
                        sqicon={<span className="text-sm">Reset</span>}
                    />
                </div>
            </div>
            <div className="relative rounded-b-lg border-2 order-solid overflow-y-scroll">
                {Recording?.length ? (
                    <>
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50  sticky top-0">
                                <tr className="text-center">
                                    <th className="px-2 py-2 font-medium">
                                        Sr.No.
                                    </th>
                                    <th className="px-2 py-2 font-medium">
                                        Patient Name
                                    </th>
                                    <th className="px-2 py-2 font-medium">
                                        Note
                                    </th>
                                    <th className="px-2 py-2 font-medium">
                                        Recordings
                                    </th>
                                    <th className="px-2 py-2 font-medium">
                                        Date
                                    </th>
                                    <th className="px-2 py-2 font-medium">
                                        Recorded By
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(filteredPatient?.length > 0 ? filteredPatient : Recording)?.slice((page * itemPerPage) - itemPerPage, page * itemPerPage).map((item, index) => (
                                    <tr
                                        className={`text-center text-gray-700 text-[13px] ${item?.patient?.isDelete ? "border-red-500 bg-red-100 border-l-2 border-r-2" : "odd:bg-white even:bg-blue-50 border-t-2"}`}
                                        key={index}
                                    >
                                        <td
                                            className="px-2 py-2 font-medium"
                                        >
                                            {(page * itemPerPage + index + 1 - 10)}
                                        </td>

                                        <td className="px-2 py-2 border-none">
                                            <Link
                                                className="text-gray-700 hover:text-blue-600"
                                                to={`/patient/patientdetails/${item?.patient?._id}`}
                                            >
                                                <h3 className={`px-4 py-2 ${item?.patient?.importance === "low" && "border-l-8 border-red-500"} ${item?.patient?.importance === "medium" && "border-l-8 border-green-500"} ${item?.patient?.importance === "high" && "border-l-8 border-yellow-500"}`}>
                                                    <span className={item?.patient?.isDelete ? "text-red-500" : ""}>
                                                        {Prifix(item?.patient)} {item?.patient?.patient_name}&nbsp;{item?.patient?.surname}
                                                    </span>
                                                </h3>
                                            </Link>
                                        </td>
                                        <td>{item.note}</td>
                                        <td>
                                            <audio controls>
                                                <source src={item.voice_url} type="audio/wav" />
                                            </audio>
                                        </td>
                                        <td>
                                            {DateFormat(item.createdAt)}
                                        </td>
                                        <td>
                                            {item.adder}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>) : (
                    <div className="w-full flex flex-col items-center justify-center">
                        <img src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527139.jpg?w=740&t=st=1715321245~exp=1715321845~hmac=ac03b291ef85c469e2935df5bdc8e42a6af065a8259681c1f1033ecd4776deba" height="310px" width="310px" alt="noData" />

                        <div className="flex flex-col items-center justify-center p-1">
                            <p className="text-3xl md:text-4xl lg:text-5xl text-gray-800 mt-1">
                                Data Not Found!
                            </p>
                            <p className="md:text-lg lg:text-xl text-gray-600 pb-4 pt-2">
                                Sorry, No Such Data Found
                            </p>
                            <Link className="p-2 border border-blue-900 rounded text-blue-900" to="/">Return Home</Link>
                        </div>
                    </div>
                )}
                <div className="|| PAGINATION || flex flex-row items-center justify-center my-2 gap-5 bg-[#eff6ff]">

                    <button className="flex flex-row items-center justify-center" onClick={() => {
                        if (page > 1) {
                            setPage(page - 1)
                            setSelectedPage(page - 1)
                        } else {
                            setPage(1)
                        }
                    }
                    }>
                        <IoMdArrowBack className=" cursor-pointer font-extrabold hover:scale-150 duration-300" />
                        <span>Previous</span>
                    </button>


                    {arrLength?.map((item, index) => (
                        <span className={`${selectedPage === index + 1 ? "bg-[#d2c2f1]" : ""} rounded-full py-1 px-3 cursor-pointer hover:scale-75 duration-75`} onClick={() => { setPage(index + 1); setSelectedPage(index + 1) }} >{item}</span>
                    ))}

                    <button className="flex flex-row items-center justify-center" onClick={() => {
                        if (page < arrLength.length) {
                            setPage(page + 1)
                            setSelectedPage(page + 1)
                        } else {
                            setPage(1)
                            setSelectedPage(1)
                        }
                    }
                    }>
                        <span>Next</span>
                        <IoMdArrowForward className=" cursor-pointer font-extrabold hover:scale-150 duration-300 " />
                    </button>

                </div>
            </div >
        </>
    );
};

export default VoiceMessagePage;
