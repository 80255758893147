// Import component
import * as COMPONENT_ROUTE from "../../routes/ComponentRoute";
import GreatingSection from "../../components/dashboardGrid/GreetingSection";

function Dashbord() {
  return (
    <>
      <div className="flex flex-col md:flex-row w-full">
        <div className="w-full md:w-5/5 p-2 rounded-md">
          <GreatingSection />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full">
        {/* <div className="w-full md:w-4/5 p-1 rounded-md">
          <COMPONENT_ROUTE.DATE_SLOT_CALENDER />
        </div> */}
        <div className="w-full md:w-5/5 p-2 rounded-md">
          <COMPONENT_ROUTE.DASHBOARD_GRID_COMPONENT />
        </div>
      </div>
      <div className="flex flex-col mt-0 md:flex-row w-full" id="dashboard-form-section">
        <div className="w-full md:w-5/8 sm:px-3 p-1 rounded-md">
          <COMPONENT_ROUTE.PATIENT_FORM />
        </div>
        <div className="w-full md:w-3/8 sm:px-3 p-1 rounded-md">
          <COMPONENT_ROUTE.RECENT_PATIENT />
        </div>
      </div>
    </>
  );
}

export default Dashbord;
