import React from 'react'
import { Prifix } from '../../utils/helper'
import { useSelector } from 'react-redux'
import { selectedPatient } from '../../redux/patientAppointments/patientSlice'
import { FaRegCircleUser } from 'react-icons/fa6'

const PatientCommunication = () => {
  const patientDetails = useSelector(selectedPatient)
  return (
    <div className='mb-2'>
      <div className="flex justify-between w-full p-3">
        <div className="flex">
          <span>
            <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
          </span>
          <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
            Patient Name -
          </p>
          <p className="text-gray-600 text-sm pt-[5px] pl-2">
            {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
          </p>
        </div>
        <div className="flex mr-2">
          <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
            File Number -
          </p>
          <p className="text-gray-600 text-sm pt-[5px] pl-2">
            {patientDetails?.file_number}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PatientCommunication
