import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW TASK API =======================
export async function addTask(task) {
    const response = await axiosClient().post(
        `${webURLs.ADD_TASK}`,
        task
    );
    return response.data;
}


//=================== GET ALL TASK API =======================
export async function getAllTask(date) {
    const response = await axiosClient().get(`${webURLs.GET_ALL_TASK}date=${date}`);
    return response.data;
}


//========================= DELETE TASK API ==================
export async function deleteTask(taskId) {
    const response = await axiosClient().delete(
        `${webURLs.DELETE_TASK}${taskId}`
    );
    return response.data;
}


//========================= UPDATE TASK API ==================
export async function updateTask(taskId) {
    const response = await axiosClient().put(
        `${webURLs.UPDATE_TASK}${taskId}`
    );
    return response.data;
}
