const adviceData = [
  {
    value: "Eat more fruits",
    label: "Eat more fruits",
  },
  {
    value: "Get 8 hours of sleep daily",
    label: "Get 8 hours of sleep daily",
  },
  {
    value: "Avoid aerated drinks like coke, pepsi, etc. During treatment",
    label: "Avoid aerated drinks like coke, pepsi, etc. During treatment",
  },
  {
    value: "Avoid alcohol intake",
    label: "Avoid alcohol intake",
  },
  {
    value: "Avoid smoking",
    label: "Avoid smoking",
  },
  {
    value: "Diet as advised",
    label: "Diet as advised",
  },
  {
    value: "Contact immediately in case of danger signs",
    label: "Contact immediately in case of danger signs",
  },
  {
    value: "Avoid cold water/ drinks/ ice cream/ refrigerated foods",
    label: "Avoid cold water/ drinks/ ice cream/ refrigerated foods",
  },
  {
    value: "Drink more water, maintain adequate hydration",
    label: "Drink more water, maintain adequate hydration",
  },
  {
    value: "Drink at least 3 litres of water daily",
    label: "Drink at least 3 litres of water daily",
  },
  {
    value:
      "Encourage fluid intake including ORS, fresh juices and coconut water",
    label:
      "Encourage fluid intake including ORS, fresh juices and coconut water",
  },
  {
    value: "Avoid oily food",
    label: "Avoid oily food",
  },
  {
    value: "Exercise regularly",
    label: "Exercise regularly",
  },
  {
    value: "Eat green leafy vegetables",
    label: "Eat green leafy vegetables",
  },
  {
    value:
      "Encourage intake of protein diet rich diet including all fruits, vegetables and pulses",
    label:
      "Encourage intake of protein diet rich diet including all fruits, vegetables and pulses",
  },
];

export default adviceData;
