import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SquareButton from "../Ui Components/SquareButton";
import { GiReceiveMoney } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { addFeeAsync, deleteFeeAsync, getAllFeeAsync, selectFee } from "../../redux/fee/feeSlice";

function Fee() {
  const fees = useSelector(selectFee)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  async function addFeeFun(data) {
    setIsLoading(true)
    try {
      dispatch(addFeeAsync(data))
      reset()
      setRefresh(!refresh)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  async function deleteFeeFu(id) {
    try {
      dispatch(deleteFeeAsync(id))
      setRefresh(!refresh)
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    dispatch(getAllFeeAsync())
  }, [refresh, dispatch])

  return (
    <>
      <div
        id="uploadfile-form-section"
        className="rounded-sm bg-white border-2 pb-3 mt-1"
      >
        <h1 className="pl-3 mb-4 text-sm font-medium py-2 text-center rounded-t-sm bg-gray-300 text-[#301762]">
          Add fees
        </h1>
        <form
          className="flex flex-col gap-3 items-start pl-3"
          onSubmit={handleSubmit(addFeeFun)}
        >
          {/* ===============form fields start================= */}
          <div className="grid grid-cols-3 w-full">
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="fee_name"
                className="bg-white left-2 top-3 text-sm w-fit font-medium text-gray-600 font-sans relative"
              >
                Fee Name
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="fee_name"
                {...register("fee_name", {
                  required: "*fee name fee is required",
                })}
                placeholder="Enter fee name"
                id="fee_name"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="fee_name" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="consultation"
                className="bg-white left-2 top-3 text-sm w-fit text-gray-600 font-medium font-sans relative"
              >
                Consultation fee
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="consultation"
                {...register("consultation", {
                  required: "*consultation fee is required",
                })}
                placeholder="Enter consultation Fee"
                id="consultation"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="consultation" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="x_ray"
                className="bg-white left-2 top-3 text-sm w-fit text-gray-600 font-medium font-sans relative"
              >
                X-ray fee
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="x_ray"
                {...register("x_ray", {
                  required: "*enter x_ray Fee",
                })}
                placeholder="Enter x_ray Fee"
                id="x_ray"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="x_ray" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="other_fee"
                className="bg-white left-2 top-3 text-sm w-fit text-gray-600 font-medium font-sans relative"
              >
                Other Fee
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="other_fee"
                defaultValue={0}
                {...register("other_fee", {
                  required: "*enter other Fee",
                })}
                placeholder="Enter other Fee"
                id="other_fee"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="other_fee" />
              </div>
            </div>
          </div>
          {/* ===============form fields start=================  */}
          <div >
            <SquareButton
              isLoading={isLoading}
              sqicon={<span className="text-sm">Add Fee</span>}
            />
          </div>
        </form>
      </div>
      <section
        id="medicine-section"
        className="rounded-sm border-2 mt-3 pb-3 h-96 overflow-y-auto w-full"
      >
        <div className="flex bg-gray-300 pl-2 rounded-t-sm text-white items-center sticky top-0">
          <GiReceiveMoney className="text-2xl p-0 m-0" />
          <h1 className="pl-3 text-sm font-medium py-2 text-[#301762]">
            Fees
          </h1>
        </div>
        {/* //======================file display table section start============================= */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-gray-800 sticky top-11">
            <tr className="">
              <th scope="col" className="text-center px-4 py-2 font-medium">
                S.No.
              </th>
              <th scope="col" className="text-center px-4 py-2 font-medium">
                Fee Name
              </th>
              <th scope="col" className="text-center px-4 py-2 font-medium">
                Consultation Fee
              </th>
              <th scope="col" className="text-center px-4 py-2 font-medium">
                X-ray Fee
              </th>
              <th scope="col" className="text-center px-4 py-2 font-medium">
                Other fee
              </th>
              <th scope="col" className="text-center px-4 py-2 font-medium">
                Option
              </th>
            </tr>
          </thead>
          <tbody className="capitalize">
            {fees && fees.map((item, ind) => (
              <tr key={ind} className="text-xs border-b-2">
                <td className="px-4 py-2 text-center font-medium">
                  {ind + 1}
                </td>
                <td className="px-4 py-2 text-center">
                  {item.fee_name}
                </td>
                <td className="px-4 py-2 text-center">
                  {item.consultation}
                </td>
                <td className="px-4 py-2 text-center">{item.x_ray}</td>
                <td className="px-4 py-2 text-center ">{item.other_fee}</td>
                <td className="px-4 py-2 text-center">
                  <div className="grid gap-2">
                    {item.isDelete ? <button
                      className="bg-green-600 hover:bg-green-400 hover:scale-90 duration-300 cursor-pointer text-white p-3 rounded-sm"
                      title="Delete Medicine"
                    >
                      Activate
                    </button> : <button
                      onClick={() => { deleteFeeFu(item._id) }}
                      className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-3 rounded-sm"
                      title="Delete Medicine"
                    >
                      Deactivate
                    </button>}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* //======================file display table section start============================= */}
      </section>
    </>
  );
}

export default Fee;
