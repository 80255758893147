// Component Routes

export { default as DASHBOARD_GRID_COMPONENT } from '../components/dashboardGrid/DashboardGrid'
export { default as DATE_PICKER } from '../components/dateRangePicker/Datepicker.jsx'
export { default as ADD_NEW_PATIENT } from '../components/forms/AddNewPatientAppointment.jsx'
export { default as NEW_PATIENT_FORM } from '../components/forms/NewPatientAppointmentForm.jsx'
export { default as HEADER } from '../components/header/Header'
export { default as LAYOUT } from '../components/layout/Layout'
export { default as LOGIN_FORM } from '../components/loginForm/LoginForm.jsx'
export { default as MODAL_COMPONENT } from '../components/modal/ModalComponent'
export { default as REPORT_GENERATE } from '../components/modal/Reportgenerate.jsx'
export { default as ADD_PATIENT } from '../components/patientComponents/AddPatient'
export { default as PATIENT_FILTER } from '../components/patientComponents/PatientFilter'
export { default as PATIENT_FORM } from '../components/patientComponents/Patientform'
export { default as RECENT_PATIENT } from '../components/patientComponents/RecentPatient'
export { default as SIDEBAR } from '../components/sideBar/SideBar'
export { default as DATE_SLOT_CALENDER } from '../components/slotCalendar/DateSlotTab'
export { default as TIME_SLOT_CALENDER } from '../components/slotCalendar/TimeSlotComponent'
export { default as TABLE } from '../components/table/Table'
export { default as TABS } from '../components/patientDetails/Tabs'
export { default as FEE_SECTION } from '../components/feeSection/Fee.jsx'
export { default as FOLLOW_UP_TABLE } from '../components/table/FollowUpTable'
export { default as FOLLOW_UP_APPOINTMENT} from '../components/forms/FollowUpAppointment.jsx'