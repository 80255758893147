import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  DASHBOARD_SIDEBAR_LINKS,
  DASHBOARD_SIDEBAR_BOTTOM_LINKS,
} from "../../routes/SideBarTabs";
import Cookies from "js-cookie";
// import logo from "../../asset/images/balaji.png"

const linkClass =
  "flex items-center gap-2 font-medium  px-3 py-2 mr-0 hover:bg-[#d2c2f1] hover:text-[#301762] hover:no-underline roundend-sm transition-all duration-300 active:bg-[#301762]";

const SideBar = () => {
  return (
    <>
      <aside className="w-48">
        <div className="bg-blue flex items-center gap-2 p-1 h-[80px]">
          <Link to="/" style={{ textDecoration: "none" }} >
            <span style={{ fontFamily: `'Tangerine', serif`, textShadow: '2px 5px 4px gray', lineHeight: 1 }} className="p-1 text-white font-bold cursor-pointe text-[30px] sm:text-[32px] lg:text-[35px]">
              Shri Balaji Dental Clinic
            </span></Link>
        </div>
        <div className="py-1 flex flex-1 flex-col gap-0.5 border-e-2">
          {DASHBOARD_SIDEBAR_LINKS.map((link, index) => (
            <SidebarLink key={index} link={link} />
          ))}
        </div>
        <div className="flex flex-col gap-0.5  border-e-2">
          {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link, index) => (
            <SidebarLink key={index} link={link} />
          ))}
          <hr />
        </div>
      </aside>
    </>
  );
};

function SidebarLink({ link }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    navigate("/login");
  };

  if (link.path === "/login") {
    return (
      <Link
        to={link.path}
        onClick={handleLogout}
        className={classNames(
          pathname === link.path ? "bg-[#301762] text-white text-sm" : "text-gray-800 text-sm",
          linkClass
        )} //rounded-2xl
      >
        <span className="text-xl">{link.icon}</span>
        {link.label}
      </Link>
    );
  }

  return (
    <Link
      to={link.path}
      className={classNames(
        pathname === link.path ? "bg-[#d2c2f1] text-[#301762] text-sm" : "text-gray-800 text-sm",
        linkClass
      )} //rounded-2xl
    >
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
}
export default SideBar;
