import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { addMedicineGroup, deleteMedicineGroup, getMedicinesGroup } from "./medicineGroupApi";

const initialState = {
  medicinesGroup: [],
  status: "idle",
};
//=============GET MEDICINE THUNK================
export const getMedicinesGroupAsync = createAsyncThunk(
  "medicineGroup/get_medicineGroup)",
  async () => {
    const response = await getMedicinesGroup();
    if(response.status){
      return response.data;
    }else{
      toast.error(response.message);
      return false;
    }
  }
);

//============= ADD MEDICINES THUNK =============
export const addMedicinesGroupAsync = createAsyncThunk(
  "medicineGroup/add_medicineGroup",
  async (medicineDetails) => {
    const response = await addMedicineGroup(medicineDetails);
    if(response.status){
      return response.data;
    }else{
      toast.error(response.message);
      return false;
    }
  }
);

//===============DELETE MEDICINE API ====================
export const deleteMedicineGroupAsync = createAsyncThunk(
  "medicineGroup/delete_medicineGroup",
  async (medicineId) => {
    let isDel = window.confirm("Are You Sure!");
    try {
      if (isDel) {
        const response = await deleteMedicineGroup(medicineId);
        if(response.status){
          return response.data;
        }else{
          toast.error(response.message);
          return false;
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const medicineGroupSlice = createSlice({
  name: "medicineGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMedicinesGroupAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMedicinesGroupAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if(action.payload){
          state.medicinesGroup = action.payload;
        }
      })
      .addCase(addMedicinesGroupAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addMedicinesGroupAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.medicinesGroup.push(action.payload);
          toast.success("medicine added successfully");
        } else {
          toast.error("Something went wrong .failed to add medicine");
        }
      })
      .addCase(deleteMedicineGroupAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteMedicineGroupAsync.fulfilled, (state, action) => {
        if(action.payload){
          const index = state.medicinesGroup.findIndex(
            (medicine) => medicine._id === action.payload._id
          );
          if (action.payload) {
            state.medicinesGroup.splice(index, 1);
            toast.success("medicine deleted successfully");
            return;
          }
        }
      });
  },
});

export const selectMedicineGroup = (state) => state.medicineGroup.medicinesGroup;

export default medicineGroupSlice.reducer;
