import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  addNewBillByPatientId,
  addNewPatient,
  addPatientReport,
  bookPatientAppointment,
  cancelPatientAppointments,
  deletePatientDetails,
  fetchAllPatients,
  fetchBillsByPatientId,
  fetchCurrentPatientAppointment,
  fetchPatientAppointments,
  fetchPatientById,
  updateAppointment,
  updatePatientDetails,
  updatePaymentBill,
} from "./patientAppointmentAPI";

const initialState = {
  patientAppointment: [],
  currentPatientAppointment: [],
  patient: [],
  bills: [],
  reports: [],
  selectedPatient: null,
  status: "idle",
};

//{ ===============PATIENT APPOINTMENT THUNKS START================== }

//==============book Patient Appointment ==============
export const bookPatientAppointmentAsync = createAsyncThunk(
  "patientAppointment/bookAppointment",
  async (appointmentDetails) => {
    const response = await bookPatientAppointment(appointmentDetails);
    if (response.status) {
      return response.data;
    } else {
      return false;
    }
  }
);

// fetch single patient appointments===============

export const fetchCurrentPatientAppointmentAsync = createAsyncThunk(
  "patientAppointment/fetchCurrentPatientAppointment",
  async (patientId) => {
    const response = await fetchCurrentPatientAppointment(patientId);
    // console.log(response.data);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//============fetch patient appointment================
export const fetchPatientAppointmentsAsync = createAsyncThunk(
  "patientAppointment/fetchAppointment",
  async () => {
    const response = await fetchPatientAppointments();
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);
//==================update patient appointment=================
export const updateAppointmentAsync = createAsyncThunk(
  "patientAppointment/updateAppointment",
  async (id) => {
    const response = await updateAppointment(id);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//==================resechdule patient appointment=================
export const updateAppointmentFieldsAsync = createAsyncThunk(
  "patientAppointment/updateAppointmentFields",
  async (response) => {
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//==================cancel patient appointment =====================
export const cancelPatientAppointmentsAsync = createAsyncThunk(
  "patientAppointment/cancelAppointment",
  async (id) => {
    const response = await cancelPatientAppointments(id);
    if (response.status) {
      toast.success(response.message);
      return id;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);
//{ ===============PATIENT APPOINTMENT THUNKS END ================== }

//{ ===============PATIENT THUNKS START ================== }

//=============add new Patient =====================
export const addNewPatientAsync = createAsyncThunk(
  "patient/Add_New_Patient",
  async (patientDetails) => {
    const response = await addNewPatient(patientDetails);
    if (response.status) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//===========fetch patiet details ====================
export const fetchAllPatientAsync = createAsyncThunk(
  "patient/fetchAllPatient",
  async () => {
    const response = await fetchAllPatients();
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//-============fetch Patient details by id ===============
export const fetchPatientByIdAsync = createAsyncThunk(
  "patient/patientById",
  async (patientId) => {
    if (patientId) {
      const response = await fetchPatientById(patientId);
      if (response.status) {
        return response.data;
      } else {
        toast.error(response.message);
        return false;
      }
    } else {
      return false;
    }

  }
);

//============update Patient details ========================

export const updatePatientDetailsAsync = createAsyncThunk(
  "patient/UpdatePatiet",
  async (patientDetails) => {
    const response = await updatePatientDetails(patientDetails);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//============delete Patient details ========================

export const deletePatientDetailsAsync = createAsyncThunk(
  "patient/DeletePatient",
  async (id) => {
    const response = await deletePatientDetails(id);
    if (response.status) {
      toast.success(response.message)
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//{ ===============PATIENT THUNKS END ================== }

//{ ===============PATIENT THUNK BILL START ================== }

//=================== add a new bill=========================

export const addNewBillByPatientIdAsync = createAsyncThunk(
  "patient/Add_bill",
  async (billDetails) => {
    const response = await addNewBillByPatientId(billDetails);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//===================fetch all bills==========================
export const fetchBillsByPatientIdAsync = createAsyncThunk(
  "patient/bill",
  async (patientId) => {
    const response = await fetchBillsByPatientId(patientId);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//====================update payment in bills========================
export const updatePaymentBillAsync = createAsyncThunk(
  "patient/update_payments",
  async (paymentDetails) => {
    const response = await updatePaymentBill(paymentDetails);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//{ ===============PATIENT THUNK BILL END ================== }

//{==========PATIENT REPORTS THUNK START======================= }
export const addPatientReportAsync = createAsyncThunk(
  "patient/add_reports",
  async (reportsDetails) => {
    const response = await addPatientReport(reportsDetails);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);
//{==========PATIENT REPORTS THUNK END======================= }

//{================= appoinntment notification thunks=======================}
export const removeNotificationAsync = createAsyncThunk(
  "patient/remove_notification",
  async (notificationId) => {
    const response = await new Promise((resolve) => {
      resolve(notificationId);
    });
    return response;
  }
);

export const patientSlice = createSlice({
  name: "patient appointment",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientAppointmentsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPatientAppointmentsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.patientAppointment = action.payload;
        }
      })
      .addCase(bookPatientAppointmentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bookPatientAppointmentAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.patientAppointment = [
            ...state.patientAppointment,
            action.payload,
          ];
          toast.success("Appointment Booked Successfully");
        } else {
          toast.error("Appointment is Already Booked For Another Patient");
        }
      })
      .addCase(addNewPatientAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addNewPatientAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.patient.push(action.payload);
        }
      })
      .addCase(updatePatientDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePatientDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          const index = state.patient.findIndex(
            (patient) => patient._id === action.payload._id
          );
          state.patient[index] = action.payload;
        }
      }).addCase(deletePatientDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePatientDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          const index = state.patient.findIndex(
            (patient) => patient._id === action.payload._id
          );
          state.patient.splice(index, 1)
        }
      })
      .addCase(fetchAllPatientAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPatientAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.patient = action.payload;
        }
      })
      .addCase(updateAppointmentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAppointmentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          const index = state.patientAppointment.findIndex(
            (appointment) => appointment._id === action.payload._id
          );
          state.patientAppointment[index].isAppointmentDone =
            action.payload.isAppointmentDone;
          state.patientAppointment[index].booked = action.payload.booked;
        }
      })
      .addCase(updateAppointmentFieldsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAppointmentFieldsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          const index = state.patientAppointment.findIndex(
            (appointment) => appointment._id === action.payload._id
          );
          state.patientAppointment[index] = action.payload
        }
      })
      .addCase(cancelPatientAppointmentsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelPatientAppointmentsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.patientAppointment = state.patientAppointment.filter(
            (appointment) => appointment._id !== action.payload
          );
        }
      })
      .addCase(fetchBillsByPatientIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBillsByPatientIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.bills = action.payload;
        }
      })
      .addCase(addNewBillByPatientIdAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNewBillByPatientIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.bills.push(action.payload);
        }
      })
      .addCase(fetchPatientByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPatientByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.selectedPatient = action.payload;
          state.reports = action.payload.report;
        } else {
          state.selectedPatient = {};
          state.reports = [];
        }
      })
      .addCase(addPatientReportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPatientReportAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          const index = state.patient.findIndex(
            (patient) => patient._id === action.payload._id
          );
          state.patient[index] = action.payload;
          state.reports = action.payload.report;
        }
      })
      .addCase(fetchCurrentPatientAppointmentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchCurrentPatientAppointmentAsync.fulfilled,
        (state, action) => {
          state.status = "idle";
          if (action.payload) {
            state.currentPatientAppointment = action.payload;
          }
        }
      )
      .addCase(removeNotificationAsync.fulfilled, (state, action) => {
        const index = state.patientAppointment.findIndex(
          (appointment) => appointment._id === action.payload
        );
        if (index > -1) {
          let updatedData = (state.patientAppointment[index].notified = false);
          state.patientAppointment.splice(index, 1, updatedData);
        }
      });
  },
});

export const selectPatientAppointment = (state) => state.patient.patientAppointment;
export const selectCurrentPatientAppointment = (state) => state.patient.currentPatientAppointment
export const selectAllPatient = (state) => state.patient.patient;
export const selectedPatient = (state) => state.patient.selectedPatient;
export const selectedPatientBill = (state) => state.patient.bills;
export const selectPatientReport = (state) => state.patient.reports;

export default patientSlice.reducer;
