import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addMedicines, deleteMedicine, getMedicines } from "./medicineAPI";
import toast from "react-hot-toast";

const initialState = {
  medicines: [],
  status: "idle",
};
//=============GET MEDICINE THUNK================
export const getMedicinesAsync = createAsyncThunk(
  "medicine/get_medicine)",
  async () => {
    const response = await getMedicines();
    if (response.status) {
      return response.data;
    } else {
      return false;
    }
  }
);

//============= ADD MEDICINES THUNK =============
export const addMedicinesAsync = createAsyncThunk(
  "medicine/add_medicine",
  async (medicineDetails) => {
    const response = await addMedicines(medicineDetails);
    if (response.status) {
      toast.success("medicine added successfully");
      return response.data;
    } else {
      toast.error(response.message);
      return false;
    }
  }
);

//===============DELETE MEDICINE API ====================
export const deleteMedicineAsync = createAsyncThunk(
  "medicine/delete_medicine",
  async (medicineId) => {
    let isDel = window.confirm("Are You Sure!");
    try {
      if (isDel) {
        const response = await deleteMedicine(medicineId);
        if (response.status) {
          toast.success("medicine deleted successfully");
          return response.data;
        } else {
          toast.error(response.message);
          return false;
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const medicineSlice = createSlice({
  name: "medicine",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMedicinesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMedicinesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.medicines = action.payload;
        }
      })
      .addCase(addMedicinesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addMedicinesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.medicines.push(action.payload);
        } else {
          toast.error("Something went wrong .failed to add medicine");
        }
      })
      .addCase(deleteMedicineAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteMedicineAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const index = state.medicines.findIndex(
            (medicine) => medicine._id === action.payload._id
          );
          state.medicines.splice(index, 1);
        }
      });
  },
});

export const selectMedicine = (state) => state.medicine.medicines;

export default medicineSlice.reducer;
