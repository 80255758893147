import React, { useEffect, useState } from "react";
import { GiMedicines } from "react-icons/gi";
import SquareButton from "../../components/Ui Components/SquareButton";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import {
  addMedicinesAsync,
  deleteMedicineAsync,
  getMedicinesAsync,
  selectMedicine,
} from "../../redux/medicines/medicineSlice";
import ModalComponent from "../../components/modal/ModalComponent";
import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

const Medicine = () => {
  const allMedicines = useSelector(selectMedicine);
  const dispatch = useDispatch();
  const [editModelOpen, setEditModelOpen] = useState(false)
  const [editMedicine, setEditMedicine] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // add new medicine
  const addMedicine = (data) => {
    setIsLoading(true)
    try {
      dispatch(addMedicinesAsync(data));
      reset()
      setRefresh(!refresh)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error("something went wrong. Failed to add Medicine .");
    }
  };

  // delete medicine 
  const handleDelete = (id) => {
    setIsLoading(true)
    try {
      dispatch(deleteMedicineAsync(id));
      setRefresh(!refresh)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message);
    }
  };

  // open edit medicine model
  function EditForm(data) {
    setEditMedicine({
      _id: data._id,
      medicine_name: data.medicine_name,
      contains: data.contains,
      times: data.times,
      quantity: data.quantity
    })
    setEditModelOpen(true)
  }

  // edit patient details
  async function handleEdit(e) {
    e.preventDefault()
    setIsLoading(true)
    try {
      let response = await axiosClient().patch(`${webURLs.EDIT_MEDICINES}/${editMedicine._id}`, editMedicine)
      if (response.data.status) {
        setIsLoading(false)
        setEditModelOpen(false)
        setRefresh(!refresh)
        toast.success(response.data.message)
      } else {
        setIsLoading(false)
        toast.error(response.data.message)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error("Oops Something Wrong")
    }
  }
  // every api hit in this page refresh 
  useEffect(() => {
    dispatch(getMedicinesAsync())
  }, [refresh, dispatch])

  return (
    <>
      <div
        id="uploadfile-form-section"
        className="rounded-lg bg-white border-2 pb-3 mt-1"
      >
        <h1 className="pl-3 mb-4 text-sm font-medium py-2 text-center rounded-t-lg bg-gray-300 text-[#301762]">
          Add New Medicine
        </h1>
        <form
          className="flex flex-col gap-3 items-start pl-3"
          onSubmit={handleSubmit(addMedicine)}
        >
          {/* ===============form fields start================= */}
          <div className="grid grid-cols-3 w-full">
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="medicine_name"
                className="bg-white left-2 top-3 text-gray-600 text-sm w-fit font-medium font-sans relative"
              >
                Medicine Name
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="medicine_name"
                {...register("medicine_name", {
                  required: "*medicine name is required",
                })}
                placeholder="Enter Medicine Name"
                id="medicine"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="medicine_name" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="contains"
                className="bg-white left-2 top-3 text-gray-600 text-sm w-fit font-medium font-sans relative"
              >
                Drug Name
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="contains"
                {...register("contains", {
                  required: "*enter medicine contents",
                })}
                placeholder="Enter Medicine Content"
                id="drug"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="contains" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="times"
                className="bg-white left-2 top-3 text-gray-600 text-sm w-fit font-medium font-sans relative"
              >
                Time To Consume
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="times"
                list="suggestion"
                {...register("times", {
                  required: "*Enter Time To Consume Medicine",
                })}
                placeholder="Prescribe Time For Medicine"
                id="time"
              />
              <datalist id="suggestion" className="capitalize">
                <option>2 times before meal (दिन में 2 बार भोजन से पहले)</option>
                <option>2 times after meal (दिन में 2 बार भोजन के बाद)</option>
                <option>2 times (दिन में 2 बार)</option>
                <option>2 times before meal and once before sleep (दिन में 2 बार भोजन से पहले और एक बार सोने से पहले)</option>
                <option>1 times (दिन में 1 बार)</option>
                <option>once a week (एक सप्ताह में एक बार)</option>
                <option>3 times after meal (दिन में 3 बार भोजन के बाद)</option>
                <option>3 times before meal (दिन में 3 बार भोजन के पहले)</option>
                <option>2 together in severe pain (2 एक साथ (ज्यादा दर्द होने पर))</option>
                <option>Half medicine 3 times after meal (आधी दवा दिन में 3 बार भोजन के बाद)</option>
                <option>3 times a day after Meal (दिन में 3 बार भोजन के बाद)</option>
              </datalist>
              <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                <ErrorMessage errors={errors} name="times" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="days"
                className="bg-white left-2 top-3 text-gray-600 text-sm w-fit font-medium font-sans relative"
              >
                Days
              </label>
              <input
                className="py-2 border-gray-300 border-2 rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="text"
                name="days"
                {...register("days", {
                  required: "Enter No. of days to consume medicine",
                })}
                placeholder="Days To Consume Medicine"
                id="days"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="days" />
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <label
                htmlFor="quantity"
                className="bg-white left-2 top-3 text-gray-600 text-sm w-fit font-medium font-sans relative"
              >
                Quantity
              </label>
              <input
                className="py-2 border-gray-300 border rounded px-1 text-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301762] focus:border-0 outline-none "
                type="number"
                name="quantity"
                {...register("quantity", { required: "quantity is required" })}
                placeholder="Number of Tab."
                id="quantity"
              />
              <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                <ErrorMessage errors={errors} name="quantity" />
              </div>
            </div>
          </div>
          {/* ===============form fields start=================  */}
          <div type="submit">
            <SquareButton
              isLoading={isLoading}
              sqicon={<span className="text-xs">Add Medicine</span>}
            />
          </div>
        </form>
      </div>
      <section
        id="medicine-section"
        className="rounded-sm border-2 mt-3 pb-3 h-96 overflow-y-auto sm:w-[80%] lg:w-[100%]"
      >
        <div className="flex bg-gray-300 pl-2 rounded-t-sm text-white items-center sticky top-0">
          <GiMedicines className="text-3xl p-0 m-0" />
          <h1 className="pl-3 text-sm font-medium py-2 text-[#301762] w-full">
            Medicines({allMedicines &&
              allMedicines.length})
          </h1>
        </div>
        {/* //======================file display table section start============================= */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-gray-800 sticky top-9">
            <tr>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                S.No.
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Medicine Name
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Drug Name
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Time
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Days
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Quantity
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Edit
              </th>
              <th scope="col" className="text-center px-2 py-2 font-medium">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="capitalize">
            {allMedicines &&
              allMedicines.map((medicine, index) => (
                <tr key={index} className="text-xs odd:bg-white even:bg-gray-50">
                  <td className="px-2 py-2 text-center font-medium">
                    {index + 1}
                  </td>
                  <td className="px-2 py-2 text-center">
                    {medicine?.medicine_name}
                  </td>
                  <td className="px-2 py-2 text-center">{medicine?.contains}</td>
                  <td className="px-2 py-2 text-center ">{medicine?.times}</td>
                  <td className="px-2 py-2 text-center">{medicine?.days}</td>
                  <td className="px-2 py-2 text-center">{medicine?.quantity}</td>
                  <td className="px-2 py-2 text-center">
                    <SquareButton
                      sqicon={
                        <span className="text-xl" title="Edit Medicine">
                          <FaEdit />
                        </span>
                      }
                      handleClick={() => { EditForm(medicine) }}
                    />
                  </td>
                  <td className="px-2 py-2 text-center">
                    <button
                      onClick={() => {
                        handleDelete(`${medicine._id}`);
                      }}
                      className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                      title="Delete Medicine"
                    >
                      <RiDeleteBin6Line className="p-0 m-0 text-xl" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* //======================file display table section start============================= */}
      </section>
      {/* edit basic patient details */}

      <ModalComponent
        content={
          <form className="space-y-6  p-3  bg-slate-100" onSubmit={handleEdit}>
            <div className=" grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="medicine"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Medicine Name
                </label>
                <div className="mt-2">
                  <input
                    name="medicine_name"
                    type="text"
                    defaultValue={editMedicine?.medicine_name}
                    onChange={(e) => { setEditMedicine({ ...editMedicine, [e.target.name]: e.target.value }) }}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contains"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Drug Name
                </label>
                <div className="mt-2">
                  <input
                    name="contains"
                    type="text"
                    defaultValue={editMedicine?.contains}
                    onChange={(e) => { setEditMedicine({ ...editMedicine, [e.target.name]: e.target.value }) }}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="times"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Time To Consume
                </label>
                <div className="mt-2">
                  <input
                    name="times"
                    type="text"
                    list="suggestion"
                    defaultValue={editMedicine?.times}
                    onChange={(e) => { setEditMedicine({ ...editMedicine, [e.target.name]: e.target.value }) }}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <datalist id="suggestion">
                    <option>2 times before meal (दिन में 2 बार भोजन से पहले)</option>
                    <option>2 times after meal (दिन में 2 बार भोजन के बाद)</option>
                    <option>2 times (दिन में 2 बार)</option>
                    <option>2 times before meal and once before sleep (दिन में 2 बार भोजन से पहले और एक बार सोने से पहले)</option>
                    <option>1 times (दिन में 1 बार)</option>
                    <option>once a week (एक सप्ताह में एक बार)</option>
                    <option>3 times after meal (दिन में 3 बार भोजन के बाद)</option>
                    <option>3 times before meal (दिन में 3 बार भोजन के पहले)</option>
                    <option>2 together in severe pain (2 एक साथ (ज्यादा दर्द होने पर))</option>
                    <option>Half medicine 3 times after meal (आधी दवा दिन में 3 बार भोजन के बाद)</option>
                    <option>3 times a day after Meal (दिन में 3 बार भोजन के बाद)</option>
                  </datalist>
                </div>
              </div>
              <div>
                <label
                  htmlFor="quantity"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Quantity
                </label>
                <div className="mt-2">
                  <input
                    name="quantity"
                    type="number"
                    defaultValue={editMedicine?.quantity}
                    onChange={(e) => { setEditMedicine({ ...editMedicine, [e.target.name]: e.target.value }) }}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 flex gap-3">
              <SquareButton
                isLoading={isLoading}
                sqicon={
                  <span className="text-sm">
                    Save
                  </span>
                }
              />
            </div>

            <hr />
          </form>
        }
        open={editModelOpen}
        setOpen={setEditModelOpen}
      />

    </>
  );
};

export default Medicine;
