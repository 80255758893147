import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useRef } from 'react';
import { FaDownload } from 'react-icons/fa';

const InvoiceTemplate = ({ Data }) => {

    const patientBill = useRef()

    // PDF GENERATION AND DOWNLOAD 
    const generatePDF = () => {
        const modalContent = patientBill.current;

        html2canvas(modalContent, {
            scale: 5,
            useCORS: true,
            imageTimeout: 5000,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");

            // A4 size in pixels (at 96 DPI)
            const pdfWidth = 595.28;
            const pdfHeight = 841.89;
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;

            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "pt",
                format: "a4",
            });

            // Calculate the scaling factor to fit the content to A4 size
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const width = imgWidth * ratio;
            const height = imgHeight * ratio;

            pdf.addImage(imgData, "PNG", 0, 0, width, height);
            console.log(canvas.height);
            console.log(canvas.width);

            pdf.save(`Patient-${Data?.bill_no}.pdf`);
        });
    };


    return (<section className='flex flex-col items-center '>
        <button className='p-2 bg-[#301762] text-white rounded flex items-center gap-2 capitalize font-extrabold w-fit' onClick={generatePDF}>Download PDF <FaDownload /></button>
        <div className="max-w-4xl mx-auto bg-white shadow-lg"
            ref={patientBill}>
            {/* Header */}
            <header className="p-8 flex justify-between">
                <div>
                    <h1 className="text-[#8a231c] text-2xl font-bold mb-1">SHRI BALAJI MULTI SPECIALIST DENTAL CLINIC</h1>
                    <p className="text-[#8a231c] text-sm italic mb-4">A dream smile is a reality!</p>
                    <div className="text-xs space-y-1 text-gray-600">
                        <p>204-205, Crystal One, Bhola Ram Ustad Marg,</p>
                        <p>Bhawar Kua, Indore-452001</p>
                        <p>(+91) 88394 19269 | (+91) 89827 07575</p>
                        <p>ISO 9001:2015 Certified Clinic</p>
                    </div>
                </div>
                <div className="text-right">
                    <h2 className="text-2xl font-bold text-[#8a231c] mb-4">INVOICE</h2>
                    <div className="text-sm space-y-1 text-gray-600">
                        <p>{Data?.bill_no || "INVOICENUMBER"}</p>
                        <p>{moment(Data?.createdAt).format("Do MMM YYYY") || "DATE"}</p>
                        {/* <p>Term: 5/30/24 to 6/30/25</p> */}
                    </div>
                </div>
            </header>

            {/* Bill To Section */}
            <div className="px-8 mb-6">
                <div className="text-sm text-gray-600">
                    <p className="font-bold mb-1">To:</p>
                    <p className='font-semobold'>{Data?.patient?.patient_name + " " + Data?.patient?.surname || "PATIENTNAME"}</p>
                    <p>{Data?.patient?.address || "PATIENTADDRESS"}</p>
                    <p>{Data?.patient?.mobile || "PATIENTMOBILE"}</p>
                    <p>Customer ID No. {Data?.patient?.file_number || "PATIENTID"}</p>
                </div>
            </div>

            {/* Invoice Table */}
            <table className="w-[100%] text-sm text-gray-500">
                <thead className="text-sm font-semibold text-gray-700 uppercase bg-gray-50">
                    <tr className="text-center ">
                        <th className="px-2 py-2 font-medium">
                            S.No.
                        </th>
                        <th className="px-2 py-2 font-medium">
                            Item Description
                        </th>
                        <th className="px-2 py-2 font-medium">
                            Others
                        </th>
                        <th className="px-2 py-2 font-medium">
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="odd:bg-white even:bg-gray-50 text-gray-700 text-center">
                        <th className="p-2 font-medium"> 1 </th>
                        <td className="px-2 py-2">Consultation fees</td>
                        <td className="px-2 py-2">
                            {/* Others */}
                        </td>
                        <td className="px-2 py-2">₹ {Data?.consultation_fee}</td>
                    </tr>
                    <tr className="odd:bg-white even:bg-gray-50 text-gray-700 text-center">
                        <th className="p-2 font-medium"> 2 </th>
                        <td className="px-2 py-2">X-ray fees</td>
                        <td className="px-2 py-2">
                            {/* Others */}
                        </td>
                        <td className="px-2 py-2">₹ {Data?.x_ray_fee}</td>
                    </tr>
                    <tr className="odd:bg-white even:bg-gray-50 text-gray-700 text-center">
                        <th className="p-2 font-medium"> 3 </th>
                        <td className="px-2 py-2">{Data?.treatment?.treatment_name}</td>
                        <td className="px-2 py-2">
                            {Data?.procedure_name}
                        </td>
                        <td className="px-2 py-2">₹ {Data?.procedure_fee}</td>
                    </tr>
                    <tr className="odd:bg-white even:bg-gray-50 text-gray-700 text-center">
                        <th className="p-2 font-medium"> 4 </th>
                        <td className="px-2 py-2">Other fees</td>
                        <td className="px-2 py-2">
                            {/* Others */}
                        </td>
                        <td className="px-2 py-2">₹ {Data?.other_fee}</td>
                    </tr>
                </tbody>
            </table>

            {/* Totals Section */}
            <div className="px-8 mt-4">
                <div className="flex justify-end text-sm">
                    <div className="w-48">
                        <div className="flex justify-between py-1">
                            <span>Subtotal</span>
                            <span>₹ {Data?.consultation_fee +
                                Data?.other_fee +
                                Data?.procedure_fee + Data?.x_ray_fee}</span>
                        </div>
                        <div className="flex justify-between py-1 text-xs">
                            <span>Amount Paid</span>
                            <span>₹ {Data?.total_paid}</span>
                        </div>
                        <div className="flex justify-between py-1 text-xs">
                            <span>Payment Mode</span>
                            <span>{Data?.payment_mode}</span>
                        </div>

                        <div className="flex justify-between py-1 text-sm">
                            <span>Remaining</span>
                            <span>₹ {(Data?.consultation_fee +
                                Data?.other_fee +
                                Data?.procedure_fee + Data?.x_ray_fee) - Data?.total_paid}</span>
                        </div>
                        <div className="flex justify-between py-1 font-bold">
                            <span>Total</span>
                            <span>₹ {(Data?.consultation_fee +
                                Data?.other_fee +
                                Data?.procedure_fee + Data?.x_ray_fee)}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* TESTING COMMAND */}
            {/* Footer Section */}
            <footer className="p-8 mt-4 border-t">
                <div className="text-sm space-y-4 text-gray-600 py-5">
                    <p>This is an invoice for dental work, subject to the conditions noted below: All sales final, payment due upon receipt.</p>
                    <p>To accept this invoice, sign here and return: _____________________________</p>
                    <p className="text-[#8a231c] font-semibold py-10">Thank you for your business!</p>
                </div>
            </footer>
        </div>

    </section>
    );
};

export default InvoiceTemplate;