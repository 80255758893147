import React, { useCallback, useEffect, useState } from 'react'
import SquareButton from '../../components/Ui Components/SquareButton'
import { GiTargetLaser } from "react-icons/gi";
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { base64ToBlob } from '../../utils/helper'
import { FaEye, FaTrash } from 'react-icons/fa';
import { addMaterial, deleteMaterial, getAllMaterial } from '../../redux/material/materialApi';
import WebCam from '../../components/webcam/webcam';
import toast from 'react-hot-toast';
import ShowPhotoModel from '../../components/modal/showPhotoModel';


export default function Materials() {

    const [AllMaterials, setMaterials] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [openWebCam, setOpenWebCame] = useState(false)
    const [capturedImage, setCapturedImage] = useState(null)
    const [photoModelOpen, setPhotoModelOpen] = useState(false)
    const [modelData, setModelData] = useState()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const handleAddMaterial = async (data) => {

        setIsLoading(true);
        if (!capturedImage) {
            toast.error("Please Capture photo First")
            setIsLoading(false);
            return;
        }

        // Extract the base64 data (remove the data URL prefix)
        const base64Data = capturedImage.split(',')[1]; // Only the Base64 data
        const mimeType = capturedImage.match(/^data:(.*);base64,/)[1]; // Extract MIME type (e.g., image/jpeg)

        // Convert base64 to Blob
        const imageBlob = base64ToBlob(base64Data, mimeType);

        // Convert Blob to File (optional)
        const file = new File([imageBlob], `materials.jpg`, { type: mimeType });


        const formData = new FormData()
        formData.append("item_name", data.item_name)
        formData.append("item_price", data.item_price)
        formData.append("quantity", data.quantity)
        formData.append("total_price", data.total_price)
        formData.append("buying_date", data.buying_date)
        formData.append("payment_mode", data.payment_mode)
        formData.append("note", data.note)
        formData.append("material-bill", file)

        try {
            let response = await addMaterial(formData)
            if (response.status) {
                setMaterials([...AllMaterials, response.data])
                setIsLoading(false);
                setOpenWebCame(false);
                setCapturedImage("")
                reset()
            } else {
                setIsLoading(false);
            }
            reset()
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    // for delete lab Data
    async function handleDelete(materialId) {
        const is = window.confirm("Are You Sure ?");
        try {
            if (is) {
                let response = await deleteMaterial(materialId)
                if (response.status) {
                    setMaterials(AllMaterials.filter(item => item._id !== response.data._id))
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getMaterial = useCallback(async () => {
        try {
            let res = await getAllMaterial()
            if (res.status) {
                setMaterials(res.data)
            }
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        getMaterial()
    }, [getMaterial])

    return (
        <>
            <div className="flex flex-col md:flex-row w-full p-0">
                {/* First Box - One Third Width */}
                <div className="w-full md:w-1/1 p-1 rounded-lg md:mb-0">
                    <div id="uploadfile-form-section" className="rounded bg-white border-2 pb-3" >
                        <h1 className="text-sm py-2 text-center font-medium bg-gray-300 text-[#301762]">
                            Material Form
                        </h1>
                        <div className="mx-auto p-6 bg-white rounded-lg">
                            <form onSubmit={handleSubmit(handleAddMaterial)}>
                                <div className="flex flex-col md:flex-row w-full p-2 gap-3 justify-center">
                                    <div className="w-full md:w-2/3 p-1 rounded-lg md:mb-0">
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
                                            <div className="mb-4">
                                                <label htmlFor="lab_name" className="block text-sm font-medium text-gray-600 mb-2">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="item_name"
                                                    id="item_name"
                                                    {...register("item_name", {
                                                        required: "*Material name is required",
                                                    })}
                                                    className="w-full text-sm p-2 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    placeholder="Enter Item name"
                                                />
                                                <div className="text-red-600 text-xs">
                                                    <ErrorMessage errors={errors} name="item_name" />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="lab_Owner" className="block text-sm font-medium text-gray-600 mb-2">
                                                    Item Price
                                                </label>
                                                <input
                                                    type="number"
                                                    name="item_price"
                                                    id="item_price"
                                                    {...register("item_price", {
                                                        required: "*Price is required",
                                                    })}
                                                    className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    placeholder="Enter Item Price"
                                                />
                                                <div className="text-red-600 text-xs">
                                                    <ErrorMessage errors={errors} name="item_price" />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="quantity" className="block text-sm font-medium text-gray-600 mb-2">
                                                    Quantity
                                                </label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    id="quantity"
                                                    {...register("quantity", {
                                                        required: "*number of Quantity is required",
                                                    })}
                                                    className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    placeholder="Enter Number of Quantity"
                                                />
                                                <div className="text-red-600 text-xs">
                                                    <ErrorMessage errors={errors} name="quantity" />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="buying_date" className="block text-sm font-medium text-gray-600 mb-2">
                                                    Buy Date
                                                </label>
                                                <input
                                                    name="buying_date"
                                                    type='date'
                                                    id="buying_date"
                                                    {...register("buying_date", {
                                                        required: "*Date is required",
                                                    })}
                                                    className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    placeholder="Enter Buy Date"
                                                    rows="3"
                                                />
                                                <div className="text-red-600 text-xs">
                                                    <ErrorMessage errors={errors} name="buying_date" />
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <label
                                                    htmlFor="payment_mode"
                                                    className="block text-sm font-medium text-gray-600 mb-2"
                                                >
                                                    Mode of Payment
                                                </label>
                                                <select
                                                    id="payment_mode"
                                                    name="payment_mode"
                                                    {...register("payment_mode", {
                                                        required: "*payment mode is required",
                                                    })}
                                                    className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                >
                                                    <option value="Cash">Cash</option>
                                                    <option value="Online App">Online App</option>
                                                    <option value="Bank Transfer">Bank Transfer</option>
                                                    <option value="Credit Card">Credit Card</option>
                                                    <option value="Debit Card">Debit Card</option>
                                                </select>
                                                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                                    <ErrorMessage errors={errors} name="payment_mode" />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="note" className="block text-sm font-medium text-gray-600 mb-2">
                                                    Note
                                                </label>
                                                <textarea
                                                    name="note"
                                                    id="note"
                                                    {...register("note")}
                                                    className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                                    placeholder="Enter Note"
                                                    rows="3"
                                                />
                                                <div className="text-red-600 text-xs">
                                                    <ErrorMessage errors={errors} name="note" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-2 rounded-sm md:mb-0">
                                        {openWebCam ? <WebCam
                                            className={"w-full border p-1"}
                                            height={"100%"}
                                            width={"100%"}
                                            getImage={(data) => { setCapturedImage(data) }}
                                        /> : <div className="flex justify-center border"><img src="/asset/images/unknown-image.jpg" className="m-2 h-[200px]" alt="user" loading="lazy" /></div>
                                        }
                                        {openWebCam ?
                                            <SquareButton
                                                btnType={"button"}
                                                handleClick={() => { setOpenWebCame(false); setCapturedImage(""); }}
                                                sqicon={<span className="text-xs">Close Camera</span>}
                                                className={"bg-red-600 mt-5 text-white rounded focus:ring-[#301762]"}
                                            />
                                            :
                                            <SquareButton
                                                btnType={"button"}
                                                sqicon={<span className="text-xs">Open Camera</span>}
                                                handleClick={() => { setCapturedImage(""); setOpenWebCame(true) }}
                                                className={"bg-[#301762] text-white p-2 rounded  focus:ring-[#301762]"}
                                            />
                                        }
                                    </div>
                                </div>

                                <SquareButton
                                    isLoading={isLoading}
                                    sqicon={<span className='text-xs'>Add Material</span>}
                                    className={"bg-[#301762] text-white p-2 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"}
                                    btnType={"submit"}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row w-full h-96 p-1">
                {/* Second Box - Two Thirds Width */}
                <div className="w-full md:w-3/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 h-96 overflow-y-auto w-full">
                        <div className="flex bg-gray-300 pl-2 rounded-t-sm justify-between text-white items-center sticky top-0">
                            <div className='flex'>
                                <GiTargetLaser className="text-3xl p-0 m-0" />
                                <h1 className="pl-3 text-sm font-medium py-2 text-[#301762]">
                                    All Materials ({AllMaterials && AllMaterials.length})
                                </h1>
                            </div>
                            <div>
                                <h3 className='pr-8 text-sm py-2 text-[#301762]'>Total Expanse - {AllMaterials && AllMaterials.reduce((cur, val) => cur + (val.total_price) * 1, 0)}₹</h3>
                            </div>
                        </div>
                        {/* //======================file display table section start============================= */}
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white rounded-lg">
                                <thead>
                                    <tr className="bg-gray-100 text-center text-gray-700 text-xs">
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            S.No.
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Name
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Item Price
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Quantity
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Total Price
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Buying Date
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Payment Mode
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Note
                                        </th>
                                        <th className="py-2 px-4 font-medium text-gray-600">
                                            Options
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {AllMaterials && AllMaterials.map((item, indx) => (
                                        <tr className="text-center border-t hover:bg-gray-50 text-xs odd:bg-white even:bg-gray-50" key={indx} >
                                            <td className="py-2 px-4 text-gray-700">
                                                {indx + 1}
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.item_name}
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.item_price}₹
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.quantity}
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.total_price}₹
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.buying_date}
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.payment_mode}
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                {item.note}
                                            </td>
                                            <td className="py-2 px-4 text-gray-700">
                                                <div className="flex gap-3 justify-center">
                                                    <button onClick={() => { setPhotoModelOpen(true); setModelData(item) }} className="text-blue hover:text-purple-900 text-lg" title="Delete Lab Data "><FaEye /></button>
                                                    <button onClick={() => { handleDelete(item._id) }} className="text-red-500 hover:text-red-400 text-lg" title="Delete Lab Data "><FaTrash /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
            {/* edit prescription model show */}
            <ShowPhotoModel
                content={<>
                    <div className="flex flex-col md:flex-row w-full justify-evenly p-1 border">
                        <div className="w-full md:w-1/3 p-5 rounded-lg text-sm">
                            <h2 className="text-blue mb-2 font-medium">Lab Details</h2>
                            <hr />
                            <ul className="grid gap-4 p-4 font-medium">
                                <li><span className="text-blue">Name</span> - {modelData && modelData.item_name}</li>
                                <li><span className="text-blue">Item Price</span> - {modelData && modelData.item_price}₹</li>
                                <li><span className="text-blue">Quantity</span> - {modelData && modelData.quantity}</li>
                                <li><span className="text-blue">Total Price</span> - {modelData && modelData.total_price}₹</li>
                                <li><span className="text-blue">Buying Date</span> - {modelData && modelData.buying_date}</li>
                                <li><span className="text-blue">Payment Mode</span> - {modelData && modelData.payment_mode}</li>
                                <li><span className="text-blue">Note</span> - {modelData && modelData.note}</li>
                            </ul>
                        </div>
                        <div className="w-full flex justify-center md:w-2/3 p-3 rounded-lg">
                            {modelData?.bill_image ? <img src={modelData && modelData.bill_image} alt={modelData && modelData.item_name} className="h-96 rounded" />
                                : <img src="/asset/images/unknown-image.jpg" alt={modelData && modelData.item_name} className="h-96 rounded" />}
                        </div>
                    </div>
                </>
                }
                open={photoModelOpen}
                setOpen={setPhotoModelOpen}
            />
        </>
    )
}
