import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import SquareButton from "../Ui Components/SquareButton";
import toast from "react-hot-toast";
import { DateFormat, Prifix, TimeFormat } from "../../utils/helper";
import { selectedPatient } from '../../redux/patientAppointments/patientSlice';
import { selectTreatment } from '../../redux/treatment/treatmentSlice';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import { deleteProcedureAsync, getAllProcedureAsync, selectProcedure } from '../../redux/procedure/procedureSlice';
import { FaRegCircleUser } from 'react-icons/fa6';
import { selectDiseasePrecation } from '../../redux/precautions/precautionSlice';
import { VscPreview } from 'react-icons/vsc';
import { FaWhatsapp } from 'react-icons/fa';
import { addProcedure } from '../../redux/procedure/procedureApi';

function Procedure() {
  const patientDetails = useSelector(selectedPatient)
  const allTreatment = useSelector(selectTreatment);
  const allProcedure = useSelector(selectProcedure);
  const deseaseData = useSelector(selectDiseasePrecation);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [filterProcedure, setFilterProcedure] = useState([])
  const [refresh, setRefresh] = useState(true)
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  function changeTreatment(e) {
    const { value } = e.target;
    setFilterProcedure(allTreatment && allTreatment.filter(item => item._id === value))
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    const obj = {
      procedure_name: data.treatment_taken,
      procedure_phase: data.procedure_taken,
      precautions: data.precautions,
      note: data.note
    }
    try {
      const response = await addProcedure(id, obj)
      if (response.status) {
        dispatch(getAllProcedureAsync(id))
        reset()
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }

    } catch (error) {
      setIsLoading(false)
      toast.error(error.message)
    }
  };

  function getPhase(arr, id) {
    let nm = arr && arr.find(item => item._id === id)
    return nm
  }

  // delete medicine 
  const handleDelete = (id) => {
    setIsLoading(true)
    try {
      dispatch(deleteProcedureAsync(id));
      setRefresh(!refresh)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message);
    }
  };

  return (<>
    <div className='mb-2'>
      <div className="flex justify-between w-full p-3">
        <div className="flex">
          <span>
            <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
          </span>
          <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
            Patient Name -
          </p>
          <p className="text-gray-600 text-sm pt-[5px] pl-2">
            {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
          </p>
        </div>
        <div className="flex mr-2">
          <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
            File Number -
          </p>
          <p className="text-gray-600 text-sm pt-[5px] pl-2">
            {patientDetails?.file_number}
          </p>
        </div>
      </div>
      <div className="bg-white rounded border-t-2 border-b-2">
        <div className="flex items-center bg-gray-300 rounded-t-sm justify-center space-x-2 text-[#301762] text-sm px-4 py-2">
          <span className="tracking-wide font-medium">Procedure Details Form</span>
        </div>
        <hr />
        <form className="py-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full m-4 px-4 flex flex-raw flex-wrap gap-8">
            <div>
              <label
                htmlFor="estimate_fee"
                className="block text-sm font-medium text-gray-600"
              >
                Treatment Taken
              </label>
              <div className="mt-2">
                <select
                  id="treatment_taken"
                  name="treatment_taken"
                  {...register("treatment_taken", {
                    required: "*treatment_taken is required",
                  })}
                  onChange={changeTreatment}
                  // autoComplete="treatment"
                  className="rounded-md text-sm border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                >
                  <option value="">-Select-</option>
                  {allTreatment && allTreatment.map((item, indx) => (
                    <option value={item._id} key={indx}>{item.treatment_name}</option>
                  ))}
                </select>
                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                  <ErrorMessage errors={errors} name="treatment_taken" />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="procedure_taken"
                className="block text-sm font-medium text-gray-600"
              >
                Procedure
              </label>
              <div className="mt-2">
                <select
                  id="procedure_taken"
                  name="procedure_taken"
                  {...register("procedure_taken")}
                  className=" rounded-md text-sm border-0 py-1.5 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                >
                  <option value="">-Select-</option>
                  {filterProcedure && filterProcedure[0]?.phase?.map((ele, idx) => (
                    <option value={ele._id} key={idx}>{ele.procedure_name}</option>
                  ))}
                </select>
                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                  <ErrorMessage errors={errors} name="procedure_taken" />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="precautions"
                className="block text-sm font-medium text-gray-600"
              >
                Instructions
              </label>
              <div className='mt-2'>
                <select
                  {...register("precautions")}
                  name="precautions"
                  className="rounded-md text-sm border-0 py-1.5 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                >
                  <option value="">-Select Instuction-</option>
                  {deseaseData.length > 0 &&
                    deseaseData.map((desease) => (
                      <option key={desease?._id} value={desease?._id}>
                        {desease?.desease_name}
                      </option>
                    ))}
                </select>
                <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                  <ErrorMessage errors={errors} name="precautions" />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="precautions"
                className="block text-sm font-medium text-gray-600"
              >
                Note
              </label>
              <div className='mt-2'>
                <textarea
                  {...register("note")}
                  name="note"
                  type='text'
                  className="rounded-md text-sm border-0 py-1.5 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
          <div className='p-8 flex justify-start'>
            <div>
              <SquareButton
                isLoading={isLoading}
                sqicon={<span className="text-xs">Add Procedure</span>}
              />
            </div>
          </div>
        </form>
      </div>
      <section
        id="medicine-section"
        className="bg-white rounded overflow-y-auto mb-2"
      >
        <div className="w-[100%] pl-2 bg-gray-300 rounded-t-sm text-white items-center">
          <h1 className="pl-3 text-sm font-medium text-center py-2 text-[#301762]">
            Total Procedure
          </h1>
        </div>
        <hr />
        {/* //======================file display table section start============================= */}
        <table className="w-[100%] text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className='text-center'>
              <th scope="col" className="px-2 py-2 font-medium">
                Sr.No.
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Treatment Taken
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Procedure
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                precautions
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Note
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                send
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                Date And Time
              </th>
              <th scope="col" className="px-2 py-2 font-medium">
                delete
              </th>
            </tr>
          </thead>
          <tbody>
            {allProcedure && allProcedure.map((item, idx) => (
              <tr className="text-center text-xs text-gray-600" key={idx}>
                <td className="px-2 py-2 font-medium">
                  {idx + 1}
                </td>
                <td className="px-2 py-2">
                  {item.procedure_name?.treatment_name}
                </td>
                <td className="px-2 py-2">
                  {getPhase(item.procedure_name.phase, item.procedure_phase)?.procedure_name}
                </td>

                <td className="px-2 py-2 flex justify-center">
                  <SquareButton
                    sqicon={<span className="text-lg" title="View File">
                      <Link to={item?.precautions?.pdf_file} target="_blank" className="flex justify-center items-center gap-3  text-white hover:no-underline hover:text-black"><VscPreview /></Link></span>}
                  />
                </td>
                <td className="px-2 py-2">
                  {item?.note}
                </td>
                <td className="px-2 py-2">
                  <div className="flex justify-center">
                    <SquareButton
                      sqicon={
                        <span className="text-lg" title="Send Prescription To Whatsapp">
                          <Link
                            to={`https://web.whatsapp.com/send?phone=+91${patientDetails?.mobile}&text=Hi%20${patientDetails?.patient_name}%20Here%20is%20the%20Procedure%20Instruction%20PDF%20file:%20${item?.precautions?.pdf_file}%20SHRI%20BALAJI%20DENTAL%20CARE%20INDORE`}
                            target="_blank"
                            className="flex items-center gap-3  text-white hover:no-underline hover:text-black"
                          >
                            <FaWhatsapp />
                          </Link>
                        </span>
                      }
                      color={"#25D366"}
                    />
                  </div>
                </td>
                <td className="px-2 py-2">
                  <span>{DateFormat(item.updatedAt)}</span><br />
                  <span>{TimeFormat(item.updatedAt)}</span>
                </td>
                <td className="px-2 py-2">
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        handleDelete(item?._id);
                      }}
                      className="bg-red-600 hover:bg-red-400 hover:scale-90 duration-300 cursor-pointer text-white p-2 rounded-sm"
                      title="Delete Prescription"
                    >
                      <RiDeleteBin6Line className="p-0 m-0 text-lg" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* //======================file display table section start============================= */}
      </section>
    </div>
  </>
  )
}

export default Procedure
