import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";

export function userLogin(loginInfo) {
    return new Promise(async (resolve) => {
        // console.log(loginInfo)
        const response = await axiosClient().post(webURLs.LOGIN_USER, {
            email: loginInfo.email,
            password: loginInfo.password
        })
        resolve(response.data)
    });
}

export function loggedInUserDetails(id) {
    return new Promise(async (resolve) => {
        try {
            const response = await axiosClient().get(`${webURLs.SINGLE_USERDETAILS}${id}`)
            resolve(response.data)
        } catch (error) {
            if (error.message === "Network Error") {
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                window.location.assign(window.location.origin)
            }
        }

    })
}

//=================== SENT OTP API =======================
export async function sendOtp(email) {
    const response = await axiosClient().post(
        `${webURLs.SEND_OPT_FORGOT_PASSWORD}`,
        email
    );
    return response.data;
}


//=================== VERIFY OTP API =======================
export async function verifyOtp(otp) {
    let token = window.sessionStorage.getItem('sbldcocp')
    const response = await axiosClient().post(`${webURLs.VERIFY_OTP}`, otp, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}


//========================= CANGE PASSWORD API ==================
export async function chnagePassApi(data) {

    let token = window.sessionStorage.getItem("sbldcvocp")
    const response = await axiosClient().post(
        `${webURLs.CHANGE_PASSWORD}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    );
    return response.data;
}

