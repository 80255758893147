import React from 'react'
// Import Components
import * as COMPONENT_ROUTE from "../../../routes/ComponentRoute";


function FeePage() {
  return (
    <>
        <COMPONENT_ROUTE.FEE_SECTION/>
    </>
  )
}

export default FeePage
