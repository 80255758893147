// imagePaths.js

export const path5 = [
    { image: "/asset/Conditionicons/caries.svg", title: "Caries" },
    { image: "/asset/Conditionicons/fracture.svg", title: "Fracture" },
    { image: "/asset/Conditionicons/impacted.svg", title: "Impacted" },
    { image: "/asset/Conditionicons/missing.svg", title: "Missing" },
    { image: "/asset/Conditionicons/mobility.svg", title: "Mobility" },
    { image: "/asset/Conditionicons/periapicalabscess.svg", title: "Periapical Abscess" },
    { image: "/asset/Conditionicons/rootstump.svg", title: "Rootstump" },
    { image: "/asset/Conditionicons/supraerupted.svg", title: "Supra Erupted" },
    { image: "/asset/Conditionicons/orthodontic.webp", title: "Ortho" },
  ];
  
  export const imgPath = [
    { image: "/asset/clinic-examination/18.svg", num: 18 },
    { image: "/asset/clinic-examination/17.svg", num: 17 },
    { image: "/asset/clinic-examination/16.svg", num: 16 },
    { image: "/asset/clinic-examination/15.svg", num: 15 },
    { image: "/asset/clinic-examination/14.svg", num: 14 },
    { image: "/asset/clinic-examination/13.svg", num: 13 },
    { image: "/asset/clinic-examination/12.svg", num: 12 },
    { image: "/asset/clinic-examination/11.svg", num: 11 },
    { image: "/asset/clinic-examination/21.svg", num: 21 },
    { image: "/asset/clinic-examination/22.svg", num: 22 },
    { image: "/asset/clinic-examination/23.svg", num: 23 },
    { image: "/asset/clinic-examination/24.svg", num: 24 },
    { image: "/asset/clinic-examination/25.svg", num: 25 },
    { image: "/asset/clinic-examination/26.svg", num: 26 },
    { image: "/asset/clinic-examination/27.svg", num: 27 },
    { image: "/asset/clinic-examination/28.svg", num: 28 },
  ];
  
  export const imgPath2 = [
    { image: "/asset/clinic-examination/48.svg", num: 48 },
    { image: "/asset/clinic-examination/47.svg", num: 47 },
    { image: "/asset/clinic-examination/46.svg", num: 46 },
    { image: "/asset/clinic-examination/45.svg", num: 45 },
    { image: "/asset/clinic-examination/44.svg", num: 44 },
    { image: "/asset/clinic-examination/43.svg", num: 43 },
    { image: "/asset/clinic-examination/42.svg", num: 42 },
    { image: "/asset/clinic-examination/41.svg", num: 41 },
    { image: "/asset/clinic-examination/31.svg", num: 31 },
    { image: "/asset/clinic-examination/32.svg", num: 32 },
    { image: "/asset/clinic-examination/33.svg", num: 33 },
    { image: "/asset/clinic-examination/34.svg", num: 34 },
    { image: "/asset/clinic-examination/35.svg", num: 35 },
    { image: "/asset/clinic-examination/36.svg", num: 36 },
    { image: "/asset/clinic-examination/37.svg", num: 37 },
    { image: "/asset/clinic-examination/38.svg", num: 38 },
  ];
  
  export const imgPath3 = [
    { image: "/asset/clinic-examination/pediatric/55.svg", num: 55 },
    { image: "/asset/clinic-examination/pediatric/54.svg", num: 54 },
    { image: "/asset/clinic-examination/pediatric/53.svg", num: 53 },
    { image: "/asset/clinic-examination/pediatric/52.svg", num: 52 },
    { image: "/asset/clinic-examination/pediatric/51.svg", num: 51 },
    { image: "/asset/clinic-examination/pediatric/61.svg", num: 61 },
    { image: "/asset/clinic-examination/pediatric/62.svg", num: 62 },
    { image: "/asset/clinic-examination/pediatric/63.svg", num: 63 },
    { image: "/asset/clinic-examination/pediatric/64.svg", num: 64 },
    { image: "/asset/clinic-examination/pediatric/65.svg", num: 65 },
  ];
  
  export const imgPath4 = [
    { image: "/asset/clinic-examination/pediatric/85.svg", num: 85 },
    { image: "/asset/clinic-examination/pediatric/84.svg", num: 84 },
    { image: "/asset/clinic-examination/pediatric/83.svg", num: 83 },
    { image: "/asset/clinic-examination/pediatric/82.svg", num: 82 },
    { image: "/asset/clinic-examination/pediatric/81.svg", num: 81 },
    { image: "/asset/clinic-examination/pediatric/71.svg", num: 71 },
    { image: "/asset/clinic-examination/pediatric/72.svg", num: 72 },
    { image: "/asset/clinic-examination/pediatric/73.svg", num: 73 },
    { image: "/asset/clinic-examination/pediatric/74.svg", num: 74 },
    { image: "/asset/clinic-examination/pediatric/75.svg", num: 75 },
  ];
  